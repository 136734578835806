var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "../common/slick";
import "../../scss/slider/slickSlider.scss";
import "../../scss/slider/arrow.scss";
import { animationFrameScheduler, fromEvent as observableFromEvent, interval } from "rxjs";
import { bufferToggle, debounceTime, filter, map, observeOn, startWith, take } from "rxjs/operators";
import { ClickStream } from "../clickstream";
import { LazyLoadContent } from "../common/lazyLoadContent";
import { isDefinedAndNotNull } from "../common/utils/objectUtil";
import $ from "../common/jquery";
var COUNTER_CLASS = ".shelf-page-count";
var PREV_ARROW_CLASS = ".orion-prev";
var NEXT_ARROW_CLASS = ".orion-next";
var TOUCH_THRESHOLD = 15;
var TRANSITION_TIME = 400;
var BATCH_TIME = 1500;
export var SLICK_EVENT_INIT = "init";
export var SLICK_EVENT_AFTER_CHANGE = "afterChange";
var HIDE_BEFORE_INITIALIZED_CLASS = "hide-before-initialized";
var SlickSlider = (function () {
    function SlickSlider($container, slickOptions, clickStreamOptions) {
        this.clickStreamOptions = clickStreamOptions || {};
        var defaultSettings = {
            accessibility: false,
            appendDots: $container.find(SlickSlider.SLIDER_WRAPPER_SELECTOR),
            infinite: true,
            lazyLoad: "none",
            speed: TRANSITION_TIME,
            swipeToSlide: true,
            touchThreshold: TOUCH_THRESHOLD,
            variableWidth: !slickOptions.slidesToShow
        };
        this.$slider = $container.find(SlickSlider.SLIDER_SELECTOR);
        defaultSettings = this.setupArrows(defaultSettings, $container);
        this.slickOptions = __assign({}, defaultSettings, slickOptions);
        if (this.slickOptions.retrieveMoreContentOptions) {
            this.slickOptions.infinite = false;
            this.setupRetrieveMoreContent();
        }
        if (this.slickOptions.disableOnScreenSmallerThanOrEqualTo) {
            this.initUnslickSlider(this.slickOptions.disableOnScreenSmallerThanOrEqualTo);
        }
        else {
            this.init();
        }
        this.$slider.removeClass(HIDE_BEFORE_INITIALIZED_CLASS);
        this.tracking();
        this.$showMoreButton = $container.find(SlickSlider.SLIDER_SHOW_MORE_BUTTON_SELECTOR);
        this.watchForShowAllSlides(this.$showMoreButton);
        if (this.slickOptions.hideViewMoreButton) {
            this.hideShowAllButton(this.$showMoreButton);
        }
        if (this.slickOptions.autoHideViewMoreButton
            && this.$slider.children().length <= 3) {
            this.hideShowAllButton(this.$showMoreButton);
        }
        if (!this.slickOptions.showAllItems) {
            this.showFirstThree();
        }
        if (this.slickOptions.doubleSizeFirstElementOnScreensGreaterThan) {
            this.setupFirstItemDoubleSize();
        }
    }
    SlickSlider.isScreenSmallerThanOrEqualTo = function (width) {
        return window.innerWidth < width;
    };
    SlickSlider.getVariableWidthSlidesShown = function (slider, slides, firstSlideToShow, totalSlidesInShelf) {
        var sliderWidth = slider.width();
        var slidesTotalWidth = 0;
        for (var slideIndex = firstSlideToShow; slideIndex < totalSlidesInShelf; slideIndex++) {
            slidesTotalWidth += $(slides[slideIndex]).outerWidth(true);
            if (slidesTotalWidth > sliderWidth) {
                return slideIndex;
            }
        }
        return totalSlidesInShelf;
    };
    SlickSlider.objectFromArray = function (stringArray) {
        return stringArray.reduce(function (acc, cur, i) {
            if (i % 2 === 1) {
                acc[stringArray[i - 1]] = cur;
            }
            return acc;
        }, {});
    };
    SlickSlider.prototype.unslick = function () {
        var slick = this.getSlick();
        slick && slick.unslick();
    };
    SlickSlider.prototype.slickGoTo = function (index, dontAnimate) {
        var slick = this.getSlick();
        slick && slick.slickGoTo(index, dontAnimate);
    };
    SlickSlider.prototype.init = function () {
        var _this = this;
        if (typeof this.slickOptions.onInit === "function") {
            observableFromEvent(this.$slider, SLICK_EVENT_INIT).subscribe(function () {
                _this.slickOptions.onInit(_this);
            });
        }
        if (this.slickOptions.lazyLoad === "lazyLoadImgs") {
            this.$slider.on(SLICK_EVENT_INIT + " " + SLICK_EVENT_AFTER_CHANGE, function () { return LazyLoadContent.loadContent(); });
        }
        this.$slider.slick(this.slickOptions);
    };
    SlickSlider.prototype.getSlick = function () {
        var rawSlider = this.$slider.get(0);
        return rawSlider && rawSlider.slick;
    };
    SlickSlider.prototype.setupArrows = function (settings, $container) {
        this.$counter = $container.find(COUNTER_CLASS);
        this.$prevArrow = $container.find(PREV_ARROW_CLASS);
        this.$nextArrow = $container.find(NEXT_ARROW_CLASS);
        if (this.$prevArrow.length > 0 && this.$nextArrow.length > 0) {
            settings = __assign({}, settings, { nextArrow: this.$nextArrow, prevArrow: this.$prevArrow });
        }
        return settings;
    };
    SlickSlider.prototype.tracking = function () {
        var _this = this;
        if (this.clickStreamOptions.servletName && this.clickStreamOptions.swipePageAction) {
            var changeObserved = observableFromEvent(this.$slider, SLICK_EVENT_AFTER_CHANGE);
            changeObserved.subscribe(function (_a) {
                var event = _a[0], slick = _a[1], currentSlide = _a[2];
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: _this.clickStreamOptions.swipePageAction,
                    pageProperties: __assign({ currentSlideIndex: currentSlide }, _this.clickStreamOptions.swipePageProperties),
                    destinationID: _this.clickStreamOptions.destinationId,
                    servletName: _this.clickStreamOptions.servletName
                });
            });
            changeObserved.pipe(bufferToggle(changeObserved, function () { return interval(BATCH_TIME); }))
                .subscribe(function (events) { return _this.trackProductImpressions(events); });
            var itemsToTrack = this.$slider.find("[data-action-shelf-item-capture]");
            if (itemsToTrack && itemsToTrack.length > 0) {
                ClickStream.trackVisibleElementsInScrollDiv(this.$slider, itemsToTrack, this.trackShelfItem.bind(this));
            }
        }
        observableFromEvent(this.$slider, SLICK_EVENT_AFTER_CHANGE).pipe(take(1))
            .subscribe(function (_a) {
            var event = _a[0], slick = _a[1], currentSlide = _a[2];
            _this.$prevArrow.removeClass("d-none");
            setTimeout(function () {
                _this.$prevArrow.removeClass("arrow-hidden");
            }, 0);
        });
        if (this.$counter.length > 0) {
            var slick = this.getSlick();
            if (isDefinedAndNotNull(slick) && slick.slideCount > slick.options.slidesToScroll) {
                this.$counter.html("1/" + Math.ceil(slick.slideCount / slick.options.slidesToScroll));
                observableFromEvent(this.$slider, SLICK_EVENT_AFTER_CHANGE)
                    .subscribe(function (_a) {
                    var event = _a[0], slick = _a[1], currentSlide = _a[2];
                    _this.$counter.html(Math.ceil((currentSlide + 1) / slick.options.slidesToScroll)
                        + "/" + Math.ceil(slick.slideCount / slick.options.slidesToScroll));
                });
            }
        }
    };
    SlickSlider.prototype.trackShelfItem = function (item) {
        if ($(item).data("impression-has-fired") !== "true") {
            var slideParent = item.closest(".slick-slide");
            var position = (slideParent && slideParent.dataset.slickIndex)
                || $(item).parentsUntil(this.$slider).last().prevAll().length;
            var pageProperties = $(item).data("action-shelf-item-page-properties")
                .split("|")
                .map(function (pageProperty) { return pageProperty.split(':'); })
                .filter(function (pageProperty) { return pageProperty.length === 2; })
                .reduce(function (acc, cur) {
                acc[cur[0]] = cur[1];
                return acc;
            }, {});
            var trackingArguments = {
                pageAction: $(item).data("action-shelf-item-capture"),
                pageProperties: pageProperties,
                productAttribute: "Position:" + position + "|" + item.dataset.actionProdAttr,
                servletName: ClickStream.getServlet()
            };
            ClickStream.postClickStreamWithTrackingArguments(trackingArguments);
            $(item).data("impression-has-fired", "true");
        }
    };
    SlickSlider.prototype.setupRetrieveMoreContent = function () {
        var _this = this;
        var isAjaxOngoing = false;
        var retrieveMoreContentOptions = this.slickOptions.retrieveMoreContentOptions;
        observableFromEvent(this.$slider, SLICK_EVENT_AFTER_CHANGE)
            .subscribe(function (_a) {
            var event = _a[0], slick = _a[1], currentSlide = _a[2];
            var numSlides = slick.$slides.length;
            if (!isAjaxOngoing && retrieveMoreContentOptions.shouldFetchMoreContent(currentSlide, numSlides)) {
                isAjaxOngoing = true;
                retrieveMoreContentOptions.fetchMoreContent(numSlides)
                    .subscribe(function (resp) {
                    isAjaxOngoing = false;
                    _this.$slider.slick("slickAdd", resp);
                });
            }
        });
    };
    SlickSlider.prototype.trackProductImpressions = function (events) {
        if (events.length === 0) {
            return 0;
        }
        var slick = events[0][1];
        var slidesToShow;
        if (slick.activeBreakpoint !== null && slick.activeBreakpoint !== undefined) {
            slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
        }
        else {
            slidesToShow = this.slickOptions.slidesToShow;
        }
        var productCards = this.$slider.find(".slick-track").children().toArray().map(function (productCard) {
            if ($(productCard).attr("data-action-capture") === "shelf_scroll") {
                return $(productCard);
            }
            return $(productCard).find("[data-action-capture='shelf_scroll']");
        });
        var totalSlidesInShelf = productCards.length;
        var firstSlides = events.map(function (event) { return event[2]; });
        var initialSlide = Math.min.apply(Math, firstSlides);
        var finalSlide = (this.slickOptions.variableWidth) ?
            SlickSlider.getVariableWidthSlidesShown(slick.$slider, slick.$slides, Math.max.apply(Math, firstSlides), totalSlidesInShelf)
            : Math.min(Math.max.apply(Math, firstSlides) + slidesToShow, totalSlidesInShelf);
        var shelfTypeList = [];
        var positionsList = [];
        var productCodesList = [];
        var attractionIdsList = [];
        for (var slide = initialSlide; slide < finalSlide; slide++) {
            var productCard = productCards[slide];
            if (productCard.data("impression-has-fired") !== true && productCard.length > 0) {
                var pageProperties = new Map(productCard.data("action-page-properties")
                    .split("|")
                    .filter(function (pageProp) { return pageProp !== ""
                    && pageProp.includes(":")
                    && pageProp.split(":").length === 2; })
                    .map(function (pageProperty) {
                    return [pageProperty.split(":")[0], pageProperty.split(":")[1]];
                }));
                var shelfType = pageProperties.get("shelfGroupId");
                var productAttribute = productCard.data("action-prod-attr");
                var attractionId = productCard.data("action-attraction-id");
                shelfTypeList.push(shelfType);
                positionsList.push(slide);
                if (productAttribute) {
                    productCodesList.push(productAttribute);
                }
                if (attractionId) {
                    attractionIdsList.push(attractionId);
                }
                if (productCard.data("action-shelf-item-capture")) {
                    this.trackShelfItem(productCard[0]);
                }
                productCard.data("impression-has-fired", true);
            }
        }
        var shelfTypes = ["[", shelfTypeList.join(), "]"].join("");
        var positions = ["[", positionsList.join(), "]"].join("");
        var productCodes = ["[", productCodesList.join(), "]"].join("");
        var attractionIds = ["[", attractionIdsList.join(), "]"].join("");
        if (positionsList.length > 0 || productCodesList.length > 0) {
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "shelf_product_in_view",
                pageProperties: __assign({ shelfTypes: shelfTypes,
                    positions: positions }, (attractionIdsList.length > 0 && { attractionIds: attractionIds }), (productCodesList.length > 0 && { productCodes: productCodes })),
                servletName: ClickStream.getServlet()
            });
        }
    };
    SlickSlider.prototype.initUnslickSlider = function (width) {
        var _this = this;
        if (!SlickSlider.isScreenSmallerThanOrEqualTo(width)) {
            this.init();
        }
        if (this.slickOptions.lazyLoad === "lazyLoadImgs") {
            observableFromEvent(this.$slider, "scroll").pipe(observeOn(SlickSlider.requestAnimationFrame))
                .subscribe(function () {
                LazyLoadContent.loadContent();
            });
        }
        observableFromEvent(window, "resize").pipe(observeOn(SlickSlider.requestAnimationFrame), map(function () { return SlickSlider.isScreenSmallerThanOrEqualTo(width); }))
            .subscribe(function (isToUnslick) {
            var slick = _this.getSlick();
            var isSlickInitialized = slick && !slick.unslicked;
            if (isToUnslick) {
                isSlickInitialized && _this.unslick();
            }
            else {
                !isSlickInitialized && _this.init();
            }
        });
    };
    SlickSlider.prototype.showFirstThree = function () {
        this.$slider.addClass(SlickSlider.SHOW_FIRST_THREE_CLASS);
    };
    SlickSlider.prototype.watchForShowAllSlides = function (elements) {
        var _this = this;
        if (elements.length === 0) {
            return;
        }
        observableFromEvent(elements, "click")
            .subscribe(function (event) {
            _this.showAllSlides(event);
        });
    };
    SlickSlider.prototype.showAllSlides = function (event) {
        this.$slider.removeClass(SlickSlider.SHOW_FIRST_THREE_CLASS);
        this.hideShowAllButton(event.target);
    };
    SlickSlider.prototype.hideShowAllButton = function (btn) {
        if (this.$showMoreButton.length === 0) {
            return;
        }
        this.$showMoreButton[0].style.display = "none";
    };
    SlickSlider.prototype.setupFirstItemDoubleSize = function () {
        var _this = this;
        var updateWidths = function () {
            var slick = _this.getSlick();
            var slideWidth = slick.listWidth / (slick.options.slidesToShow + 1) + "px";
            slick.$slides[0].style.width = "calc((" + slideWidth + " * 2) - 2rem)";
            for (var i = 1; i < slick.$slides.length; i++) {
                slick.$slides[i].style.width = "calc(" + slideWidth + " - 2rem)";
            }
        };
        observableFromEvent(window, "resize").pipe(debounceTime(100), startWith(null), filter(function (_) { return _this.slickOptions.variableWidth &&
            !SlickSlider.isScreenSmallerThanOrEqualTo(_this.slickOptions.doubleSizeFirstElementOnScreensGreaterThan); })).subscribe(function (_) { return updateWidths(); });
    };
    SlickSlider.SLIDER_WRAPPER_SELECTOR = ".slider-wrapper";
    SlickSlider.SLIDER_SELECTOR = ".orion-slider";
    SlickSlider.SLIDER_SHOW_MORE_BUTTON_SELECTOR = ".slider-show-more-button";
    SlickSlider.SHOW_FIRST_THREE_CLASS = "show-first-three";
    SlickSlider.requestAnimationFrame = animationFrameScheduler;
    return SlickSlider;
}());
export { SlickSlider };
