var ForgotPasswordSuccess = (function () {
    function ForgotPasswordSuccess($container, $modal, email) {
        this.$modal = $modal;
        this.$container = $container;
        document.getElementById("email").innerHTML = email;
        this.bootstrap();
    }
    ForgotPasswordSuccess.prototype.bootstrap = function () {
        var _this = this;
        this.backToLoginButton = this.$modal.find("#backToLoginButton");
        this.doneButton = this.$modal.find("#doneButton");
        this.backToLoginButton.click(function () { return _this.handleBackToLogin(); });
        this.doneButton.click(function () { return _this.handleDone(); });
    };
    ForgotPasswordSuccess.prototype.handleBackToLogin = function () {
        this.$container.find("#tapLoginModal").modal("show");
        this.$container.find("#tapForgotPasswordModalSuccess").modal("hide");
        this.$container.find("#tapForgotPasswordModal").modal("hide");
    };
    ForgotPasswordSuccess.prototype.handleDone = function () {
        this.$container.find("#tapForgotPasswordModalSuccess").modal("hide");
        this.$container.find("#tapForgotPasswordModal").modal("hide");
    };
    return ForgotPasswordSuccess;
}());
export { ForgotPasswordSuccess };
