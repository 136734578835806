import { AjaxService } from "../common/ajax";
import { localStorage } from "../common/storage";
import { OneTrustApi } from "../onetrust/OneTrustApi";
import { CookieCategory } from "./cookieCategory";
import { noop } from "rxjs";
import { catchError } from "rxjs/operators";
var TrackingConsentManager = (function () {
    function TrackingConsentManager(consentClient, analyticsAndCustomisationEnabled) {
        var _this = this;
        this.analyticsAndCustomisationEnabled =
            analyticsAndCustomisationEnabled || false;
        consentClient.getNoticeApi().then(function (api) { return _this.performAutoOptIn(api, consentClient); });
        consentClient
            .getPriorConsentSubject()
            .subscribe(function (categories) {
            return consentClient
                .getNoticeApi()
                .then(function (api) { return _this.handlePriorConsent(api, categories, consentClient); });
        });
    }
    TrackingConsentManager.prototype.handlePriorConsent = function (consentApi, categories, consentClient) {
        var newConsentState = {
            advertisingConsentGiven: categories[CookieCategory.ADVERTISING] ||
                categories[CookieCategory.ALL] ||
                false,
            analyticsAndCustomisationConsentGiven: categories[CookieCategory.ANALYTICS_AND_CUSTOMISATION] ||
                categories[CookieCategory.ALL] ||
                false,
            functionalConsentGiven: categories[CookieCategory.FUNCTIONAL] ||
                categories[CookieCategory.ALL] ||
                false,
        };
        this.updateTrackingPreferencesIfChanged(consentApi.regulationId, newConsentState, consentClient);
    };
    TrackingConsentManager.prototype.performAutoOptIn = function (consentApi, consentClient) {
        if (!consentApi.isGDPR()) {
            var defaultConsent = {
                advertisingConsentGiven: true,
                analyticsAndCustomisationConsentGiven: true,
                functionalConsentGiven: true,
            };
            this.updateTrackingPreferencesIfChanged(consentApi.regulationId, defaultConsent, consentClient);
        }
    };
    TrackingConsentManager.prototype.updateTrackingPreferencesIfChanged = function (regulationTypeId, newConsentState, consentClient) {
        var oldConsentStateEnvelope = JSON.parse(localStorage.get(TrackingConsentManager.TRACKING_CONSENT_STORAGE_KEY));
        var oldConsentState = oldConsentStateEnvelope == null ? null : oldConsentStateEnvelope.obj;
        var changed = oldConsentState === null ||
            this.analyticsAndCustomisationEnabled !==
                newConsentState.analyticsAndCustomisationConsentGiven ||
            oldConsentState.advertisingConsentGiven !==
                newConsentState.advertisingConsentGiven ||
            oldConsentState.analyticsAndCustomisationConsentGiven !==
                newConsentState.analyticsAndCustomisationConsentGiven ||
            oldConsentState.functionalConsentGiven !==
                newConsentState.functionalConsentGiven;
        if (changed) {
            var newConsentStateEnvelope = {
                obj: newConsentState,
                ttl: 0,
                created: new Date().getTime(),
            };
            localStorage.set(TrackingConsentManager.TRACKING_CONSENT_STORAGE_KEY, JSON.stringify(newConsentStateEnvelope));
            this.updateTrackingPreferences(regulationTypeId, newConsentState, consentClient);
        }
    };
    TrackingConsentManager.prototype.updateTrackingPreferences = function (regulationTypeId, consentOptions, consentClient) {
        var _this = this;
        this.analyticsAndCustomisationEnabled =
            !this.analyticsAndCustomisationEnabled;
        var consentId = consentClient instanceof OneTrustApi ? consentClient.consentId() : null;
        var trackingConsentWebRequest = {
            consentId: consentId,
            regulationTypeId: regulationTypeId,
            advertisingConsentGiven: consentOptions.advertisingConsentGiven,
            analyticsAndCustomisationConsentGiven: consentOptions.analyticsAndCustomisationConsentGiven,
            functionalConsentGiven: consentOptions.functionalConsentGiven,
        };
        TrackingConsentManager.AJAX.postJSON(TrackingConsentManager.TRACKING_BASE_URL, trackingConsentWebRequest)
            .pipe(catchError(function (error) {
            _this.analyticsAndCustomisationEnabled = !_this.analyticsAndCustomisationEnabled;
            throw new Error("Failed to " + (consentOptions.analyticsAndCustomisationConsentGiven
                ? "enable"
                : "disable") + " personalization tracking: " + error.message);
        }))
            .subscribe(noop);
    };
    TrackingConsentManager.AJAX = AjaxService.getInstance();
    TrackingConsentManager.TRACKING_BASE_URL = "/support/ajax/gdpr";
    TrackingConsentManager.TRACKING_CONSENT_STORAGE_KEY = "TRACKING_CONSENT";
    return TrackingConsentManager;
}());
export { TrackingConsentManager };
