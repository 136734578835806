import "../../scss/side-nav.scss";
import { fromEvent as observableFromEvent, merge } from "rxjs";
import { tap, delay, filter } from "rxjs/operators";
import { Decode } from "../decode";
import $ from "../common/jquery";
var SideNav = (function () {
    function SideNav($container) {
        this.$container = $container;
        this.sideBarElement = this.$container.find(SideNav.SIDEBAR);
        this.sideBarOverlay = this.sideBarElement.find("." + SideNav.SIDEBAR_OVERLAY_CLASS);
        this.seeGeoLinksButton = this.sideBarElement.find("." + SideNav.SEE_GEO_LINKS_BUTTON);
        this.sideBarGeoLinksContainer = this.sideBarElement.find("." + SideNav.SIDEBAR_GEO_LINKS);
        this.sideBarBackButton = this.sideBarElement.find("." + SideNav.SIDEBAR_BACK_BUTTON);
        this.watchMenuClick();
        this.watchCloseClick();
        this.watchGeoLinksOpen();
        this.watchGeoLinksClose();
        this.setupResponsiveNavigations();
    }
    SideNav.prototype.observeForNavShow = function (observable) {
        var _this = this;
        observable.pipe(tap(function () {
            _this.sideBarElement.addClass("entering");
            _this.sideBarElement.show();
            _this.resizeAndPosition();
        }), delay(SideNav.NAV_TRANSITION_DURATION)).subscribe(function () {
            _this.sideBarElement.removeClass("entering");
        });
    };
    SideNav.prototype.observeForNavHide = function (observable) {
        var _this = this;
        observable.pipe(filter(function (event) { return !$(event.target).hasClass(SideNav.SIDEBAR_OVERLAY_CLASS); }), tap(function () { return _this.sideBarElement.addClass("exiting"); }), delay(SideNav.NAV_TRANSITION_DURATION)).subscribe(function () {
            if (_this.sideBarElement.find(".side-nav-geo-links").hasClass("slide-in")) {
                _this.sideBarElement.find(".side-nav-geo-links").removeClass("slide-in");
            }
            _this.sideBarElement.hide();
            _this.sideBarElement.removeClass("exiting");
        });
    };
    SideNav.prototype.watchMenuClick = function () {
        var observable = observableFromEvent(this.$container.find(SideNav.OPEN_MENU_ICON), "click");
        this.observeForNavShow(observable);
    };
    SideNav.prototype.watchCloseClick = function () {
        var observable;
        if (this.sideBarElement.children().hasClass("nav-sidebar-overlay")) {
            observable = merge(observableFromEvent(this.sideBarElement, "click"), observableFromEvent(this.$container.find(SideNav.CLOSE_X_ICON), "click"));
        }
        else {
            observable = observableFromEvent(this.$container.find(SideNav.CLOSE_X_ICON), "click");
        }
        this.observeForNavHide(observable);
    };
    SideNav.prototype.watchGeoLinksOpen = function () {
        observableFromEvent(this.seeGeoLinksButton, "click")
            .subscribe(function (e) {
            $(e.currentTarget).next().addClass(SideNav.SLIDE_IN_CLASS);
        });
    };
    SideNav.prototype.watchGeoLinksClose = function () {
        observableFromEvent(this.sideBarBackButton, "click")
            .subscribe(function (e) {
            $(e.currentTarget).closest("." + SideNav.SIDEBAR_GEO_LINKS).removeClass(SideNav.SLIDE_IN_CLASS);
        });
    };
    SideNav.prototype.resizeAndPosition = function () {
        this.modalHeight = this.sideBarElement.innerHeight();
        var contentWidth = this.sideBarElement.find(".sidebar-modal").width();
        this.sideBarGeoLinksContainer.css({
            height: this.modalHeight,
            right: -contentWidth,
            width: contentWidth
        });
    };
    SideNav.prototype.setupResponsiveNavigations = function () {
        Decode.addDecoderHandler(this.$container);
        observableFromEvent(this.$container.find(".sidenav-next"), "click")
            .subscribe(function (event) {
            $(event.currentTarget)
                .next(SideNav.SIDENAV_SLIDE_CONTAINER)
                .addClass(SideNav.SLIDE_IN_CLASS);
            event.stopPropagation();
        });
        observableFromEvent(this.$container.find(".sidenav-back"), "click")
            .subscribe(function (event) {
            $(event.currentTarget)
                .closest(SideNav.SIDENAV_SLIDE_CONTAINER)
                .removeClass(SideNav.SLIDE_IN_CLASS);
            event.stopPropagation();
        });
        observableFromEvent(this.$container.find(".sidenav-all-back"), "click")
            .subscribe(function (event) {
            var target = $(event.currentTarget);
            while (target.length) {
                target = target.parent()
                    .closest(SideNav.SIDENAV_SLIDE_CONTAINER)
                    .removeClass(SideNav.SLIDE_IN_CLASS);
            }
            event.stopPropagation();
        });
        var target = this.$container.find(".sidenav-selected").first();
        while (target.length) {
            target = target.parent()
                .closest(SideNav.SIDENAV_SLIDE_CONTAINER)
                .addClass(SideNav.SLIDE_IN_CLASS);
        }
    };
    SideNav.SIDEBAR = ".nav-sidebar";
    SideNav.OPEN_MENU_ICON = ".navbar-wrapper .mobile-menu";
    SideNav.CLOSE_X_ICON = ".sidebar-close";
    SideNav.SIDEBAR_OVERLAY_CLASS = "nav-sidebar-overlay";
    SideNav.SEE_GEO_LINKS_BUTTON = "see-geo-links-button";
    SideNav.SIDEBAR_GEO_LINKS = "side-nav-geo-links";
    SideNav.SIDEBAR_BACK_BUTTON = "sidebar-back";
    SideNav.SIDENAV_SLIDE_CONTAINER = ".sidenav-slide";
    SideNav.SLIDE_IN_CLASS = "slide-in";
    SideNav.NAV_TRANSITION_DURATION = 150;
    return SideNav;
}());
export { SideNav };
