import $ from "../common/jquery";
import { ClickStream } from "../clickstream";
import { take, timeout } from "rxjs/operators";
var LoginSignupPopover = (function () {
    function LoginSignupPopover($header, pageName, isEligibleToShow, googleOneTapShownObservable) {
        if (isEligibleToShow === void 0) { isEligibleToShow = true; }
        var _this = this;
        this.isOneTapShown = false;
        this.googleOneTapShownObservable = googleOneTapShownObservable;
        this.$popoverContainers = $header.find(LoginSignupPopover.POPOVER_CLASS_SELECTOR);
        this.clickstream = new ClickStream();
        this.isEligibleToShow = isEligibleToShow;
        this.abandonedCartItemCount = $(LoginSignupPopover.CART_BADGE_COUNT_SELECTOR).text();
        this.googleOneTapShownObservable.pipe(timeout(4000), take(1)).subscribe(function (oneTapShown) {
            _this.isOneTapShown = oneTapShown;
        }, function () {
            _this.initPopoverAndClosingLogic();
        }, function () {
            _this.initPopoverAndClosingLogic();
        });
    }
    LoginSignupPopover.prototype.shouldShowPopover = function () {
        return $(LoginSignupPopover.LOGOUT_CLASS_SELECTOR).length < 1
            && !this.isOneTapShown
            && this.isEligibleToShow;
    };
    LoginSignupPopover.prototype.initPopoverAndClosingLogic = function () {
        var _this = this;
        if (!this.shouldShowPopover()) {
            return;
        }
        this.$popoverContainers.each(function (idx) {
            var $popoverContainer = $(_this.$popoverContainers[idx]);
            var contentContainer = $($popoverContainer.attr("data-popover-content"));
            var htmlContent = contentContainer.find(".popover-body").html();
            if (htmlContent) {
                $popoverContainer.popover({
                    container: contentContainer,
                    content: htmlContent,
                    html: true,
                    trigger: "focus"
                });
                _this.timeout = setTimeout(function () {
                    if ($popoverContainer.css("display") !== "none") {
                        $popoverContainer.popover("show");
                        ClickStream.postClickStreamWithTrackingArguments({
                            pageAction: "login_signup_popup_shown",
                            servletName: ClickStream.getServlet()
                        });
                        var popover = contentContainer.find(".popover");
                        var elements = popover.find("[data-action-capture]");
                        _this.clickstream.addElementsToClickStream(elements);
                        _this.initializeEarlyHideEventListeners($popoverContainer);
                        setTimeout(function () {
                            $popoverContainer.popover("hide");
                            _this.removeEventListeners();
                        }, LoginSignupPopover.AUTO_DISMISS_AFTER_TIME);
                    }
                }, LoginSignupPopover.POPOVER_CREATION_TIME);
            }
        });
    };
    LoginSignupPopover.prototype.initializeEarlyHideEventListeners = function ($popoverContainer) {
        var _this = this;
        $("body").on("click.popover", function () { return _this.hideAndClearTimeout($popoverContainer, _this.timeout); });
        $("#dropdownMenuButton").hover(function () { return _this.hideAndClearTimeout($popoverContainer, _this.timeout); });
        $(".mobile-menu").hover(function () { return _this.hideAndClearTimeout($popoverContainer, _this.timeout); });
    };
    LoginSignupPopover.prototype.removeEventListeners = function () {
        $("body").off("click.popover");
        $("#dropdownMenuButton").off("hover");
        $(".mobile-menu").off("hover");
    };
    LoginSignupPopover.prototype.hideAndClearTimeout = function ($container, timeout) {
        $container.popover("hide");
        this.removeEventListeners();
        clearTimeout(timeout);
    };
    LoginSignupPopover.POPOVER_CLASS_SELECTOR = ".login-popover-container";
    LoginSignupPopover.POPOVER_CREATION_TIME = 2000;
    LoginSignupPopover.AUTO_DISMISS_AFTER_TIME = 10000;
    LoginSignupPopover.LOGOUT_CLASS_SELECTOR = ".logout";
    LoginSignupPopover.CART_BADGE_COUNT_SELECTOR = ".badge-count";
    return LoginSignupPopover;
}());
export { LoginSignupPopover };
