var TooltipLoader = (function () {
    function TooltipLoader(options) {
        if (options === void 0) { options = TooltipLoader.defaultConfig; }
        var context = options.context || TooltipLoader.defaultConfig.context;
        $(context).tooltip();
    }
    TooltipLoader.defaultConfig = {
        context: '[data-toggle="tooltip"]',
    };
    return TooltipLoader;
}());
export { TooltipLoader };
