import { merge as observableMerge, fromEvent as observableFromEvent } from "rxjs";
import { tap, map } from "rxjs/operators";
import $ from "../common/jquery";
var AggregateFilter = (function () {
    function AggregateFilter(container, filters) {
        var _this = this;
        this.$filtersDiv = $(container);
        this.filters = filters;
        var applyButtonObs = observableFromEvent(this.$filtersDiv.find(AggregateFilter.APPLY_BUTTON), "click").pipe(map(function () { return _this.getState(); }));
        var clearButtonObs = observableFromEvent(this.$filtersDiv.find(AggregateFilter.CLEAR_BUTTON), "click").pipe(tap(function () { return _this.clear(); }), map(function () { return _this.getState(); }));
        this.changeObservable = observableMerge.apply(void 0, [applyButtonObs, clearButtonObs].concat(this.filters.map(function (filter) { return filter.getChangeObservable(); })));
    }
    AggregateFilter.prototype.onMount = function () {
        this.filters.forEach(function (f) { return f.onMount && f.onMount(); });
    };
    AggregateFilter.prototype.getState = function () {
        return $.extend.apply($, [{}].concat(this.filters.map(function (f) { return f.getState(); })));
    };
    AggregateFilter.prototype.getAppliedOptionsCount = function () {
        return this.filters.map(function (filter) { return filter.getAppliedOptionsCount(); }).reduce(function (a, b) { return a + b; });
    };
    AggregateFilter.prototype.clear = function () {
        this.filters.forEach(function (f) { return f.clear(); });
        this.updateStyles();
    };
    AggregateFilter.prototype.getChangeObservable = function () {
        return this.changeObservable;
    };
    AggregateFilter.prototype.updateFromState = function (state) {
        this.filters.forEach(function (f) { return f.updateFromState(state); });
        this.updateStyles();
    };
    AggregateFilter.prototype.revertState = function () {
        this.filters.forEach(function (f) { return f.revertState(); });
        this.updateStyles();
    };
    AggregateFilter.prototype.updateCounts = function (filterCounts) {
        this.filters.forEach(function (f) { return f.updateCounts && f.updateCounts(filterCounts); });
    };
    AggregateFilter.prototype.updateAppliedOptionsCount = function (count) {
        this.$filtersDiv.find(AggregateFilter.APPLIED_OPTIONS_COUNT).html(count);
        this.updateBorderAndIcon(count);
    };
    AggregateFilter.prototype.updateBorderAndIcon = function (count) {
        this.$filtersDiv.toggleClass("count-applied", count > 0);
        this.$filtersDiv.toggleClass("filter-selected", count > 0);
        this.$filtersDiv.find(".filter-modal .btn .button-icon").toggleClass("fill-secondary", count === 0);
        this.$filtersDiv.find(".filter-modal .btn .button-icon").toggleClass("fill-white", count > 0);
        this.$filtersDiv.find(".filter-modal .btn").toggleClass("filter-text", count === 0);
        this.$filtersDiv.find(".btn")
            .not(".spin-caret")
            .not(".spin-caret-group")
            .not(".more-less-btn")
            .toggleClass("border-secondary", count > 0);
        var $filtersIcon = this.$filtersDiv.find(".filters-icon");
        if ($filtersIcon.length > 0) {
            $filtersIcon.toggle(!count);
        }
        else {
            this.$filtersDiv.find(".filter-count").toggleClass("text-secondary", count === 0);
        }
    };
    AggregateFilter.prototype.updateDynamicFilterMoreStyle = function (count) {
        $("#dynamicFilterMoreButton").toggleClass("filter-applied", count > 0);
        $(".filters-pill-filter").toggleClass("filter-applied", count > 0);
    };
    AggregateFilter.prototype.updateStyles = function () {
        var count = this.getAppliedOptionsCount() > 0 ? this.getAppliedOptionsCount() : "";
        this.updateAppliedOptionsCount(count);
        this.updateDynamicFilterMoreStyle(count);
    };
    AggregateFilter.APPLIED_OPTIONS_COUNT = ".filter-count";
    AggregateFilter.APPLY_BUTTON = ".apply-button";
    AggregateFilter.CLEAR_BUTTON = ".aggregateClear";
    return AggregateFilter;
}());
export { AggregateFilter };
