var SupplierFilterSearch = (function () {
    function SupplierFilterSearch() {
        $(SupplierFilterSearch.SEARCH_INPUT_SELECTOR).on("change input", SupplierFilterSearch.filterSuppliers);
    }
    SupplierFilterSearch.filterSuppliers = function () {
        var searchText = $(SupplierFilterSearch.SEARCH_INPUT_SELECTOR).val().toString().trim().toLowerCase();
        $(SupplierFilterSearch.SEARCH_OPTION_SELECTOR).each(function () {
            var searchOption = $(this);
            var supplierCode = searchOption.data("supplier-code").toString().trim().toLowerCase();
            var supplierName = searchOption.data("supplier-name").toString().trim().toLowerCase();
            if (!searchText) {
                searchOption.css("display", "block");
            }
            else if (supplierName.indexOf(searchText) > -1 || supplierCode.indexOf(searchText) > -1) {
                searchOption.css("display", "block");
            }
            else {
                searchOption.css("display", "none");
            }
        });
    };
    SupplierFilterSearch.SEARCH_INPUT_SELECTOR = "#supplierFilterOptions input[type=search]";
    SupplierFilterSearch.SEARCH_OPTION_SELECTOR = "div[data-supplier-filter-option]";
    return SupplierFilterSearch;
}());
export { SupplierFilterSearch };
