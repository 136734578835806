import { UspBannerClicksListener } from "../header/uspBannerClicksListener";
import { ClickStream } from "../clickstream";
var InlineUspBanners = (function () {
    function InlineUspBanners() {
        this.$uspBannerDesktop = $(".inline-banners-desktop");
        this.$uspBannerMobile = $(".inline-banners-mobile");
        this.$banners = $(".inline-banner");
        this.$isMobile = $(".inline-banners-mobile").is(":visible");
    }
    InlineUspBanners.prototype.setUp = function () {
        if (this.$banners.length === 0) {
            return;
        }
        this.setBanners();
        this.setTracking();
        this.setListeners();
        if (this.$isMobile) {
            this.startRotation();
        }
    };
    InlineUspBanners.prototype.isVisible = function (element) {
        return element.is(":visible");
    };
    InlineUspBanners.prototype.startRotation = function () {
        var BANNER_CHANGE_TIMEOUT = 8000;
        function show(elem) {
            elem.style.opacity = "1";
        }
        function hide(elem) {
            elem.style.opacity = "0";
        }
        var banners = this.$banners;
        var index = 0;
        this.setBanners();
        var curBanner = this.$banners[0];
        var nextBanner = this.$banners[0];
        show(curBanner);
        showSlides();
        function showSlides() {
            if (index > 0) {
                curBanner = nextBanner;
                nextBanner = banners[index];
                hide(curBanner);
                show(nextBanner);
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "swipe_usp_banner",
                    servletName: ClickStream.getServlet()
                });
                if (index === banners.length - 1) {
                    return;
                }
            }
            index++;
            setTimeout(showSlides, BANNER_CHANGE_TIMEOUT);
        }
    };
    InlineUspBanners.prototype.setListeners = function () {
        new UspBannerClicksListener(this.$uspBannerDesktop);
        new UspBannerClicksListener(this.$uspBannerMobile);
    };
    InlineUspBanners.prototype.setTracking = function () {
        if ((this.$uspBannerDesktop && this.$uspBannerDesktop.length > 0 && this.isVisible(this.$uspBannerDesktop)) ||
            (this.$uspBannerMobile && this.$uspBannerMobile.length > 0 && this.isVisible(this.$uspBannerMobile))) {
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "usp_banner_in_view",
                servletName: ClickStream.getServlet()
            });
        }
    };
    InlineUspBanners.prototype.setBanners = function () {
        var _this = this;
        var len = this.$banners.length;
        if (len === 0) {
            return;
        }
        this.$banners.each(function (i) {
            if (i < len - 1) {
                _this.$banners[i].style.position = "absolute";
                _this.$banners[i].style.display = "flex";
            }
        });
        this.$banners[len - 1].style.display = "flex";
        this.$banners[0].style.opacity = "1";
    };
    return InlineUspBanners;
}());
export { InlineUspBanners };
