import { EMPTY as observableEmpty, fromEvent as observableFromEvent } from "rxjs";
import { map } from "rxjs/operators";
import get from "lodash/get";
import $ from "../common/jquery";
var CheckboxesFilter = (function () {
    function CheckboxesFilter(parentElement, paramName, elementName, immediateUpdate, disableIfZero, revertStateObservable) {
        var _this = this;
        this.$parentElement = parentElement;
        this.paramName = paramName;
        this.currentState = this.getEmptyState();
        this.elementName = elementName;
        this.immediateUpdate = immediateUpdate;
        this.disableIfZero = disableIfZero;
        var parentFilters = this.$parentElement.find(".checkbox-parent");
        if (parentFilters.length) {
            var childFilters = parentFilters.siblings(".child-filters").find(".checkbox-child");
            childFilters.length && this.createParentToChildMapping(childFilters);
        }
        this.showMoreLessButtons = this.$parentElement.find(".toggle-show." + elementName);
        if (this.showMoreLessButtons.length) {
            observableFromEvent(this.showMoreLessButtons, "click")
                .subscribe(function () { return _this.toggleExtraOptionsDisplay(); });
        }
        this.preventClickEventsBubbling();
        observableFromEvent(this.getAllCheckboxes(), "change").subscribe(function (event) {
            var $clickedCheckbox = $(event.target);
            var $parentContainer = $clickedCheckbox.closest(".checkbox-filter");
            var $parentCheckbox = $clickedCheckbox;
            var isParent = $clickedCheckbox.hasClass("checkbox-parent");
            if (!isParent) {
                $parentCheckbox = $parentContainer.find(".checkbox-parent");
            }
            var $children = $parentContainer.find(".checkbox-child");
            if (isParent) {
                var isParentChecked = $parentCheckbox.prop("checked");
                if (isParentChecked) {
                    _this.collapseTarget($parentCheckbox, false);
                }
                $children.prop("checked", isParentChecked);
            }
            if ($children.length > 0) {
                var checkedChildren = $children.filter(":checked").length;
                var allSelected = $children.length === checkedChildren;
                var isPartlyChecked = checkedChildren > 0 && !allSelected;
                $parentCheckbox.toggleClass("partly-checked", isPartlyChecked);
                if (isPartlyChecked) {
                    $parentCheckbox.prop("checked", false);
                }
                else if (allSelected) {
                    $parentCheckbox.prop("checked", true);
                }
                else if (checkedChildren === 0) {
                    $parentCheckbox.prop("checked", false);
                    _this.collapseTarget($parentCheckbox, true);
                }
            }
            if (_this.parentToChildCategoryMapping) {
                var state = _this.getState();
                var tagFilters = state.tagFilter;
                if (!$clickedCheckbox.prop("checked") && tagFilters) {
                    var unselectedCheckboxTagId_1 = event.target.dataset.tagId;
                    if (unselectedCheckboxTagId_1) {
                        tagFilters = Array.from(new Set(tagFilters.split(",")));
                        var relatedTagIds_1 = _this.parentToChildCategoryMapping[parseInt(unselectedCheckboxTagId_1, 10)] || [];
                        state.tagFilter = tagFilters.filter(function (tagId) {
                            return tagId !== unselectedCheckboxTagId_1 && relatedTagIds_1.indexOf(tagId) === -1;
                        }).join(",");
                    }
                }
                _this.updateFromState(state);
            }
        });
        if (revertStateObservable !== undefined) {
            revertStateObservable.subscribe(function () {
                _this.revertState();
            });
        }
    }
    CheckboxesFilter.prototype.getParamName = function () {
        return this.paramName;
    };
    CheckboxesFilter.prototype.onMount = function () {
        this.enableSelectedCheckboxesThatAreDisabled();
    };
    CheckboxesFilter.prototype.clear = function () {
        this.getSelectedElements().prop("checked", false);
        this.currentState = this.getEmptyState();
    };
    CheckboxesFilter.prototype.getState = function () {
        var stateValue = this.getSelectedElements()
            .map(function (index, element) { return element.value; })
            .get()
            .join();
        return this.getStateWithValue(stateValue);
    };
    CheckboxesFilter.prototype.getAppliedOptionsCount = function () {
        return this.getSelectedElements().length;
    };
    CheckboxesFilter.prototype.getChangeObservable = function () {
        var _this = this;
        if (this.immediateUpdate) {
            return observableFromEvent(this.getAllCheckboxes(), "change").pipe(map(function () { return _this.getState(); }));
        }
        return observableEmpty;
    };
    CheckboxesFilter.prototype.updateFromState = function (state) {
        if (state != null && state[this.paramName] != null) {
            var checkboxFilterStateValue = state[this.paramName];
            this.clear();
            var values = String(checkboxFilterStateValue).split(",");
            for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
                var value = values_1[_i];
                this.findCheckboxByValue(value).prop("checked", true);
            }
            this.currentState = this.getStateWithValue(checkboxFilterStateValue);
        }
    };
    CheckboxesFilter.prototype.updateCounts = function (filterCounts) {
        var _this = this;
        if (!filterCounts) {
            return;
        }
        this.getAllCheckboxes().each(function (index, checkbox) {
            var $checkbox = $(checkbox);
            if ($checkbox.data("filterCount")) {
                var $countSpan = $checkbox.closest("label").find(".checkbox-filter-count");
                var countKey = $checkbox.data("filterCount");
                var defaultValue = false;
                var okToUpdate = !$checkbox.data("noCountUpdate");
                var countValue = void 0;
                if (countKey.indexOf(".") !== -1) {
                    var parts = countKey.split(".");
                    var keyName = parts[0];
                    var keyValue = parts[1];
                    var filter = filterCounts[keyName];
                    if (filter !== undefined) {
                        countValue = filter[keyValue] !== undefined ? filter[keyValue] : 0;
                    }
                    else {
                        okToUpdate = false;
                    }
                }
                else {
                    countValue = get(filterCounts, countKey, defaultValue);
                }
                var isCheckboxChecked = $checkbox.prop("checked");
                if (okToUpdate && countValue !== defaultValue) {
                    $countSpan.text(countValue);
                    if (countValue > 0) {
                        _this.enableCheckbox($checkbox);
                    }
                    else if (_this.disableIfZero && !isCheckboxChecked) {
                        _this.disableCheckbox($checkbox);
                    }
                }
            }
        });
    };
    CheckboxesFilter.prototype.revertState = function () {
        this.updateFromState(this.currentState);
    };
    CheckboxesFilter.prototype.getStateMessage = function () {
        if (this.getSelectedElements().length === 1) {
            return this.getSelectedElements().data("message");
        }
        return "";
    };
    CheckboxesFilter.prototype.findCheckboxByValue = function (value) {
        return this.$parentElement.find("input[value=\"" + value + "\"][name=\"" + this.elementName + "\"]");
    };
    CheckboxesFilter.prototype.toggleExtraOptionsDisplay = function () {
        this.$parentElement.find(".extra-option." + this.elementName).toggleClass("d-none");
        this.showMoreLessButtons.find(".btn-text").toggleClass("d-none");
    };
    CheckboxesFilter.prototype.getAllCheckboxes = function () {
        return this.$parentElement.find("input[name=\"" + this.elementName + "\"]");
    };
    CheckboxesFilter.prototype.getSelectedElements = function () {
        return this.$parentElement.find("input[name=\"" + this.elementName + "\"]:checked");
    };
    CheckboxesFilter.prototype.getEmptyState = function () {
        return this.getStateWithValue("");
    };
    CheckboxesFilter.prototype.getStateWithValue = function (value) {
        var _a;
        return _a = {},
            _a[this.paramName] = value,
            _a;
    };
    CheckboxesFilter.prototype.enableSelectedCheckboxesThatAreDisabled = function () {
        var _this = this;
        this.getAllCheckboxes().each(function (index, checkbox) {
            var $checkbox = $(checkbox);
            var isCheckboxSelected = $checkbox.prop("checked");
            var isCheckboxDisabled = $checkbox.prop("disabled");
            if (isCheckboxSelected && isCheckboxDisabled) {
                _this.enableCheckbox($checkbox);
            }
        });
    };
    CheckboxesFilter.prototype.disableCheckbox = function ($checkbox) {
        this.toggleCheckbox($checkbox, true);
    };
    CheckboxesFilter.prototype.enableCheckbox = function ($checkbox) {
        this.toggleCheckbox($checkbox, false);
    };
    CheckboxesFilter.prototype.toggleCheckbox = function ($checkbox, disable) {
        var checkboxText = $checkbox.closest("div").find(".text-body");
        var $countSpan = $checkbox.closest("label").find(".checkbox-filter-count");
        $checkbox.prop("disabled", disable);
        checkboxText.toggleClass("text-disabled", disable);
        $countSpan.toggleClass("text-disabled", disable);
    };
    CheckboxesFilter.prototype.preventClickEventsBubbling = function () {
        var dropdownBody = this.$parentElement.find(".dropdown-menu");
        if (!dropdownBody.length) {
            return;
        }
        var labels = dropdownBody.find("label.form-check-label, label.form-check-label span");
        observableFromEvent(labels, "click")
            .subscribe(function (e) {
            e.stopPropagation();
        });
    };
    CheckboxesFilter.prototype.collapseTarget = function ($targetingElement, collapse) {
        $($targetingElement.data("target"))
            .toggleClass("collapse", collapse)
            .toggleClass("show", !collapse);
    };
    CheckboxesFilter.prototype.createParentToChildMapping = function (childFilters) {
        var individualParentToChildMapping = childFilters.get().map(function (childFilter) {
            var _a;
            return _a = {}, _a[childFilter.dataset.parentTagId] = childFilter.dataset.tagId, _a;
        });
        var tempParentToChildCategoryMapping = {};
        individualParentToChildMapping.forEach(function (parentToChildMapping) {
            var key = parseInt(Object.keys(parentToChildMapping)[0], 10);
            if (tempParentToChildCategoryMapping[key]) {
                tempParentToChildCategoryMapping[key]
                    = tempParentToChildCategoryMapping[key].concat([parentToChildMapping[key]]);
            }
            else {
                tempParentToChildCategoryMapping[key] = [parentToChildMapping[key]];
            }
        });
        this.parentToChildCategoryMapping = tempParentToChildCategoryMapping;
    };
    return CheckboxesFilter;
}());
export { CheckboxesFilter };
