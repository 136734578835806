import { fromEvent as observableFromEvent } from "rxjs";
import $ from "./common/jquery";
var Decode = (function () {
    function Decode() {
    }
    Decode.addDecoderHandler = function (element) {
        var _this = this;
        if (element.length) {
            observableFromEvent(element.find(".product-encoded-url"), "click")
                .subscribe(function (e) {
                var obfuscatedUrl = $(event.currentTarget).closest(".product-encoded-url")
                    .attr("data-encoded-url");
                var additionalParams = $(event.currentTarget).closest(".product-encoded-url")
                    .attr("additional-params");
                var fragment = $(event.currentTarget).closest(".product-encoded-url")
                    .attr("fragment");
                var decodedUrl = atob(obfuscatedUrl);
                decodedUrl = decodedUrl.substring(4, decodedUrl.length - 4);
                var finalUrl = _this.getUrlWithUpdatedParams(decodedUrl, additionalParams, fragment);
                window.open(finalUrl, "_self");
            });
        }
    };
    Decode.getUrlWithUpdatedParams = function (url, additionalParamsString, fragment) {
        if (!additionalParamsString || additionalParamsString === "") {
            return url;
        }
        var urlObj = new URL(url, window.location.origin);
        var additionalParams = new URLSearchParams(additionalParamsString);
        additionalParams.forEach(function (v, k) {
            urlObj.searchParams.set(k, v);
        });
        if (fragment && fragment !== "") {
            urlObj.hash = fragment;
        }
        return urlObj.toString();
    };
    return Decode;
}());
export { Decode };
