import { ClickStream } from "../clickstream";
import { fromEvent as observableFromEvent } from "rxjs";
var RNPLPromoModal = (function () {
    function RNPLPromoModal(isElegibleToShow, rnplModal) {
        if (isElegibleToShow === void 0) { isElegibleToShow = true; }
        this.clickstream = new ClickStream();
        this.isElegibleToShow = isElegibleToShow;
        this.$rnplModal = rnplModal;
        if (this.isElegibleToShow && this.$rnplModal.length) {
            this.clickStreamEvents();
            this.$rnplModal.modal("show");
        }
    }
    RNPLPromoModal.prototype.clickStreamEvents = function () {
        observableFromEvent(this.$rnplModal, "show.bs.modal")
            .subscribe(function () {
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "reserve_now_pay_later_modal_show",
                servletName: ClickStream.getServlet()
            });
        });
        observableFromEvent(this.$rnplModal, "hide.bs.modal")
            .subscribe(function () {
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "reserve_now_pay_later_modal_close",
                servletName: ClickStream.getServlet()
            });
        });
    };
    return RNPLPromoModal;
}());
export { RNPLPromoModal };
