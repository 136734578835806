var ForgotPasswordFormValidator = (function () {
    function ForgotPasswordFormValidator(form) {
        this.parsleyFormInstance = form.parsley(ForgotPasswordFormValidator.PARSLEY_VALIDATION_OPTIONS);
        this.registerFormEventHandlers();
    }
    ForgotPasswordFormValidator.prototype.registerFormEventHandlers = function () {
        if (this.parsleyFormInstance) {
            this.parsleyFormInstance.on("field:error", function (fieldInstance) {
                fieldInstance.$element
                    .closest(".form-group")
                    .removeClass("is-valid")
                    .addClass("is-invalid");
            });
            this.parsleyFormInstance.on("field:success", function (fieldInstance) {
                fieldInstance.$element
                    .closest(".form-group")
                    .removeClass("is-invalid")
                    .addClass("is-valid");
            });
        }
    };
    ForgotPasswordFormValidator.PARSLEY_VALIDATION_OPTIONS = {
        errorClass: "is-invalid",
        errorTemplate: "<div></div>",
        errorsWrapper: "<div class=\"invalid-feedback\"></div>",
        successClass: "is-valid",
        trigger: "input focusout"
    };
    return ForgotPasswordFormValidator;
}());
export { ForgotPasswordFormValidator };
