import $ from "./common/jquery";
import { Popover } from "./common/popover";
import { HelpCenterLoader } from "./helpCenterLoader";
var ExcellentBadge = (function () {
    function ExcellentBadge() {
        if ($(ExcellentBadge.EXCELLENT_BADGE_WRAPPER).length > 0) {
            this.$excellentBadgeBody = $(ExcellentBadge.EXCELLENT_BADGE_WRAPPER);
            this.bootstrap();
            $(".excellent-wrap").on("inserted.bs.popover", function () {
                HelpCenterLoader.scanFaqArticleLinks(window, ".excellent-wrap");
            });
        }
    }
    ExcellentBadge.prototype.bootstrap = function () {
        new Popover(this.$excellentBadgeBody, ".excellent-wrap", true);
    };
    ExcellentBadge.EXCELLENT_BADGE_WRAPPER = ".excellent-wrap";
    return ExcellentBadge;
}());
export { ExcellentBadge };
