import "../../scss/priceSlider.scss";
import { fromEvent as observableFromEvent, merge as observableMerge, Subject } from "rxjs";
import { first, map, concatMap, takeUntil } from "rxjs/operators";
import { ClickStream } from "../clickstream";
import $ from "../common/jquery";
var PriceSlider = (function () {
    function PriceSlider(container) {
        this.changeObservable = new Subject();
        this.setElements(container);
        this.setData();
        this.initHandle(true);
        this.initHandle(false);
        this.watchMinInput();
        this.watchMaxInput();
    }
    PriceSlider.prototype.clear = function () {
        this.updateSlider(this.getLeftHandleData(), 0);
        this.updateSlider(this.getRightHandleData(), 100);
    };
    PriceSlider.prototype.getState = function () {
        this.applyFilterHandler();
        var minInput = this.getValueFromPriceDisplay(this.$priceMinElement);
        var maxInput = this.getValueFromPriceDisplay(this.$priceMaxElement);
        if (minInput && maxInput) {
            var minPrice = minInput ? parseInt(minInput, 10) * 100 : NaN;
            var maxPrice = maxInput ? parseInt(maxInput.replace("+", ""), 10) * 100 : NaN;
            if (isNaN(minPrice) || isNaN(maxPrice) || minPrice > maxPrice) {
                return "";
            }
            else {
                var priceRangeMin = minPrice > 0 ? String(minPrice) : "";
                var priceRangeMax = (maxInput.indexOf("+") < 0 && parseInt(maxInput, 10) < this.priceUpperBound) ?
                    String(maxPrice) :
                    "";
                return priceRangeMin + "-" + priceRangeMax;
            }
        }
        return "";
    };
    PriceSlider.prototype.getAppliedOptionsCount = function () {
        return this.selectedMinPrice === this.priceLowerBound && this.selectedMaxPrice === this.priceUpperBound ? 0 : 1;
    };
    PriceSlider.prototype.getChangeObservable = function () {
        return this.changeObservable;
    };
    PriceSlider.prototype.updateFromState = function (state) {
        if (state != null) {
            this.clear();
            var priceRange = state.split("-");
            var priceMin = Math.max(parseInt(priceRange[0], 10), 0);
            var priceMax = Math.min(parseInt(priceRange[1], 10), this.priceUpperBound * 100);
            var leftXPercent = isNaN(priceMin) ? 0 : (priceMin / this.priceUpperBound);
            var rightXPercent = isNaN(priceMax) ? 100 : (priceMax / this.priceUpperBound);
            this.updateSlider(this.getLeftHandleData(), leftXPercent);
            this.updateSlider(this.getRightHandleData(), rightXPercent);
        }
    };
    PriceSlider.prototype.applyFilterHandler = function () {
        var leftHandleData = this.getLeftHandleData();
        var leftHandleXPercent = this.getXPercentFromInput(true);
        var rightHandleData = this.getRightHandleData();
        var rightHandleXPercent = this.getXPercentFromInput(false);
        this.updateSlider(leftHandleData, leftHandleXPercent);
        this.updateSlider(rightHandleData, rightHandleXPercent);
    };
    PriceSlider.prototype.getStateMessage = function () {
        if (!this.getAppliedOptionsCount()) {
            return "";
        }
        var minInput = this.getValueFromPriceDisplay(this.$priceMinElement);
        var maxInput = this.getValueFromPriceDisplay(this.$priceMaxElement);
        var returnMessage = this.$priceSliderData.attr("data-currency-label");
        if (minInput) {
            returnMessage = returnMessage + minInput;
        }
        if (minInput && maxInput) {
            returnMessage = returnMessage + "-";
        }
        if (maxInput) {
            returnMessage = returnMessage + maxInput;
        }
        return returnMessage;
    };
    PriceSlider.prototype.setElements = function (container) {
        this.$priceSliderContainer = $(container);
        this.$leftHandle = this.$priceSliderContainer.find(PriceSlider.HANDLE_LEFT_SELECTOR);
        this.$rightHandle = this.$priceSliderContainer.find(PriceSlider.HANDLE_RIGHT_SELECTOR);
        this.$priceMinElement = $(this.$priceSliderContainer.find(PriceSlider.PRICE_MIN_SELECTOR).get(0) ||
            this.$priceSliderContainer.find(PriceSlider.TOP_PRICE_MIN_SELECTOR).get(0));
        this.$priceMaxElement = $(this.$priceSliderContainer.find(PriceSlider.PRICE_MAX_SELECTOR).get(0) ||
            this.$priceSliderContainer.find(PriceSlider.TOP_PRICE_MAX_SELECTOR).get(0));
        this.$priceSliderData = this.$priceSliderContainer.find(PriceSlider.PRICE_SLIDER_DATA_SELECTOR);
        this.$selectedBar = this.$priceSliderContainer.find(PriceSlider.SLIDER_BAR_SELECTOR +
            PriceSlider.FILTERED_CLASS);
    };
    PriceSlider.prototype.setData = function () {
        this.selectableWidth = this.$priceSliderContainer.innerWidth();
        this.leftHandleXPercent = 0;
        this.rightHandleXPercent = 100;
        this.bucketWidth = parseInt(this.$priceSliderData.attr("data-bucket-width"), 10);
        var minPrice = parseInt(this.$priceSliderData.attr("data-price-lower-bound"), 10);
        var maxPrice = parseInt(this.$priceSliderData.attr("data-price-upper-bound"), 10);
        this.selectedMinPrice = minPrice;
        this.selectedMaxPrice = maxPrice;
        this.priceLowerBound = minPrice;
        this.priceUpperBound = maxPrice;
    };
    PriceSlider.prototype.initHandle = function (isLeft) {
        var _this = this;
        var handle = isLeft ? this.$leftHandle : this.$rightHandle;
        var mouseEventToCoordinate = function (mouseEvent) {
            mouseEvent.preventDefault();
            return {
                x: mouseEvent.clientX,
                y: mouseEvent.clientY
            };
        };
        var touchEventToCoordinate = function (touchEvent) {
            touchEvent.preventDefault();
            return {
                x: touchEvent.changedTouches[0].clientX,
                y: touchEvent.changedTouches[0].clientY
            };
        };
        var mouseDowns = observableFromEvent(handle, "mousedown").pipe(map(mouseEventToCoordinate));
        var mouseMoves = observableFromEvent(window, "mousemove").pipe(map(mouseEventToCoordinate));
        var mouseUps = observableFromEvent(window, "mouseup").pipe(map(mouseEventToCoordinate));
        var touchStarts = observableFromEvent(handle, "touchstart").pipe(map(touchEventToCoordinate));
        var touchMoves = observableFromEvent(handle, "touchmove").pipe(map(touchEventToCoordinate));
        var touchEnds = observableFromEvent(window, "touchend").pipe(map(touchEventToCoordinate));
        var starts = observableMerge(mouseDowns, touchStarts);
        var moves = observableMerge(mouseMoves, touchMoves);
        var ends = observableMerge(mouseUps, touchEnds);
        starts.pipe(concatMap(function () { return moves.pipe(takeUntil(ends), map(function (dragEvent) {
            var handleData = isLeft ? _this.getLeftHandleData() : _this.getRightHandleData();
            var xPercent = _this.getXPercentFromOffset(handleData, dragEvent.x);
            _this.updateSlider(handleData, xPercent);
            return xPercent;
        })); })).subscribe();
        starts.pipe(concatMap(function () { return ends.pipe(first(), map(function () {
            var handleData = isLeft ? _this.getLeftHandleData() : _this.getRightHandleData();
            _this.trackPriceChange("priceSlider", handleData.displayPriceElement);
            _this.changeObservable.next();
        })); })).subscribe();
    };
    PriceSlider.prototype.getLeftHandleData = function () {
        return {
            displayPriceElement: this.$priceMinElement,
            element: this.$leftHandle,
            isLeft: true,
            xMax: this.rightHandleXPercent - this.bucketWidth,
            xMin: 0,
            xOffset: 0
        };
    };
    PriceSlider.prototype.getRightHandleData = function () {
        return {
            displayPriceElement: this.$priceMaxElement,
            element: this.$rightHandle,
            isLeft: false,
            xMax: 100,
            xMin: this.leftHandleXPercent + this.bucketWidth,
            xOffset: this.handleTotalWidth
        };
    };
    PriceSlider.prototype.updateSlider = function (handleData, xPercent) {
        var priceVal = Math.round((xPercent / 100) * this.priceUpperBound);
        if (handleData.isLeft) {
            this.updateLeftValues(xPercent, priceVal);
        }
        else {
            this.updateRightValues(xPercent, priceVal);
        }
        this.updateElements(handleData, xPercent, priceVal);
    };
    PriceSlider.prototype.updateLeftValues = function (xPercent, priceVal) {
        this.leftHandleXPercent = xPercent;
        this.selectedMinPrice = priceVal;
    };
    PriceSlider.prototype.updateRightValues = function (xPercent, priceVal) {
        this.rightHandleXPercent = xPercent;
        this.selectedMaxPrice = priceVal;
    };
    PriceSlider.prototype.updateElements = function (handleData, xPercent, priceVal) {
        handleData.element.css("left", xPercent + "%");
        this.$selectedBar.css("left", this.leftHandleXPercent + "%");
        this.$selectedBar.css("width", (this.rightHandleXPercent - this.leftHandleXPercent) + "%");
        var displayPrice = priceVal === this.priceUpperBound ? priceVal + "+" : String(priceVal);
        this.updatePriceDisplayValue(handleData.displayPriceElement, displayPrice);
    };
    PriceSlider.prototype.getXPercentFromOffset = function (handleData, x) {
        var normalizedX = 100 * (x - this.$priceSliderContainer.offset().left) /
            this.$priceSliderContainer.outerWidth();
        return Math.max(Math.min(normalizedX, handleData.xMax), handleData.xMin);
    };
    PriceSlider.prototype.getXPercentFromInput = function (isLeft) {
        var priceDisplayElement = isLeft ? this.$priceMinElement : this.$priceMaxElement;
        var handleData = isLeft ? this.getLeftHandleData() : this.getRightHandleData();
        var price = parseInt(this.getValueFromPriceDisplay(priceDisplayElement), 10);
        var priceAsPercent = (100 * price) / this.priceUpperBound;
        return Math.max(Math.min(priceAsPercent, handleData.xMax), handleData.xMin);
    };
    PriceSlider.prototype.watchMinInput = function () {
        var _this = this;
        observableFromEvent(this.$priceMinElement, "blur")
            .subscribe(function () {
            _this.trackPriceChange("inputBox", _this.$priceMinElement);
        });
    };
    PriceSlider.prototype.watchMaxInput = function () {
        var _this = this;
        observableFromEvent(this.$priceMaxElement, "blur")
            .subscribe(function () {
            _this.trackPriceChange("inputBox", _this.$priceMaxElement);
        });
    };
    PriceSlider.prototype.getValueFromPriceDisplay = function (priceDisplayElement) {
        return priceDisplayElement.is("input") ? priceDisplayElement.val() : priceDisplayElement.text();
    };
    PriceSlider.prototype.updatePriceDisplayValue = function (priceDisplayElement, value) {
        if (priceDisplayElement.is("input")) {
            priceDisplayElement.val(value);
        }
        else {
            priceDisplayElement.text(value);
        }
    };
    PriceSlider.prototype.trackPriceChange = function (source, priceElement) {
        var servletName = priceElement.attr("data-action-servlet-name");
        var pageAction = priceElement.attr("data-action-tag");
        var currency = priceElement.attr("data-tracking-currency");
        var price = this.getValueFromPriceDisplay(priceElement);
        ClickStream.postClickStreamWithTrackingArguments({
            servletName: servletName,
            pageAction: pageAction,
            pageProperties: {
                section: PriceSlider.TRACKING_PAGE_PROPERTY + price, currency: currency, source: source
            },
        });
    };
    PriceSlider.PRICE_SLIDER_DATA_SELECTOR = "#sliderData";
    PriceSlider.FILTERED_CLASS = ".filtered";
    PriceSlider.HANDLE_LEFT_SELECTOR = ".handle.left";
    PriceSlider.HANDLE_RIGHT_SELECTOR = ".handle.right";
    PriceSlider.SLIDER_BAR_SELECTOR = ".slider-bar";
    PriceSlider.PRICE_MIN_SELECTOR = ".price.min";
    PriceSlider.PRICE_MAX_SELECTOR = ".price.max";
    PriceSlider.TOP_PRICE_MIN_SELECTOR = ".top-price.min";
    PriceSlider.TOP_PRICE_MAX_SELECTOR = ".top-price.max";
    PriceSlider.TRACKING_PAGE_PROPERTY = "filter price-";
    return PriceSlider;
}());
export { PriceSlider };
