import { fromEvent as observableFromEvent } from "rxjs";
import { take } from "rxjs/operators";
import { AjaxService, HTML_RESPONSE_TYPE } from "../common/ajax";
import { Url } from "../common";
import $ from "../common/jquery";
import { LanguagePreferenceBanner } from "../languagePreferenceBanner";
import { ListingsSort } from "../listings/listingsSort";
import { ListingsFilterObserver } from "../listings/listingsFilterObserver";
var LanguageSwitcherListener = (function () {
    function LanguageSwitcherListener($window, languageSwitcherContainer, posType) {
        var _this = this;
        this.document = document;
        this.$window = $window;
        this.$languageSwitcherContainer = languageSwitcherContainer;
        this.ajaxService = new AjaxService();
        this.languagePreferenceBanner = new LanguagePreferenceBanner();
        this.watchLanguageSwitcherClick(posType);
        var languageSwitcherModalContent = this.$languageSwitcherContainer.find("#languageSwitchModalContent");
        if (languageSwitcherModalContent && languageSwitcherModalContent.length > 0) {
            observableFromEvent(this.$languageSwitcherContainer, "shown.bs.modal")
                .pipe(take(1))
                .subscribe(function () { return _this.populateCountryFlags(); });
        }
    }
    LanguageSwitcherListener.prototype.watchLanguageSwitcherClick = function (posType) {
        var _this = this;
        var languageMenuItems = this.$languageSwitcherContainer.find(LanguageSwitcherListener.LANGUAGE_MENU_ITEM);
        observableFromEvent(languageMenuItems, "click")
            .subscribe(function (event) {
            if (posType === "TAP") {
                var selectedLocale = $(event.target)
                    .closest(LanguageSwitcherListener.LANGUAGE_MENU_ITEM)
                    .find(LanguageSwitcherListener.LINK_ITEM)
                    .data("locale");
                _this.languagePreferenceBanner.setLanguagePreferenceCookie(false, selectedLocale)
                    .subscribe(function () { return _this.handleLanguageSwitcherClick(event); });
            }
            else {
                _this.handleLanguageSwitcherClick(event);
            }
        });
    };
    LanguageSwitcherListener.prototype.populateCountryFlags = function () {
        var countryFlagElems = this.$languageSwitcherContainer.find(".country-flag");
        $.each(countryFlagElems, function (key, val) {
            var imgUrl = $(val).parent().attr("data-img-url");
            $(val).one("load", function () { return $(val).removeClass("skeleton"); }).attr("src", imgUrl);
        });
    };
    LanguageSwitcherListener.prototype.getBookableLinkQueryParameters = function () {
        return this.ajaxService.postJSONSafe(LanguageSwitcherListener.BOOKABLE_LINK_QUERY_PARAMETERS_URL, null, HTML_RESPONSE_TYPE);
    };
    LanguageSwitcherListener.prototype.appendURLParams = function (url, urlParams) {
        urlParams.localeSwitch = 1;
        var queryString = $.param(urlParams);
        return url + "?" + queryString;
    };
    LanguageSwitcherListener.prototype.handleLanguageSwitcherClick = function (event) {
        var _this = this;
        var elem = $(event.target);
        var selectedItem = elem.closest(LanguageSwitcherListener.LANGUAGE_MENU_ITEM);
        var link = selectedItem.find(LanguageSwitcherListener.LINK_ITEM);
        var url = link.data("value");
        var baseUrl = (link.length > 0) ? link[0].baseURI : "";
        if (baseUrl === undefined) {
            baseUrl = this.document.URL;
        }
        if (url) {
            event.preventDefault();
            var newUrl_1 = this.getLocaleSwitchURL(url, baseUrl);
            this.getBookableLinkQueryParameters()
                .subscribe(function (val) {
                var bookableLink = val.response;
                if (bookableLink) {
                    newUrl_1 += "&" + bookableLink;
                }
            }, function () {
            }, function () {
                _this.$window.location.href = newUrl_1;
            });
        }
    };
    LanguageSwitcherListener.prototype.getLocaleSwitchURL = function (url, baseUrl) {
        var newUrl = url + "?" + LanguageSwitcherListener.LOCALE_SWITCH;
        if (this.isSRPPage(baseUrl)) {
            var urlObj = new Url(baseUrl);
            var searchParams = urlObj.getQueryParameters();
            if (Object.keys(searchParams).length > 0) {
                newUrl = this.appendURLParams(url, searchParams);
            }
        }
        else {
            var urlObj = new Url(baseUrl);
            var queryParams_1 = urlObj.getQueryParameters();
            var paramsForNewURL = [
                ListingsSort.URL_PARAM_NAME,
                ListingsFilterObserver.DYNAMIC_FILTERS_ARG
            ];
            var additionalParams = paramsForNewURL.reduce(function (previousValue, paramForNewURL) {
                if (queryParams_1.hasOwnProperty(paramForNewURL)) {
                    previousValue[paramForNewURL] = queryParams_1[paramForNewURL];
                }
                return previousValue;
            }, {});
            if (Object.keys(additionalParams).length > 0) {
                newUrl = this.appendURLParams(url, additionalParams);
            }
        }
        return newUrl;
    };
    LanguageSwitcherListener.prototype.isSRPPage = function (baseUrl) {
        return baseUrl.indexOf("/searchResults/all") > 0;
    };
    LanguageSwitcherListener.LANGUAGE_MENU_ITEM = ".language-menu-item";
    LanguageSwitcherListener.LINK_ITEM = ".link-item";
    LanguageSwitcherListener.LOCALE_SWITCH = "localeSwitch=1";
    LanguageSwitcherListener.BOOKABLE_LINK_QUERY_PARAMETERS_URL = "/orion/travel-agent/ajax/getBookableLinkQueryParameters";
    return LanguageSwitcherListener;
}());
export { LanguageSwitcherListener };
