import { fromEvent as observableFromEvent } from "rxjs";
import { CONTENT_LOAD_EVENT } from "../genericContentLoader";
import $ from "../common/jquery";
import { WishlistButton } from "../product/wishlistButton";
var ListLayout = (function () {
    function ListLayout(trackingServlet, listLayoutContainerElement, clickStream, gtmLoader) {
        this.listLayoutContainerElement = listLayoutContainerElement;
        this.clickStream = clickStream;
        this.gtmLoader = gtmLoader;
        this.trackingServlet = trackingServlet;
        this.suppressSeeDetailsClickEvent();
        this.addAjaxedElementsToClickStream();
    }
    ListLayout.prototype.addAjaxedElementsToClickStream = function () {
        var _this = this;
        observableFromEvent(document, CONTENT_LOAD_EVENT)
            .subscribe(function () {
            var trackedElements = _this.listLayoutContainerElement.find(".tracked-elements");
            _this.suppressSeeDetailsClickEvent();
            _this.clickStream.addElementsToClickStream(trackedElements);
            new WishlistButton($(".product-list-container"));
        });
    };
    ListLayout.prototype.suppressSeeDetailsClickEvent = function () {
        var _this = this;
        var seeDetailsButtons = this.listLayoutContainerElement.find(".see-details-button");
        seeDetailsButtons.each(function (_, seeDetailsButton) {
            observableFromEvent(seeDetailsButton, "click")
                .subscribe(function (ev) {
                if (_this.trackingServlet === "attraction_detail") {
                    if (_this.gtmLoader) {
                        var gtmIframe = _this.gtmLoader.getGtmIframe();
                        if (gtmIframe) {
                            gtmIframe.pushDataToGtm({
                                event: "seeDetailConversion"
                            });
                        }
                    }
                }
                ev.stopPropagation();
            });
        });
    };
    return ListLayout;
}());
export { ListLayout };
