import $ from "../ts/common/jquery";
import { ClickStream } from "../ts/clickstream";
export function setUpSignInSignUpModal() {
    var _this = this;
    var signInSignUpModal = $("#signInSignUpModal");
    if (signInSignUpModal.length > 0) {
        $(window).on("scroll", function (event) {
            configureLinkToSignInPageRedirect();
            ClickStream.postClickStreamWithTrackingArguments({
                pageAction: "sign_in_sign_up_modal_view",
                servletName: ClickStream.getServlet()
            });
            signInSignUpModal.modal("show");
            $(_this).off(event);
        });
    }
}
function configureLinkToSignInPageRedirect() {
    var link = new URL("/signup", window.location.href);
    link.searchParams.append("login_redirect", encodeURI(window.location.pathname));
    link.searchParams.append("valueProp", "true");
    $("#signInSignUpModalLink").attr("href", link.toString());
}
