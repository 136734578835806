var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ClickStream } from "../../../clickstream";
import { HttpCodes } from "../../../common/HttpCodes";
import { AjaxService, HTML_RESPONSE_TYPE } from "../../../common/ajax";
import $ from "../../../common/jquery";
import { EMPTY as observableEmpty } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { catchError } from "rxjs/operators";
var AffiliateSelectorLink = (function () {
    function AffiliateSelectorLink($document, ajaxService, $window) {
        if (ajaxService === void 0) { ajaxService = new AjaxService(); }
        if ($window === void 0) { $window = window; }
        var _this = this;
        this.shortenedLink = null;
        this.$window = $window;
        this.container = $($document.body);
        this.ajaxService = ajaxService;
        this.$generatedBookableLinkTextArea = this.container.find("#generatedBookableLinkText");
        this.$campaignInput = this.container.find("#bookableLinkCampaignValue");
        this.$copyLinkButton = this.container.find("#affiliateCopyLinkButton");
        this.$copyLinkButtonLabel = this.container.find("#affiliateCopyLinkLabel");
        this.$copyLinkButtonCopiedLabel = this.container.find("#affiliateCopyLinkCopied");
        this.$copyLinkButtonCopiedTick = this.container.find("#affiliateCopyLinkTick");
        this.$shortenLinkRadioButtons = this.container.find("input[name='linkTypeOptions']");
        this.$affiliateLinkPage1 = this.container.find(".affiliateLinkPage1");
        this.$affiliateLinkPage2 = this.container.find(".affiliateLinkPage2");
        this.$modalTitle = this.container.find("#modalTitle");
        this.$generateLinkButton = this.container.find("#generateLinkButton");
        this.$backToPage1Button = this.container.find("#backToPage1");
        this.$shortenLinkRadioButtons.on("change", function () {
            var isChecked = _this.$shortenLinkRadioButtons.filter("#shortLink").prop("checked");
            _this.onchange(isChecked);
            _this.resetCopiedStatus();
        });
        this.$backToPage1Button.on("click", function () {
            _this.showPage1();
        });
        this.$generateLinkButton.on("click", function () {
            _this.handleGenerateLink();
        });
        this.$campaignInput.on("input", function () {
            _this.resetCopiedStatus();
            _this.$campaignInput.parsley().validate();
            _this.updateBookableLinkCampaign();
        });
        this.$copyLinkButton.click(function () {
            var oldPosition = window.pageYOffset;
            _this.$generatedBookableLinkTextArea.select();
            var copied = document.execCommand("copy");
            window.scrollTo(0, oldPosition);
            if (copied) {
                _this.showCopiedSuccessful();
                _this.logCopiedToClipboardToClickStream();
            }
            else {
                _this.resetCopiedStatus();
            }
        });
    }
    AffiliateSelectorLink.prototype.onchange = function (isChecked) {
        return __awaiter(this, void 0, void 0, function () {
            var initialLink;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.submitGenerateBookableLinkByPartner()];
                    case 1:
                        initialLink = _a.sent();
                        if (isChecked) {
                            this.$generatedBookableLinkTextArea.val(this.shortenedLink);
                        }
                        else {
                            this.$generatedBookableLinkTextArea.val(initialLink);
                        }
                        return [2];
                }
            });
        });
    };
    AffiliateSelectorLink.prototype.shortenLink = function (link) {
        var _this = this;
        return this.ajaxService
            .doPost("/createShortLink", { targetUrl: link }, HTML_RESPONSE_TYPE, { "Content-Type": "application/json" })
            .pipe(catchError(function (err) {
            if (err instanceof AjaxError && err.status === HttpCodes.UNAUTHORIZED) {
                _this.$window.location.replace(AffiliateSelectorLink.LOGOUT_URL);
            }
            return observableEmpty;
        }))
            .toPromise()
            .then(function (response) {
            return response.response;
        });
    };
    AffiliateSelectorLink.prototype.submitGenerateBookableLinkByPartner = function () {
        var _this = this;
        return this.ajaxService
            .doPost(AffiliateSelectorLink.AFFILIATE_GENERATE_LINK_BY_PARTNER, {
            affiliateBookableLink: {
                currentUrl: window.location.pathname,
                queryParams: window.location.search.substring(1),
            }
        }, HTML_RESPONSE_TYPE, { "Content-Type": "application/json" })
            .pipe(catchError(function (err) {
            if (err instanceof AjaxError && err.status === HttpCodes.UNAUTHORIZED) {
                _this.$window.location.replace(AffiliateSelectorLink.LOGOUT_URL);
            }
            return observableEmpty;
        }))
            .toPromise()
            .then(function (ajaxResponse) {
            if (ajaxResponse.response) {
                var initialBookableUrl = ajaxResponse.response;
                var campaignValue = _this.$campaignInput.val();
                initialBookableUrl = _this.isValidCampaignValue(campaignValue)
                    ? "" + initialBookableUrl + AffiliateSelectorLink.CAMPAIGN_QUERY_PARAMETER + campaignValue
                    : initialBookableUrl;
                _this.$generatedBookableLinkTextArea.val(initialBookableUrl);
                _this.updateBookableLinkCampaign();
                return initialBookableUrl;
            }
            throw new Error('Invalid response from server');
        });
    };
    AffiliateSelectorLink.prototype.showCopiedSuccessful = function () {
        this.$copyLinkButtonCopiedLabel.removeClass("d-none").addClass("d-block");
        this.$copyLinkButtonCopiedTick.addClass("d-block");
        this.$copyLinkButtonLabel.removeClass("d-block");
    };
    AffiliateSelectorLink.prototype.resetCopiedStatus = function () {
        this.$copyLinkButtonCopiedLabel.addClass("d-none").removeClass("d-block");
        this.$copyLinkButtonCopiedTick.removeClass("d-block");
        this.$copyLinkButtonLabel.addClass("d-block");
    };
    AffiliateSelectorLink.prototype.updateBookableLinkCampaign = function () {
        var campaignValue = this.$campaignInput.val();
        if (!campaignValue || campaignValue.trim().length === 0) {
            var previousBookableUrl = this.$generatedBookableLinkTextArea.val();
            this.$generatedBookableLinkTextArea.val(previousBookableUrl.replace(/&campaign=.*&?$/, ""));
        }
        else if (this.isValidCampaignValue(campaignValue)) {
            var previousBookableUrl = this.$generatedBookableLinkTextArea.val();
            var campaignParameter = "" + AffiliateSelectorLink.CAMPAIGN_QUERY_PARAMETER + campaignValue;
            if (!previousBookableUrl.includes(AffiliateSelectorLink.CAMPAIGN_QUERY_PARAMETER)) {
                previousBookableUrl += campaignParameter;
            }
            else {
                previousBookableUrl = previousBookableUrl.replace(/&campaign=.+&?$/, campaignParameter);
            }
            this.$generatedBookableLinkTextArea.val(previousBookableUrl);
        }
    };
    AffiliateSelectorLink.prototype.isValidCampaignValue = function (campaign) {
        return campaign && campaign.match(AffiliateSelectorLink.CAMPAIGN_VALUE_REGEX);
    };
    AffiliateSelectorLink.prototype.logCopiedToClipboardToClickStream = function () {
        ClickStream.postClickStreamWithTrackingArguments({
            servletName: ClickStream.getServlet(),
            pageAction: "click_copy_url_to_clipboard",
            pageProperties: {
                element_label: "Copy link",
                element_type: "Button",
            }
        });
    };
    AffiliateSelectorLink.prototype.handleGenerateLink = function () {
        return __awaiter(this, void 0, void 0, function () {
            var initialLink, _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.$affiliateLinkPage1.addClass("d-none");
                        this.$affiliateLinkPage2.removeClass("d-none");
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4, this.submitGenerateBookableLinkByPartner()];
                    case 2:
                        initialLink = _b.sent();
                        _a = this;
                        return [4, this.shortenLink(initialLink)];
                    case 3:
                        _a.shortenedLink = _b.sent();
                        this.$generatedBookableLinkTextArea.val(initialLink);
                        this.$shortenLinkRadioButtons.filter("#fullLink").prop("checked", true);
                        return [3, 5];
                    case 4:
                        error_1 = _b.sent();
                        console.error('Error while generating or shortening link:', error_1);
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    AffiliateSelectorLink.prototype.showPage1 = function () {
        this.$affiliateLinkPage1.removeClass("d-none");
        this.$affiliateLinkPage2.addClass("d-none");
    };
    AffiliateSelectorLink.AFFILIATE_GENERATE_LINK_BY_PARTNER = "/orion/affiliate/generate-bookable-link-by-partner";
    AffiliateSelectorLink.LOGOUT_URL = "/logout";
    AffiliateSelectorLink.CAMPAIGN_QUERY_PARAMETER = "&campaign=";
    AffiliateSelectorLink.CAMPAIGN_VALUE_REGEX = /^[A-Z0-9-]+$/i;
    return AffiliateSelectorLink;
}());
export { AffiliateSelectorLink };
