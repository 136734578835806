import { fromEvent as observableFromEvent } from "rxjs";
import { ClickStream } from "../clickstream";
import { StorageFactory } from "../common/storage/storageFactory";
var PartnerDiscountBanner = (function () {
    function PartnerDiscountBanner() {
        this.$partnerDiscountBanner = $(".partner-discount-banner-container");
        this.$partnerDiscountBannerClose = $(".partner-discount-banner-container .partner-discount-banner-close");
        this.window = window;
        this.localStorage = new StorageFactory().window(this.window).localStorage();
        if (this.shouldShowBanner()) {
            this.makeBannerVisible();
            this.attachClickListenerToBannerClose();
        }
    }
    PartnerDiscountBanner.track = function (pageAction) {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: pageAction,
            servletName: ClickStream.getServlet()
        });
    };
    PartnerDiscountBanner.prototype.shouldShowBanner = function () {
        return this.$partnerDiscountBanner && this.hasBannerNotBeenClosed();
    };
    PartnerDiscountBanner.prototype.hasBannerNotBeenClosed = function () {
        return !this.localStorage.getObject(PartnerDiscountBanner.LOCAL_STORAGE_KEY);
    };
    PartnerDiscountBanner.prototype.makeBannerVisible = function () {
        this.$partnerDiscountBanner.removeClass("d-none");
        PartnerDiscountBanner.track("partner_discount_banner_in_view");
    };
    PartnerDiscountBanner.prototype.attachClickListenerToBannerClose = function () {
        var _this = this;
        observableFromEvent(this.$partnerDiscountBannerClose, "click").subscribe(function () { return _this.hideBanner(); });
    };
    PartnerDiscountBanner.prototype.hideBanner = function () {
        PartnerDiscountBanner.track("partner_discount_banner_close");
        this.$partnerDiscountBanner.hide();
        this.persistBannerHiddenFlag();
    };
    PartnerDiscountBanner.prototype.persistBannerHiddenFlag = function () {
        this.localStorage.setObject(PartnerDiscountBanner.LOCAL_STORAGE_KEY, true);
    };
    PartnerDiscountBanner.LOCAL_STORAGE_KEY = "shouldHidePartnerDiscountBanner";
    return PartnerDiscountBanner;
}());
export { PartnerDiscountBanner };
