import { AjaxService } from "./common/ajax";
import { EMPTY as observableEmpty, fromEvent as observableFromEvent, merge as observableMerge, Subject } from "rxjs";
import { GoogleOneTapTrackingHelper } from "./googleOneTapTrackingHelper";
import { tap } from "rxjs/operators";
import { SubfolderUrlPrefix } from "./subfolderUrlPrefix";
import { DismissedReason, NotDisplayedReason, SkippedReason } from "./GoogleOneTapAPI";
import { signInOrSignUpWithGoogleOneTapToken } from "./account/authUtil";
import { localStorage } from "./common/storage";
import bowser from "bowser";
import "../scss/oneTapTermsAndConditions.scss";
import { ViewportBreakpoint } from "./common/utils/viewportBreakpoints";
var AUTO_LOGIN_SUSPENDED_STORAGE_KEY = "autoLoginSuspended";
var AUTO_LOGIN_HIATUS_MS = 24 * 60 * 60 * 1000;
var ONE_TAP_STORAGE_KEY = "oneTapUsed";
var GoogleOneTap = (function () {
    function GoogleOneTap(window, $root, trackingHelper, servletName) {
        var _this = this;
        this.ajaxService = AjaxService.getInstance();
        this.window = window;
        this.document = window.document;
        this.isMobile = window.innerWidth <= ViewportBreakpoint.SM && bowser.mobile;
        this.$root = $root;
        this.servletName = servletName;
        this.trackingHelper = trackingHelper;
        this.googleApiInit = new Subject();
        this.promptShownObservable = new Subject();
        this.$oneTap = this.$root.find("#" + GoogleOneTap.ONE_TAP_CONTAINER_ID_NAME);
        this.showOneTapOnPage = this.$oneTap.length > 0;
        this.oneTapClientId = this.$root.find("body").data("one-tap-client-id");
        this.oneTapCookieDomain = this.$root.find("body").data("one-tap-cookie-domain");
        if (!this.oneTapClientId) {
            this.promptShownObservable = observableEmpty;
            return;
        }
        this.googleScript = this.createGoogleScript();
        observableFromEvent(this.googleScript, "load")
            .subscribe(function () {
            _this.googleAccountsIdApi = google.accounts.id;
            _this.initializeGoogleApi();
        });
        this.injectGoogleScript();
        this.googleApiInit.subscribe(function () {
            if (_this.shouldShowOneTapOnPage()) {
                _this.googleAccountsIdApi.prompt(function (notification) {
                    _this.handlePromptMomentNotification(notification);
                });
            }
        });
        this.handleDisableAutoSelect($root.find(".header"), $root.find("#submitDeactivate"));
    }
    GoogleOneTap.prototype.shouldShowOneTapOnPage = function () {
        return this.showOneTapOnPage;
    };
    GoogleOneTap.prototype.getPromptShownObservable = function () {
        return this.promptShownObservable;
    };
    GoogleOneTap.prototype.handleCredential = function (credentialResponse) {
        if (credentialResponse.credential) {
            this.trackingHelper.trackEvent(credentialResponse.select_by === "auto" ?
                GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_AUTOLOGIN :
                GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_USER_SELECTED, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SELECTED, this.servletName);
            this.authenticateIdToken(credentialResponse.credential, this.$oneTap.find("#oneTapSubscriptionCheckbox:checked").length > 0);
        }
        else {
            this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_FAILED, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_UNKNOWN_ERROR);
        }
    };
    GoogleOneTap.prototype.handlePromptMomentNotification = function (notification) {
        if (!notification) {
            return;
        }
        if (notification.isDisplayed()) {
            this.promptShownObservable.next(true);
            var $subscriptionBox = this.$oneTap.find(".subscription-box");
            if (!localStorage.getObject(ONE_TAP_STORAGE_KEY) && $subscriptionBox.length > 0) {
                this.$oneTap.addClass("js-new-sign-up");
                if (this.isMobile) {
                    var googlePrompt = this.$root.find("#" + GoogleOneTap.GOOGLE_ONE_TAP_IFRAME_PROMPT_ID);
                    if (googlePrompt.length === 0) {
                        console.error("Could not find Google One Tap iframe by ID", GoogleOneTap.GOOGLE_ONE_TAP_IFRAME_PROMPT_ID);
                        this.removeNewSignUp();
                        $subscriptionBox.prop("checked", false);
                    }
                    else {
                        googlePrompt.css("bottom", $subscriptionBox.innerHeight() - 2 + "px");
                        var subscriptionBoxZIndex = $subscriptionBox.css("z-index");
                        if (subscriptionBoxZIndex !== "auto") {
                            googlePrompt.css("z-index", parseInt(subscriptionBoxZIndex, 10) - 1);
                        }
                    }
                }
            }
            this.updateShowNextOneTapTimestamp();
            this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_DISPLAYED, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SHOWN, this.servletName);
        }
        if (notification.isNotDisplayed()) {
            this.promptShownObservable.next(false);
            var notDisplayedReason = NotDisplayedReason[notification.getNotDisplayedReason().toUpperCase()];
            if (notDisplayedReason === NotDisplayedReason.SUPPRESSED_BY_USER) {
                this.trackingHelper.trackEvent(notDisplayedReason, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_NOT_DISPLAYED, this.servletName);
            }
            else {
                this.trackingHelper.trackEvent(notDisplayedReason !== undefined ? notDisplayedReason : "other", GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_DISPLAY_ERROR, this.servletName);
            }
            this.removeNewSignUp();
        }
        if (notification.isSkippedMoment()) {
            var skippedReason = SkippedReason[notification.getSkippedReason().toUpperCase()];
            if (skippedReason === SkippedReason.ISSUING_FAILED) {
                this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_UNAVAILABLE, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_CREDENTIAL_UNAVAILABLE, this.servletName);
            }
            else {
                this.trackingHelper.trackEvent(skippedReason !== undefined ? skippedReason : "other", GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SKIPPED, this.servletName);
            }
            this.removeNewSignUp();
        }
        if (notification.isDismissedMoment()) {
            var dismissedReason = DismissedReason[notification.getDismissedReason().toUpperCase()];
            if (dismissedReason === DismissedReason.CREDENTIAL_RETURNED) {
                this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_SUCCESS, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_CREDENTIAL_RETURNED);
            }
            else {
                this.trackingHelper.trackEvent(dismissedReason !== undefined ? dismissedReason : "other", GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_DISMISSED);
            }
            this.removeNewSignUp();
        }
    };
    GoogleOneTap.prototype.removeNewSignUp = function () {
        if (this.$oneTap) {
            this.$oneTap.removeClass("js-new-sign-up");
        }
    };
    GoogleOneTap.prototype.initializeGoogleApi = function () {
        this.googleAccountsIdApi.initialize({
            auto_select: !localStorage.getObject(AUTO_LOGIN_SUSPENDED_STORAGE_KEY),
            callback: this.handleCredential.bind(this),
            cancel_on_tap_outside: false,
            client_id: this.oneTapClientId,
            prompt_parent_id: GoogleOneTap.ONE_TAP_CONTAINER_ID_NAME,
            state_cookie_domain: this.oneTapCookieDomain
        });
        this.googleApiInit.next(true);
    };
    GoogleOneTap.prototype.handleDisableAutoSelect = function (header, deactivateBtn) {
        var _this = this;
        observableMerge(observableFromEvent(header.find(".logout"), "click"), observableFromEvent(deactivateBtn, "click")).pipe(tap(function () {
            if (_this.googleAccountsIdApi !== undefined) {
                _this.googleAccountsIdApi.disableAutoSelect();
            }
        }), tap(function () {
            _this.logout();
        })).subscribe();
    };
    GoogleOneTap.prototype.logout = function () {
        localStorage.setObject(AUTO_LOGIN_SUSPENDED_STORAGE_KEY, true, AUTO_LOGIN_HIATUS_MS);
        var subfolderUrlPrefix = new SubfolderUrlPrefix(window.document);
        window.location.href = subfolderUrlPrefix.apply(GoogleOneTap.LOGOUT_URL);
    };
    GoogleOneTap.prototype.createGoogleScript = function () {
        var script = this.document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        return script;
    };
    GoogleOneTap.prototype.injectGoogleScript = function () {
        if (this.document.body != null) {
            this.document.body.appendChild(this.googleScript);
        }
    };
    GoogleOneTap.prototype.updateShowNextOneTapTimestamp = function () {
        this.ajaxService.doGetSafe(GoogleOneTap.PROMPT_SHOWN_URL).subscribe();
    };
    GoogleOneTap.prototype.authenticateIdToken = function (idToken, isSubscribe) {
        var _this = this;
        signInOrSignUpWithGoogleOneTapToken(this.ajaxService, idToken, isSubscribe)
            .then(function () {
            localStorage.setObject(ONE_TAP_STORAGE_KEY, true);
            _this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_SUCCESS, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SIGN_IN_SUCCESS);
        })
            .catch(function () {
            _this.trackingHelper.trackEvent(GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_FAILED, GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SIGN_IN_FAILED);
        });
    };
    GoogleOneTap.PROMPT_SHOWN_URL = "/orion/ajax/googleOneTap/hintShown";
    GoogleOneTap.ONE_TAP_CONTAINER_ID_NAME = "googleOneTapContainer";
    GoogleOneTap.LOGOUT_URL = "/logout";
    GoogleOneTap.GOOGLE_ONE_TAP_IFRAME_PROMPT_ID = "credential_picker_iframe";
    return GoogleOneTap;
}());
export { GoogleOneTap };
