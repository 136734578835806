import { fromEvent as observableFromEvent } from "rxjs";
import $ from "../common/jquery";
import { AjaxService, HTML_RESPONSE_TYPE } from "../common/ajax";
var CurrencyChangeListener = (function () {
    function CurrencyChangeListener(currencySwitcherContainer) {
        var _this = this;
        this.window = window;
        this.setCurrency = function (currencyCode) {
            return _this.ajaxService
                .postJSONSafe(CurrencyChangeListener.SET_CURRENCY_URL, currencyCode, HTML_RESPONSE_TYPE)
                .subscribe(function () {
                _this.window.location.reload();
            }, function (error) {
            });
        };
        this.currencySwitcherContainer = currencySwitcherContainer;
        this.ajaxService = new AjaxService();
        this.watchCurrencyClick();
    }
    CurrencyChangeListener.prototype.watchCurrencyClick = function () {
        var _this = this;
        observableFromEvent(this.currencySwitcherContainer.find(CurrencyChangeListener.CURRENCY_MENU_ITEM), "click")
            .subscribe(function (event) {
            var elem = $(event.target);
            var selectedItem = elem.closest(CurrencyChangeListener.CURRENCY_MENU_ITEM);
            var currencyCode = selectedItem.find(CurrencyChangeListener.LINK_ITEM).attr("data-value");
            if (currencyCode) {
                _this.setCurrency(currencyCode);
            }
        });
    };
    CurrencyChangeListener.SET_CURRENCY_URL = "/orion/currency/";
    CurrencyChangeListener.CURRENCY_MENU_ITEM = ".currency-menu-item";
    CurrencyChangeListener.LINK_ITEM = ".link-item";
    return CurrencyChangeListener;
}());
export { CurrencyChangeListener };
