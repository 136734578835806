import { fromEvent as observableFromEvent } from "rxjs";
import $ from "../common/jquery";
var ScrollFrom = (function () {
    function ScrollFrom(source, options, callback) {
        this.defaultConfig = {
            yOffset: 0
        };
        this.$htmlBody = $("html, body");
        this.fromClickEvent(source, options, callback);
    }
    ScrollFrom.scrollTo = function (htmlBody, target, yoffset, callback, scrollSpeed) {
        typeof callback === "function" && setTimeout(callback, 850);
        $(htmlBody).animate({
            scrollTop: $(target).offset().top + yoffset
        }, scrollSpeed || 800);
    };
    ScrollFrom.prototype.fromClickEvent = function (source, options, callback) {
        var _this = this;
        var $source = source instanceof $ ? source : $(source);
        var config = $.extend(true, {}, this.defaultConfig, options);
        observableFromEvent($source, "click")
            .subscribe(function (event) {
            event.preventDefault();
            var $element = $(event.target);
            var $target = $($element.data("scroll-target"));
            var $yoffset = parseInt($target.attr("data-scroll-target-y-offset"), 10) || config.yOffset;
            ScrollFrom.scrollTo(_this.$htmlBody, $target, $yoffset, callback);
        });
    };
    return ScrollFrom;
}());
export { ScrollFrom };
