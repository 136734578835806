import { CookieCategory } from "./cookieCategory";
import { Subject } from "rxjs";
import { isDefinedAndNotNull } from "../common/utils/objectUtil";
var OneTrustApi = (function () {
    function OneTrustApi($window) {
        var _this = this;
        this.consentChangeCount = 0;
        this.priorConsentSubject = new Subject();
        this.readCookie = function (cookieName) {
            var match = document.cookie.match(new RegExp("(^|;\\s*)(" + cookieName + ")=([^;]*)"));
            return match ? decodeURIComponent(match[3]) : null;
        };
        this.getConsentId = function () {
            var consentCookie = _this.readCookie(OneTrustApi.CONSENT_COOKIE_NAME);
            if (isDefinedAndNotNull(consentCookie)) {
                var params = new URLSearchParams(consentCookie);
                return params.get('consentId');
            }
            return null;
        };
        this.oneTrust = {
            test: false,
            notice: undefined,
            consentModel: function () { return _this.getConsentModel($window); },
            priorConsentCallback: function (categories) { return _this.priorConsentSubject.next(categories); }
        };
        this.load();
        var hasOTBannerClosedCookie = !!this.readCookie(OneTrustApi.ALERT_CLOSED_COOKIE_NAME);
        $window[0].OptanonWrapper = function () {
            _this.consentChangeCount += 1;
            var consentModel = _this.getConsentModel($window).name;
            if (_this.consentChangeCount === 1) {
                $window[0].Optanon.OnConsentChanged(function () {
                    _this.priorConsentSubject.next(_this.getCategories($window));
                });
                _this.oneTrustInitialised();
            }
            if (consentModel === 'opt-in' && _this.consentChangeCount === 1 && !hasOTBannerClosedCookie) {
                return;
            }
            if (consentModel === 'opt-in' &&
                _this.consentChangeCount === 2 &&
                !hasOTBannerClosedCookie) {
                return;
            }
            if ((consentModel === 'opt-out' && _this.consentChangeCount === 1) ||
                (consentModel === 'opt-in' && _this.consentChangeCount === 1 && hasOTBannerClosedCookie)) {
                _this.priorConsentSubject.next(_this.getCategories($window));
                return;
            }
            window.location.reload();
        };
        var isGDPR = function () {
            var consentModel = _this.getConsentModel($window).name;
            return consentModel === 'opt-in';
        };
        this.oneTrustNoticeApi = new Promise(function (resolveApiReady) {
            new Promise(function (resolve) {
                _this.resolveNoticeApiScriptLoad = resolve;
            }).then(function () {
                new Promise(function (resolve) {
                    _this.oneTrustInitialised = resolve;
                }).then(function () {
                    _this.oneTrust.notice = {
                        isGDPR: isGDPR,
                        showOptions: function () { return _this.showOptions($window); },
                        consentIsGiven: isGDPR(),
                        regulationId: _this.getRegulationId($window),
                    };
                    _this.oneTrustInitialised = resolveApiReady(_this.oneTrust.notice);
                });
            });
        });
    }
    OneTrustApi.appendScript = function (url, domainScriptAttr, async, onLoadCallback) {
        var scriptTagName = 'script';
        var script = document.createElement(scriptTagName);
        script.async = async;
        script.setAttribute('data-domain-script', domainScriptAttr);
        script.setAttribute('data-document-language', 'true');
        script.src = url;
        script.onload = onLoadCallback || null;
        document.body.appendChild(script);
    };
    OneTrustApi.prototype.getConsentModel = function ($window) {
        try {
            if ($window[0].Optanon) {
                var name_1 = $window[0].Optanon.GetDomainData().ConsentModel.Name;
                var _a = $window[0].Optanon.getGeolocationData(), country = _a.country, state = _a.state;
                if (name_1 === 'opt-in' || name_1 === 'opt-out') {
                    return {
                        name: name_1,
                        country: country,
                        state: state,
                    };
                }
                console.error("Invalid consent model \"" + name_1 + "\". Defaulting to \"opt-out\"");
            }
            return OneTrustApi.defaultConsentModel;
        }
        catch (e) {
            console.error('Could not determine consent model. Defaulting to "opt-out"', e);
            return OneTrustApi.defaultConsentModel;
        }
    };
    OneTrustApi.prototype.getRegulationId = function ($window) {
        if ($window[0].Optanon) {
            var consentModel = $window[0].Optanon.GetDomainData().ConsentModel.Name;
            if (consentModel === 'opt-in') {
                var geoData = $window[0].Optanon.getGeolocationData();
                if (geoData.country === 'US' && geoData.state === 'CA') {
                    return 1;
                }
                return 2;
            }
            if (consentModel === 'opt-out') {
                return 0;
            }
        }
        return 0;
    };
    OneTrustApi.prototype.getCategories = function ($window) {
        var _a;
        var activeGroups = $window[0].OnetrustActiveGroups;
        return _a = {},
            _a[CookieCategory.ANALYTICS_AND_CUSTOMISATION] = activeGroups.includes('C0002'),
            _a[CookieCategory.FUNCTIONAL] = activeGroups.includes('C0003'),
            _a[CookieCategory.ADVERTISING] = activeGroups.includes('C0004'),
            _a[CookieCategory.ALL] = activeGroups.includes('C0002') &&
                activeGroups.includes('C0003') &&
                activeGroups.includes('C0004'),
            _a;
    };
    OneTrustApi.prototype.load = function () {
        var _this = this;
        OneTrustApi.appendScript(OneTrustApi.NOTICE_CDN + "otSDKStub.js", OneTrustApi.VIATOR_DOMAIN_ID + "-test", false, function () {
            return _this.resolveNoticeApiScriptLoad ? _this.resolveNoticeApiScriptLoad() : null;
        });
    };
    OneTrustApi.prototype.getNoticeApi = function () {
        return this.oneTrustNoticeApi;
    };
    OneTrustApi.prototype.showOptions = function ($window) {
        $window[0].Optanon.ToggleInfoDisplay();
    };
    OneTrustApi.prototype.getPriorConsentSubject = function () {
        return this.priorConsentSubject;
    };
    OneTrustApi.prototype.consentId = function () {
        return this.getConsentId();
    };
    OneTrustApi.CDN = '//cdn.cookielaw.org/';
    OneTrustApi.VIATOR_DOMAIN_ID = '9c65a12a-c291-4793-ae97-3f1f4851c1df';
    OneTrustApi.NOTICE_CDN = OneTrustApi.CDN + "scripttemplates/";
    OneTrustApi.ALERT_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed';
    OneTrustApi.CONSENT_COOKIE_NAME = 'OptanonConsent';
    OneTrustApi.defaultConsentModel = {
        name: 'opt-out',
        country: 'US',
        state: 'MA',
    };
    return OneTrustApi;
}());
export { OneTrustApi };
