import $ from "../common/jquery";
import { CookieCategory } from "./cookieCategory";
var GtmConsentManager = (function () {
    function GtmConsentManager(gtmIFrameEnabled, gdprNoticeEnabled) {
        var _this = this;
        this.gtmCommunicationHandler = new Promise(function (resolve) {
            if (gtmIFrameEnabled) {
                _this.resolveGtmCommHandlerOnIFrameLoad(resolve);
            }
            else {
                resolve(function (data) {
                    var gtmWindow = window;
                    gtmWindow.dataLayer = gtmWindow.dataLayer || [];
                    gtmWindow.dataLayer.push(data);
                });
            }
        });
        if (!gdprNoticeEnabled) {
            this.reportConsentEventToGtm([CookieCategory.ALL]);
        }
    }
    GtmConsentManager.prototype.listenForConsentUpdates = function (consentClient) {
        var _this = this;
        consentClient.getNoticeApi().then(function (api) {
            if (!api.isGDPR()) {
                _this.reportConsentEventToGtm([CookieCategory.ALL]);
            }
        });
        consentClient.getPriorConsentSubject().subscribe(function (categories) {
            var consentedCategories = Object.keys(categories).filter(function (category) { return categories[category]; });
            if (consentedCategories.includes(CookieCategory.ADVERTISING)) {
                consentedCategories.push('ad_user_data');
                consentedCategories.push('ad_personalization');
            }
            _this.reportConsentEventToGtm(consentedCategories);
        });
    };
    GtmConsentManager.prototype.resolveGtmCommHandlerOnIFrameLoad = function (resolve) {
        var iframeTimeout = setTimeout(function () {
            throw new Error("Could not load the GTM iframe after "
                + (GtmConsentManager.GTM_IFRAME_LOAD_TIMEOUT_MILLIS / 1000) + " seconds.");
        }, GtmConsentManager.GTM_IFRAME_LOAD_TIMEOUT_MILLIS);
        var resolveToGtmIFrameDataPusher = function (event, gtmIFrame) {
            clearTimeout(iframeTimeout);
            resolve(function (data) { return gtmIFrame.pushDataToGtm(data); });
        };
        $(document).one("gtmIFrameLoad", resolveToGtmIFrameDataPusher);
    };
    GtmConsentManager.prototype.reportConsentEventToGtm = function (consentedCategories) {
        var consentEvent = {
            consentedCategories: consentedCategories,
            event: "consentGiven"
        };
        this.gtmCommunicationHandler.then(function (gtmCommunicationHandler) { return gtmCommunicationHandler(consentEvent); });
    };
    GtmConsentManager.GTM_IFRAME_LOAD_TIMEOUT_MILLIS = 15000;
    return GtmConsentManager;
}());
export { GtmConsentManager };
