export var NotDisplayedReason;
(function (NotDisplayedReason) {
    NotDisplayedReason["BROWSER_NOT_SUPPORTED"] = "browser_not_supported";
    NotDisplayedReason["INVALID_CLIENT"] = "invalid_client";
    NotDisplayedReason["MISSING_CLIENT_ID"] = "missing_client_id";
    NotDisplayedReason["OPT_OUT_OR_NO_SESSION"] = "opt_out_or_no_session";
    NotDisplayedReason["SECURE_HTTP_REQUIRED"] = "secure_http_required";
    NotDisplayedReason["SUPPRESSED_BY_USER"] = "suppressed_by_user";
    NotDisplayedReason["UNREGISTERED_ORIGIN"] = "unregistered_origin";
    NotDisplayedReason["UNKNOWN_REASON"] = "unknown_reason";
})(NotDisplayedReason || (NotDisplayedReason = {}));
export var SkippedReason;
(function (SkippedReason) {
    SkippedReason["AUTO_CANCEL"] = "auto_cancel";
    SkippedReason["USER_CANCEL"] = "user_cancel";
    SkippedReason["TAP_OUTSIDE"] = "tap_outside";
    SkippedReason["ISSUING_FAILED"] = "issuing_failed";
})(SkippedReason || (SkippedReason = {}));
export var DismissedReason;
(function (DismissedReason) {
    DismissedReason["CREDENTIAL_RETURNED"] = "credential_returned";
    DismissedReason["CANCEL_CALLED"] = "cancel_called";
    DismissedReason["FLOW_RESTARTED"] = "flow_restarted";
})(DismissedReason || (DismissedReason = {}));
export var MomentType;
(function (MomentType) {
    MomentType["DISPLAY"] = "display";
    MomentType["SKIPPED"] = "skipped";
    MomentType["DISMISSED"] = "dismissed";
})(MomentType || (MomentType = {}));
