import { EMPTY as observableEmpty, fromEvent as observableFromEvent } from "rxjs";
import { map } from "rxjs/operators";
var RadioFilter = (function () {
    function RadioFilter(parentElement, paramName, elementName, immediateUpdate) {
        this.$parentElement = parentElement;
        this.paramName = paramName;
        this.currentState = this.getEmptyState();
        this.elementName = elementName;
        this.immediateUpdate = immediateUpdate;
    }
    RadioFilter.prototype.clear = function () {
        this.getSelectedElement().prop("checked", false);
        this.currentState = this.getEmptyState();
    };
    RadioFilter.prototype.getState = function () {
        var stateValue = this.getSelectedElement()
            .map(function (index, element) { return element.value; })
            .get()
            .join();
        return this.getStateWithValue(stateValue);
    };
    RadioFilter.prototype.getAppliedOptionsCount = function () {
        return this.getSelectedElement().length;
    };
    RadioFilter.prototype.getChangeObservable = function () {
        var _this = this;
        if (this.immediateUpdate) {
            return observableFromEvent(this.getAllRadios(), "change").pipe(map(function () { return _this.getState(); }));
        }
        else {
            return observableEmpty;
        }
    };
    RadioFilter.prototype.updateFromState = function (state) {
        if (state != null && state[this.paramName] != null) {
            var radioFilterStateValue = state[this.paramName];
            this.clear();
            this.findRadioByValue(radioFilterStateValue).prop("checked", true);
            this.currentState = this.getStateWithValue(radioFilterStateValue);
        }
    };
    RadioFilter.prototype.getStateMessage = function () {
        return this.getSelectedElement().attr("data-message");
    };
    RadioFilter.prototype.revertState = function () {
        this.updateFromState(this.currentState);
    };
    RadioFilter.prototype.findRadioByValue = function (value) {
        return this.$parentElement.find("input[value=\"" + value + "\"][name=\"" + this.elementName + "\"][type=\"radio\"]");
    };
    RadioFilter.prototype.getAllRadios = function () {
        return this.$parentElement.find("input[name=\"" + this.elementName + "\"][type=\"radio\"]");
    };
    RadioFilter.prototype.getSelectedElement = function () {
        return this.$parentElement.find("input[name=\"" + this.elementName + "\"][type=\"radio\"]:checked");
    };
    RadioFilter.prototype.getEmptyState = function () {
        return this.getStateWithValue("");
    };
    RadioFilter.prototype.getStateWithValue = function (value) {
        var _a;
        return _a = {},
            _a[this.paramName] = value,
            _a;
    };
    return RadioFilter;
}());
export { RadioFilter };
