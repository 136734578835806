import { fromEvent as observableFromEvent } from "rxjs";
import { AggregateFilter } from "./listings/aggregateFilter";
import { DateRangeFilter } from "./listings/dateRangeFilter";
import { BannerCancellationFilter } from "./listings/bannerCancellationFilter";
import { ListingsSort } from "./listings/listingsSort";
import { ListingsFilterObserver } from "./listings/listingsFilterObserver";
import { CheckboxesFilter } from "./listings/checkboxesFilter";
import { PriceFilter } from "./listings/priceFilter";
import { RadioFilter } from "./listings/radioFilter";
import { DropdownFilter } from "./listings/dropdownFilter";
import $ from "./common/jquery";
import { AggregateDateRangeFilter } from "./listings/aggregateDateRangeFilter";
var Filters = (function () {
    function Filters() {
    }
    Filters.buildFilters = function ($parentElement, clickStream, shelfService) {
        var $aggregateFiltersWrapper = $parentElement.find("#aggregateFilter");
        if (!$aggregateFiltersWrapper.length) {
            return;
        }
        var pageData = this.getPageData($aggregateFiltersWrapper);
        var aggregateAlternateFilters = [
            new PriceFilter($aggregateFiltersWrapper, true),
            new RadioFilter($aggregateFiltersWrapper, Filters.MIN_RATE_PARAM, Filters.MIN_RATE_ELEMENT_NAME, true)
        ];
        var aggregateCheckboxFilters = $.makeArray($aggregateFiltersWrapper.find(".checkbox-filter"))
            .map(function (value) {
            var $value = $(value);
            return new CheckboxesFilter($aggregateFiltersWrapper, $value.data("filter-param"), $value.attr("name") || $value.data("name"), true, true);
        });
        var filters = [];
        filters.push(new AggregateFilter($aggregateFiltersWrapper, aggregateAlternateFilters.concat(aggregateCheckboxFilters)));
        this.scrollPageToTopWhenAggregateFilterOpened($aggregateFiltersWrapper);
        [
            Filters.createAggregateDateRangeFilter,
            Filters.createBannerCancellationFilter,
            Filters.createDropdownPriceFilter,
            Filters.createDropdownDurationFilter,
            Filters.createDropdownTimeFilter,
            Filters.createDropdownRatingFilter,
            Filters.createDropdownAggregateFilter,
        ]
            .forEach(function (func) {
            var filter = func($parentElement);
            if (filter) {
                filters.push(filter);
            }
        });
        return new ListingsFilterObserver(filters, pageData, $parentElement.find("#productsList").attr("data-applied-filters"), $parentElement.find("#productListCount"), clickStream, $parentElement.find(".product-list-with-count-wrapper"), new ListingsSort($parentElement.find(".listings-sorter")), null, shelfService);
    };
    Filters.buildLeftNavFiltersForProductListPages = function ($parentElement, clickStream, shelfService) {
        var $leftRail = $parentElement.find(".left-rail");
        var $listingFilters = $parentElement.find(".listing-filters");
        var $aggregateFiltersWrapper = $parentElement.find("#aggregateFilter");
        var $aggregateFiltersWrapperDesktop = $parentElement.find("#aggregateFilterDesktop");
        if (!$leftRail.length || !$listingFilters.length || !$aggregateFiltersWrapper.length) {
            return;
        }
        var filters = [];
        filters.push(this.createSupplierFilter($leftRail));
        Filters.addPopularFilters(filters, $leftRail);
        Filters.addLanguageFilters(filters, $leftRail, $aggregateFiltersWrapperDesktop);
        Filters.addPriceAndDurationFilters(filters, $leftRail, true);
        filters.push(new CheckboxesFilter($leftRail, Filters.TAXONOMY_TAG_PARAM, Filters.TAG_ELEMENT_NAME, true, false));
        filters.push(new RadioFilter($leftRail, Filters.MIN_RATE_PARAM, Filters.MIN_RATE_DROPDOWN_ELEMENT_NAME, true));
        filters.push(Filters.createDateRangeFilter($leftRail));
        filters.push(Filters.createAggregateDateRangeFilter($listingFilters));
        var aggregateFilters = [
            new PriceFilter($aggregateFiltersWrapper, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.GROUP_PARAM, Filters.GROUP_ELEMENT_NAME, false, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.SPECIALS_PARAM, Filters.SPECIALS_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.DURATION_PARAM, Filters.DURATION_ELEMENT_NAME, false, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.TIME_PARAM, Filters.TIME_ELEMENT_NAME, false, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.CANCELLATION_PARAM, Filters.CANCELLATION_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.LIKELY_TO_SELL_OUT_PARAM, Filters.LIKELY_TO_SELL_OUT_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.SKIP_THE_LINE_PARAM, Filters.SKIP_THE_LINE_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.PRIVATE_PARAM, Filters.PRIVATE_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.NEWONVIATOR_PARAM, Filters.NEWONVIATOR_ELEMENT_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.TAXONOMY_TAG_PARAM, Filters.TAG_ELEMENT_NAME, false, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.NEWONVIATOR_PARAM, Filters.NEWONVIATOR_ELEMENT_NAME, false, true),
            new RadioFilter($aggregateFiltersWrapper, Filters.MIN_RATE_PARAM, Filters.MIN_RATE_ELEMENT_NAME, false),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.TAG_PARAM, Filters.POPULAR_FILTER_NAME, false, true),
            new CheckboxesFilter($aggregateFiltersWrapper, Filters.LANGUAGE_PARAM, Filters.LANGUAGE_MODAL_ELEMENT_NAME, false, false),
        ];
        filters.push(new AggregateFilter($aggregateFiltersWrapper, aggregateFilters.filter(function (filter) { return filter !== undefined; })));
        this.scrollPageToTopWhenAggregateFilterOpened($aggregateFiltersWrapper);
        var pageData = this.getPageData($aggregateFiltersWrapper);
        return new ListingsFilterObserver(filters.filter(function (filter) { return filter !== undefined; }), pageData, $parentElement.find("#productsList").attr("data-applied-filters"), $parentElement.find("#productListCount"), clickStream, $parentElement.find(".product-list-with-count-wrapper"), new ListingsSort($parentElement.find(".listings-sorter")), null, shelfService);
    };
    Filters.createAggregateDateRangeFilter = function ($parentElement) {
        var dateRangePickerWrapper = $parentElement.find(".date-range-picker-wrapper");
        if (dateRangePickerWrapper.length) {
            var clearDateIcon = dateRangePickerWrapper.find(".clear-date-icon");
            return new AggregateDateRangeFilter(dateRangePickerWrapper, window, clearDateIcon);
        }
    };
    Filters.createSupplierFilter = function ($parent) {
        return new CheckboxesFilter($parent, Filters.SUPPLIER_FILTER_PARAM_NAME, Filters.SUPPLIER_FILTER_ELEMENT_NAME, true, true);
    };
    Filters.findSupplierFilter = function (filters) {
        return filters.find(function (filter) { return (filter instanceof CheckboxesFilter) && filter.getParamName() === Filters.SUPPLIER_FILTER_PARAM_NAME; });
    };
    Filters.removeSupplierFilter = function (filters) {
        filters.filter(function (filter) { return !(filter instanceof CheckboxesFilter) || filter.getParamName() !== Filters.SUPPLIER_FILTER_PARAM_NAME; });
    };
    Filters.addPriceAndDurationFilters = function (filters, $leftRail, addTimeFilter) {
        filters.push(new PriceFilter($leftRail, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.GROUP_PARAM, Filters.GROUP_ELEMENT_NAME, true, false));
        filters.push(new CheckboxesFilter($leftRail, Filters.SPECIALS_PARAM, Filters.SPECIALS_ELEMENT_NAME, true, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.DURATION_PARAM, Filters.DURATION_ELEMENT_NAME, true, false));
        if (addTimeFilter) {
            filters.push(new CheckboxesFilter($leftRail, Filters.TIME_PARAM, Filters.TIME_ELEMENT_NAME, true, false));
        }
        filters.push(new CheckboxesFilter($leftRail, Filters.CANCELLATION_PARAM, Filters.CANCELLATION_ELEMENT_NAME, true, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.LIKELY_TO_SELL_OUT_PARAM, Filters.LIKELY_TO_SELL_OUT_ELEMENT_NAME, true, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.SKIP_THE_LINE_PARAM, Filters.SKIP_THE_LINE_ELEMENT_NAME, true, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.PRIVATE_PARAM, Filters.PRIVATE_ELEMENT_NAME, true, true));
        filters.push(new CheckboxesFilter($leftRail, Filters.NEWONVIATOR_PARAM, Filters.NEWONVIATOR_ELEMENT_NAME, true, true));
    };
    Filters.addPopularFilters = function (filters, $parent) {
        filters.push(new CheckboxesFilter($parent, Filters.TAG_PARAM, Filters.POPULAR_FILTER_NAME, true, true));
    };
    Filters.addLanguageFilters = function (filters, $parent, $aggregateFiltersWrapperDesktop) {
        filters.push(new CheckboxesFilter($parent, Filters.LANGUAGE_PARAM, Filters.LANGUAGE_ELEMENT_NAME, true, false));
        var aggregateFiltersDesktop = [
            new CheckboxesFilter($aggregateFiltersWrapperDesktop, Filters.LANGUAGE_PARAM, Filters.LANGUAGE_MODAL_ELEMENT_NAME, false, false, observableFromEvent($(".show-languages-click"), "click")),
        ];
        filters.push(new AggregateFilter($aggregateFiltersWrapperDesktop, aggregateFiltersDesktop.filter(function (filter) { return filter !== undefined; })));
    };
    Filters.createDateRangeFilter = function ($parentElement) {
        var dateRangePickerWrapper = $parentElement.find(".date-range-picker-wrapper");
        if (dateRangePickerWrapper.length) {
            var clearDateIcon = dateRangePickerWrapper.find(".clear-date-icon");
            return new DateRangeFilter(dateRangePickerWrapper, window, clearDateIcon);
        }
    };
    Filters.createDropdownAggregateFilter = function ($parentElement) {
        var $aggregateDropdownFiltersWrapper = $parentElement.find("#aggregateDropdownFilter");
        if ($aggregateDropdownFiltersWrapper.length) {
            var aggregateCheckboxFilters = $.makeArray($aggregateDropdownFiltersWrapper.find(".checkbox-filter"))
                .map(function (value) {
                var $value = $(value);
                return new CheckboxesFilter($aggregateDropdownFiltersWrapper, $value.data("filter-param"), $value.attr("name"), true, true);
            });
            return new DropdownFilter($aggregateDropdownFiltersWrapper, aggregateCheckboxFilters);
        }
    };
    Filters.createDropdownRatingFilter = function ($parentElement) {
        var $ratingDropdownFilterWrapper = $parentElement.find("#ratingDropdownFilter");
        if ($ratingDropdownFilterWrapper.length) {
            var ratingFilter = new RadioFilter($ratingDropdownFilterWrapper, Filters.MIN_RATE_PARAM, Filters.MIN_RATE_DROPDOWN_ELEMENT_NAME, true);
            var dropdownFilter_1 = new DropdownFilter($ratingDropdownFilterWrapper, [ratingFilter]);
            ratingFilter.getChangeObservable()
                .subscribe(function () { return dropdownFilter_1.closeDropdown(); });
            return dropdownFilter_1;
        }
    };
    Filters.createDropdownDurationFilter = function ($parentElement) {
        var $durationDropdownFilterWrapper = $parentElement.find("#durationDropdownFilter");
        if ($durationDropdownFilterWrapper.length) {
            var durationFilter = new CheckboxesFilter($durationDropdownFilterWrapper, Filters.DURATION_PARAM, Filters.DURATION_ELEMENT_NAME, true, false);
            return new DropdownFilter($durationDropdownFilterWrapper, [durationFilter]);
        }
    };
    Filters.createDropdownTimeFilter = function ($parentElement) {
        var $timeDropdownFilterWrapper = $parentElement.find("#timeDropdownFilter");
        if ($timeDropdownFilterWrapper.length) {
            var timeFilter = new CheckboxesFilter($timeDropdownFilterWrapper, Filters.TIME_PARAM, Filters.TIME_ELEMENT_NAME, true, true);
            var durationFilter = new CheckboxesFilter($timeDropdownFilterWrapper, Filters.DURATION_PARAM, Filters.DURATION_ELEMENT_NAME, true, false);
            return new DropdownFilter($timeDropdownFilterWrapper, [timeFilter, durationFilter]);
        }
    };
    Filters.createDropdownPriceFilter = function ($parentElement) {
        var $priceDropdownFilterWrapper = $parentElement.find("#priceDropdownFilter");
        if ($priceDropdownFilterWrapper.length) {
            var priceFilterContainer = $parentElement.find("#priceDropdownFilter");
            var priceFilter = new PriceFilter(priceFilterContainer, true);
            var dealsFilter = new CheckboxesFilter(priceFilterContainer, Filters.SPECIALS_PARAM, Filters.SPECIALS_ELEMENT_NAME, true, true);
            var dropdownFilter = new DropdownFilter($priceDropdownFilterWrapper, [priceFilter, dealsFilter]);
            if ($parentElement.find("#priceFilterMenuButton").data("pre-apply")) {
                dropdownFilter.updateFromState({ priceFilter: "-", specialsFilter: "deals-and-discounts" });
            }
            return dropdownFilter;
        }
    };
    Filters.createBannerCancellationFilter = function ($parentElement) {
        return new BannerCancellationFilter($parentElement);
    };
    Filters.getPageData = function ($aggregateFiltersWrapper) {
        return {
            adGroupId: $aggregateFiltersWrapper.data("adGroupId") || "",
            ajaxUrlPath: $aggregateFiltersWrapper.data("ajax-url"),
            attractionId: $aggregateFiltersWrapper.data("attractionId") || 0,
            campaignId: $aggregateFiltersWrapper.data("campaignId") || "",
            categoryId: $aggregateFiltersWrapper.data("categoryId"),
            destinationId: $aggregateFiltersWrapper.data("destinationId"),
            groupId: $aggregateFiltersWrapper.data("groupId"),
            productCode: $aggregateFiltersWrapper.data("productCode") || "",
            recommendationId: $aggregateFiltersWrapper.data("recommendationId") || 0,
            semRecommendationLander: $aggregateFiltersWrapper.data("semLander"),
            superFilterTagId: $aggregateFiltersWrapper.data("superFilterTagId"),
            tagCategoryId: $aggregateFiltersWrapper.data("tagCategoryId"),
            tagId: $aggregateFiltersWrapper.data("tagId"),
            taxonomyEligibleTagFilters: $aggregateFiltersWrapper.data("taxonomyEligibleTagFilters") || "",
            tripDestinationType: $aggregateFiltersWrapper.data("tripDestinationType") || "",
            tripDestinationId: $aggregateFiltersWrapper.data("tripDestinationId") || 0,
            latitude: $aggregateFiltersWrapper.data("latitude") || "",
            longitude: $aggregateFiltersWrapper.data("longitude") || "",
        };
    };
    Filters.scrollPageToTopWhenAggregateFilterOpened = function ($parentElement) {
        observableFromEvent($parentElement, "show.bs.modal")
            .subscribe(function () {
            ListingsFilterObserver.scrollToTopOfFilters(1);
        });
    };
    Filters.TIME_PARAM = "timeFilter";
    Filters.TIME_ELEMENT_NAME = "time-option";
    Filters.TAG_PARAM = "tagFilter";
    Filters.TAXONOMY_TAG_PARAM = "taxonomyTagFilter";
    Filters.TAG_ELEMENT_NAME = "tag-option";
    Filters.DURATION_PARAM = "durationFilter";
    Filters.DURATION_ELEMENT_NAME = "duration-option";
    Filters.SPECIALS_PARAM = "specialsFilter";
    Filters.SPECIALS_ELEMENT_NAME = "specials-option";
    Filters.CANCELLATION_PARAM = "cancellationFilter";
    Filters.CANCELLATION_ELEMENT_NAME = "cancellation-option";
    Filters.LIKELY_TO_SELL_OUT_PARAM = "likelyToSellOut";
    Filters.LIKELY_TO_SELL_OUT_ELEMENT_NAME = "likelyToSellOut-option";
    Filters.SKIP_THE_LINE_PARAM = "skipTheLine";
    Filters.SKIP_THE_LINE_ELEMENT_NAME = "skipTheLine-option";
    Filters.PRIVATE_PARAM = "privateExperience";
    Filters.PRIVATE_ELEMENT_NAME = "private-option";
    Filters.NEWONVIATOR_PARAM = "newOnViator";
    Filters.NEWONVIATOR_ELEMENT_NAME = "newOnViator-option";
    Filters.GROUP_PARAM = "groupFilter";
    Filters.GROUP_ELEMENT_NAME = "group-option";
    Filters.MIN_RATE_PARAM = "minimumRating";
    Filters.MIN_RATE_ELEMENT_NAME = "minRating-option-mw";
    Filters.MIN_RATE_DROPDOWN_ELEMENT_NAME = "minRating-option-dt";
    Filters.SUPPLIER_FILTER_PARAM_NAME = "supplierFilter";
    Filters.SUPPLIER_FILTER_ELEMENT_NAME = "supplierFilter";
    Filters.POPULAR_FILTER_NAME = "popularFilter";
    Filters.LANGUAGE_PARAM = "languageFilter";
    Filters.LANGUAGE_ELEMENT_NAME = "language-option";
    Filters.LANGUAGE_MODAL_ELEMENT_NAME = "language-modal-option";
    return Filters;
}());
export { Filters };
