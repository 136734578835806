import { ClickStream } from "./clickstream";
var GoogleOneTapTrackingHelper = (function () {
    function GoogleOneTapTrackingHelper() {
    }
    GoogleOneTapTrackingHelper.prototype.trackEvent = function (pageAttribute, pageAction, servletName) {
        if (servletName === void 0) { servletName = GoogleOneTapTrackingHelper.GOOGLE_ONE_TAP_SERVLET_NAME; }
        ClickStream.postClickStreamWithTrackingArguments({ servletName: servletName,
            pageAction: pageAction,
            pageProperties: {
                module: GoogleOneTapTrackingHelper.PAGE_PROPERTY_MODULE,
                pageAttribute: pageAttribute
            }
        });
    };
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SHOWN = "google_onetap_vi_shown";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SKIPPED = "google_onetap_vi_skipped_moment";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_DISMISSED = "google_onetap_vi_dismissed_moment";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_NOT_DISPLAYED = "google_onetap_vi_not_displayed";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_DISPLAY_ERROR = "google_onetap_vi_display_error";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SELECTED = "google_onetap_vi_selected";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_CREDENTIAL_RETURNED = "google_onetap_vi_credential_returned";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_CREDENTIAL_UNAVAILABLE = "google_onetap_vi_login_unavailable";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_UNKNOWN_ERROR = "google_onetap_vi_unknown_error";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SIGN_IN_SUCCESS = "google_onetap_vi_login_success";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SIGN_IN_FAILED = "google_onetap_vi_login_failed";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_NEW_MEMBER_SUCCESS = "google_onetap_vi_new_member_success";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_NEW_MEMBER_FAILED = "google_onetap_vi_new_member_failed";
    GoogleOneTapTrackingHelper.PAGE_ACTION_GOOGLE_ONE_TAP_SHOWN_T_AND_C_CANCELLED = "google_onetap_vi_terms_canceled";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_DISPLAYED = "displayed";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_AUTOLOGIN = "autologin";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_USER_SELECTED = "user_selected";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_UNAVAILABLE = "credential_unavailable";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_SUCCESS = "success";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_FAILED = "failed";
    GoogleOneTapTrackingHelper.PAGE_ATTRIBUTE_CANCELLED = "canceled";
    GoogleOneTapTrackingHelper.GOOGLE_ONE_TAP_SERVLET_NAME = "google_onetap";
    GoogleOneTapTrackingHelper.PAGE_PROPERTY_MODULE = "google_onetap";
    return GoogleOneTapTrackingHelper;
}());
export { GoogleOneTapTrackingHelper };
