import { merge as observableMerge } from "rxjs";
import $ from "../common/jquery";
var DropdownFilter = (function () {
    function DropdownFilter($filterContainer, filters) {
        this.$filterContainer = $filterContainer;
        this.filters = filters;
    }
    DropdownFilter.prototype.onMount = function () {
        this.filters.forEach(function (f) { return f.onMount && f.onMount(); });
    };
    DropdownFilter.prototype.getState = function () {
        return $.extend.apply($, [{}].concat(this.filters.map(function (f) { return f.getState(); })));
    };
    DropdownFilter.prototype.getAppliedOptionsCount = function () {
        return this.filters.map(function (filter) { return filter.getAppliedOptionsCount(); }).reduce(function (a, b) { return a + b; });
    };
    DropdownFilter.prototype.clear = function () {
        this.filters.forEach(function (f) { return f.clear(); });
        this.updateAppliedOptions();
    };
    DropdownFilter.prototype.getChangeObservable = function () {
        return observableMerge.apply(void 0, this.filters.map(function (filter) { return filter.getChangeObservable(); }));
    };
    DropdownFilter.prototype.updateFromState = function (state) {
        this.filters.forEach(function (f) { return f.updateFromState(state); });
        this.updateAppliedOptions();
    };
    DropdownFilter.prototype.revertState = function () {
        this.filters.forEach(function (f) { return f.revertState(); });
        this.updateAppliedOptions();
    };
    DropdownFilter.prototype.updateCounts = function (filterCounts) {
        this.filters.forEach(function (f) { return f.updateCounts && f.updateCounts(filterCounts); });
    };
    DropdownFilter.prototype.getStateMessage = function () {
        var message = "";
        this.filters.forEach(function (f) {
            if (f.getStateMessage() && f.getStateMessage() !== "") {
                message = f.getStateMessage();
            }
        });
        return (this.getAppliedOptionsCount() === 1) ? message : "";
    };
    DropdownFilter.prototype.closeDropdown = function () {
        var openDropdowns = this.$filterContainer.filter(".dropdown.show").find("button");
        openDropdowns.dropdown("toggle");
    };
    DropdownFilter.prototype.updateAppliedOptions = function () {
        var count = this.getAppliedOptionsCount() > 0 ? this.getAppliedOptionsCount() : 0;
        this.updateBorderColor(count);
        this.updateText(".filter-count", String(count));
        this.updateText(".button-label", this.getStateMessage() || this.$filterContainer.find(".button-label").data("placeholder"));
        this.clearOtherClasses();
        if (count === 0) {
            this.$filterContainer.addClass("zero-filters");
        }
        else if (count === 1) {
            if (this.getStateMessage()) {
                this.$filterContainer.addClass("one-filter-message");
            }
            else {
                this.$filterContainer.addClass("one-filter-icon");
            }
        }
        else {
            this.$filterContainer.addClass("many-filters");
        }
    };
    DropdownFilter.prototype.clearOtherClasses = function () {
        this.$filterContainer.removeClass("many-filters one-filter-message one-filter-icon zero-filters");
    };
    DropdownFilter.prototype.updateText = function (selector, text) {
        this.$filterContainer.find(selector).text(text);
    };
    DropdownFilter.prototype.updateBorderColor = function (count) {
        this.$filterContainer.find(".btn").toggleClass("border-secondary", count > 0);
    };
    return DropdownFilter;
}());
export { DropdownFilter };
