import "bootstrap";
import "../scss/typeahead.scss";
import "../scss/show-hide-password.scss";
import "../scss/loginPopover.scss";
import "../scss/login-modal.scss";
import "../scss/_loading-bubbles.scss";
import "../scss/_large-checkbox.scss";
import "../scss/_spinner.scss";
import "../scss/_skeleton.scss";
import "../scss/_no-photo.scss";
import "../scss/helpCenter.scss";
import "../scss/ltsoModal.scss";
import "../scss/signin-signup/signin-signup-modal.scss";
import "../scss/get-to-know-you/get-to-know-you-modal.scss";
import "../scss/wishlists/wishlist-modals.scss";
import "../scss/footerModal.scss";
import "../scss/footerAppDownloadBadges.scss";
import { take, throttleTime } from "rxjs/operators";
import { TravelDirective } from "../ts/footer/travelDirective";
import { CurrencyChangeListener } from "../ts/header/currencyChangeListener";
import { LanguageSwitcherListener } from "../ts/header/languageSwitcherListener";
import { SideNav } from "../ts/header/sideNav";
import { ExperienceTypeaheadWrapper } from "../ts/experienceTypeaheadWrapper";
import { LazyLoadContent } from "../ts/common/lazyLoadContent";
import { ImageFallback } from "../ts/common/imageFallback";
import { LoginForm } from "../ts/account/loginForm";
import { Logout } from "../ts/tap/logout";
import { TapBookableShareLink } from "../ts/tap/tapBookableShareLink";
import { AffiliateSelectorLink } from "../ts/partners/affiliate/selector/affiliateSelectorLink";
import { HoverDropdown } from "../ts/hoverDropdown";
import { ClickDropdown } from "../ts/clickDropdown";
import { Modal } from "../ts/modal";
import { AjaxService } from "../ts/common/ajax";
import { LanguagePreferenceBannerCloseListener } from "../ts/header/languagePreferenceBannerCloseListener";
import { ClickStream } from "../ts/clickstream";
import { GoogleOneTap } from "../ts/googleOneTap";
import { LoginModalSuccessHandler } from "../ts/account/handler/loginModalSuccessHandler";
import { LoginSignupModal } from "../ts/account/loginSignupModal";
import { VisitorProfile } from "../ts/visitorProfile";
import { Popover } from "../ts/common/popover";
import { ForgotPasswordForm } from "../ts/tap/forgotPassword/forgotPasswordForm";
import { ForgotPassword } from "../ts/tap/forgotPassword/forgotPassword";
import { GoogleOneTapTrackingHelper } from "../ts/googleOneTapTrackingHelper";
import { LoginSignupPopover } from "../ts/header/loginSignupPopover";
import { RNPLPromoModal } from "../ts/header/rnplPromoModal";
import { WishlistButton } from "../ts/product/wishlistButton";
import { HelpCenterLoader } from "../ts/helpCenterLoader";
import { HelpButtonWithUnreadCount } from "../ts/header/helpButtonWithUnreadCount";
import { HelpLink } from "../ts/helpLink";
import { TrackingConsentManager } from "../ts/consent/trackingConsentManager";
import { GtmConsentManager } from "../ts/consent/gtmConsentManager";
import { SubfolderUrlPrefix } from "../ts/subfolderUrlPrefix";
import { InlineElements } from "../ts/footer/inlineElements";
import { InlineUspBanners } from "../ts/footer/inlineUspBanners";
import { PartnerDiscountBanner } from "../ts/header/partnerDiscountBanner";
import { getMetaContent } from "../ts/common/utils/metaContent";
import { fromEvent as observableFromEvent } from "rxjs";
import { handleSocialAuthRedirectResult } from "../ts/account/authUtil";
import { localStorage } from "../ts/common/storage";
import { setUpSignInSignUpModal } from "./signInSignUpModal";
import { setUpGetToKnowYouModal } from "./getToKnowYouModal";
import { GoogleGlobalSiteTag } from "../ts/googleGlobalSiteTag";
import { OneTrustApi } from "../ts/onetrust/OneTrustApi";
var IP_LOCATION_PROFILE_TYPE = "IPLocation";
$(function () {
    var ajaxService = new AjaxService();
    var $document = $(document);
    var $headerElement = $(".header");
    new SideNav($headerElement);
    var $footerElement = $("#footer");
    var posType = $footerElement.find("input[name=posType]").val();
    var typeaheadOptionsDropdown = { fadeBackground: true };
    var $searchIcon = $headerElement.find(".navbar-wrapper .search-icon");
    if ($searchIcon && $searchIcon.length) {
        typeaheadOptionsDropdown["toggleElement"] = $searchIcon;
    }
    var $typeaheadInline = $(".header-search-inline");
    if ($typeaheadInline.length) {
        observableFromEvent($(".inline-search-wrapper input, .inline-search-wrapper button"), "focus")
            .pipe(take(1))
            .subscribe(function () {
            new ExperienceTypeaheadWrapper(window, $typeaheadInline, {});
        });
        var isEagleSearch = $('.eagle-header-search').length === 1;
        var isNoResultsPage = $('.noResultsTypeahead').length === 1;
        var fadeBackground_1 = $('.travel-agent-search').length === 0;
        if (isEagleSearch && !isNoResultsPage) {
            var $eagleSearchInputButton_1 = $(".eagle-header-search input, .eagle-header-search button");
            observableFromEvent($eagleSearchInputButton_1, "focus")
                .pipe(take(1))
                .subscribe(function () {
                new ExperienceTypeaheadWrapper(window, $typeaheadInline, { fadeBackground: fadeBackground_1, useMouseDownTrigger: true });
                $eagleSearchInputButton_1.trigger("mousedown");
            });
        }
    }
    observableFromEvent($(".ltso-tag-modal"), "click")
        .subscribe(function (event) {
        $("#ltsoModal").modal("show");
        event.stopPropagation();
    });
    var $typeaheadDropDown = $(".header-search-modal");
    if ($typeaheadDropDown.length) {
        observableFromEvent($searchIcon, "touchstart")
            .pipe(take(1))
            .subscribe(function (event) {
            var $closestTypeaheadDropDown = $(event.currentTarget).closest('.header').find('.header-search-modal');
            new ExperienceTypeaheadWrapper(window, $closestTypeaheadDropDown, typeaheadOptionsDropdown);
        });
    }
    ImageFallback.process($document);
    new WishlistButton($(document));
    new HelpButtonWithUnreadCount($(document), window, 0).bootstrap();
    new Logout($headerElement).bootstrap();
    var $tapLoginModal = $("#tapLoginModal");
    if ($tapLoginModal && $tapLoginModal.length > 0) {
        (function (w) {
            w.URLSearchParams = w.URLSearchParams || function (searchString) {
                var self = this;
                self.searchString = searchString;
                self.get = function (name) {
                    var results = new RegExp("[\?&]" + name + "=([^&#]*)").exec(self.searchString);
                    if (results == null) {
                        return null;
                    }
                    else {
                        return decodeURI(results[1]) || 0;
                    }
                };
            };
        })(window);
        var tapLoginForm = new LoginForm($tapLoginModal).initialize();
        new LoginSignupModal($tapLoginModal, tapLoginForm, new LoginModalSuccessHandler());
        new Logout($headerElement).bootstrap();
        var url = window.location;
        var urlParamsString = url.search;
        var urlParams = new URLSearchParams(urlParamsString);
        var loginModalQueryParam = "loginModal";
        if (urlParams.get(loginModalQueryParam) === "true") {
            $tapLoginModal.modal("show");
        }
    }
    var $body = $("body");
    var $tapForgotPasswordModal = $("#tapForgotPasswordModal");
    if ($tapForgotPasswordModal && $tapForgotPasswordModal.length > 0) {
        var $tapForgotPasswordForm = new ForgotPasswordForm($body, $tapForgotPasswordModal);
        new ForgotPassword($tapForgotPasswordModal, $tapForgotPasswordForm);
    }
    new TapBookableShareLink($document, ajaxService, window).bootstrap();
    new AffiliateSelectorLink(document, ajaxService, window);
    new HoverDropdown($("#dropdownMenuButton"), $("#accountMenuContents"));
    new ClickDropdown($("#accountMenuButton"), $("#accountMenuContentsForProfile"));
    new ClickDropdown($("#LanguageAndCurrencyMenuButton"), $("#languageAndCurrencyMenuContents"));
    new ClickDropdown($("#RewardsBalanceMenuButton"), $("#rewardsBalanceMenuContents"));
    new Popover($headerElement);
    var pageName = $("meta[name='_page_name']").attr("content");
    var $numItemsInCart = $("#numItemInCart");
    var showingSignInSignUpModal = $("#signInSignUpModal").length > 0;
    var rnplPromoModal = $(".rnplPromoModal");
    var showRNPLPromoModal = rnplPromoModal.length > 0 && !showingSignInSignUpModal;
    var reminderEligible = $numItemsInCart.attr("data-reminder-eligible") === "true";
    setUpSignInSignUpModal();
    setUpGetToKnowYouModal();
    var googleOneTap = new GoogleOneTap(window, $document, new GoogleOneTapTrackingHelper(), ClickStream.getServlet());
    if ($(LoginSignupPopover.POPOVER_CLASS_SELECTOR).length > 0) {
        var shouldShowLoginSignupPopover = $footerElement.data("shouldShowLoginSignupPopover") === true;
        var isEligibleToShowLoginPopover = shouldShowLoginSignupPopover && !reminderEligible && !showRNPLPromoModal;
        new LoginSignupPopover($body, pageName, isEligibleToShowLoginPopover, googleOneTap.getPromptShownObservable());
    }
    new RNPLPromoModal(showRNPLPromoModal, rnplPromoModal);
    var lazyLoadOptions = {};
    if (getMetaContent("_page_name") === "homepage") {
        lazyLoadOptions.heightThreshold = 200;
    }
    new LazyLoadContent(document, lazyLoadOptions);
    var $languageSwitcherDropdown = $("#languageSwitcherDropdown");
    var languageSwitcherListener = new LanguageSwitcherListener(document, $languageSwitcherDropdown, posType);
    var headerLanguageDropdown = new HoverDropdown($("#languageSwitcherDropdownButton"), $languageSwitcherDropdown);
    headerLanguageDropdown.getMouseEnterObservable()
        .pipe(take(1))
        .subscribe(function () { return languageSwitcherListener.populateCountryFlags(); });
    new LanguageSwitcherListener(document, $("#languageSwitcherModal"), posType);
    new LanguageSwitcherListener(document, $("#languageSwitcherModalDesktop"), posType);
    var languagePreferenceBanner = $(".language-preference-banner");
    new LanguagePreferenceBannerCloseListener(languagePreferenceBanner);
    if (languagePreferenceBanner && languagePreferenceBanner.length > 0) {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "language_preference_banner_loaded",
            servletName: "homepage"
        });
    }
    new InlineUspBanners().setUp();
    new PartnerDiscountBanner();
    var $languageSwitcherFooter = $("#languageSwitcherModalButtonFooter");
    var $currencySwitcherFooter = $("#currencySwitcherModalButtonFooter");
    if (($languageSwitcherFooter && $languageSwitcherFooter.length > 0) &&
        ($currencySwitcherFooter && $currencySwitcherFooter.length > 0)) {
        new InlineElements($languageSwitcherFooter, $currencySwitcherFooter, $(window));
    }
    var $currencySwitcherDropdown = $("#currencySwitcherDropdown");
    new HoverDropdown($("#currencySwitcherDropdownButton"), $currencySwitcherDropdown);
    new CurrencyChangeListener($currencySwitcherDropdown);
    new CurrencyChangeListener($("#currencySwitcherModal"));
    new CurrencyChangeListener($("#currencySwitcherModalDesktop"));
    if ($footerElement.data("collectSignals") === true) {
        var visitorData = {
            visitorProfileType: IP_LOCATION_PROFILE_TYPE
        };
        new VisitorProfile(ajaxService).postVisitorProfile(visitorData);
    }
    new Modal($(window), $body, $(".footer-modal"));
    new Modal($(window), $body, $(".switcher-modal"));
    var $travelDirectiveContainer = $footerElement.find(".travel-directive-container");
    $travelDirectiveContainer && new TravelDirective($travelDirectiveContainer);
    HelpCenterLoader.bootstrap(window);
    var $helpLink = $footerElement.find(".js-help-link");
    if ($helpLink.length) {
        new HelpLink($helpLink).bootstrap();
    }
    var gdprOneTrustNoticeEnabled = $footerElement.data("gdprOnetrustNoticeEnabled");
    var gtmConsentManager;
    var gtmDataElement = $("viator-gtm");
    if (gtmDataElement.length) {
        var gtmIFrameEnabled = gtmDataElement.data("enabled");
        gtmConsentManager = new GtmConsentManager(gtmIFrameEnabled, gdprOneTrustNoticeEnabled);
    }
    var consentClient;
    if (gdprOneTrustNoticeEnabled) {
        consentClient = new OneTrustApi($(window));
        var subfolderUrlPrefix = new SubfolderUrlPrefix(window.document);
        var lang = subfolderUrlPrefix.prefix.replace("/", "");
        $footerElement.find(".show-cookie-consent-options").on("click", function (evt) {
            evt.preventDefault();
            consentClient.showOptions($(window));
        });
        new TrackingConsentManager(consentClient, $footerElement.data("analyticsAndCustomisationEnabled"));
        if (gtmConsentManager) {
            gtmConsentManager.listenForConsentUpdates(consentClient);
        }
        GoogleGlobalSiteTag.initGoogleSiteTag(consentClient);
    }
    else {
        GoogleGlobalSiteTag.initGoogleSiteTag(null);
    }
    handleSocialAuthRedirectResult();
    toggleIeNotSupportedModal(gdprOneTrustNoticeEnabled, consentClient, $footerElement.data("analyticsAndCustomisationEnabled"));
    toggleAppDownloadBadgesOnDesktopFooter();
    observableFromEvent(window, "resize")
        .pipe(throttleTime(50))
        .subscribe(toggleAppDownloadBadgesOnDesktopFooter);
});
function toggleIeNotSupportedModal(gdprNoticeEnabled, consentClient, analyticsAndCustomisationEnabled) {
    var id = "ieNonSupportModal";
    if ((document.getElementById(id) != null) && (localStorage.get(id) !== "shown")) {
        $(function () {
            $("#" + id).modal("show");
            if (gdprNoticeEnabled) {
                consentClient
                    .getNoticeApi()
                    .then(function (api) {
                    if (api.consentIsGiven) {
                        localStorage.set(id, "shown");
                    }
                });
            }
            else if (analyticsAndCustomisationEnabled) {
                localStorage.set(id, "shown");
            }
        });
    }
}
function toggleAppDownloadBadgesOnDesktopFooter() {
    var badgesContainer = $(".app-download-badges-container");
    var LAYOUT_LG_BREAKPOINT = 992;
    var parentSelector = window.innerWidth < LAYOUT_LG_BREAKPOINT ? '.non-desktop-badge' : '.desktop-badge';
    if (!badgesContainer.parent().is(parentSelector)) {
        badgesContainer.prependTo(parentSelector);
    }
}
