import { fromEvent as observableFromEvent, merge as observableMerge, EMPTY as observableEmpty, Subject } from "rxjs";
import { map, mergeMap, tap, distinctUntilChanged } from "rxjs/operators";
import { DateRangeFilter } from "./dateRangeFilter";
import { ClickStream } from "../clickstream";
import { AjaxService } from "../common/ajax";
import { ListingsSort } from "./listingsSort";
import { ScrollFrom } from "../common";
import { NextPageButton } from "./nextPageButton";
import { ListLayout } from "./listLayout";
import { AggregateDateRangeFilter } from "./aggregateDateRangeFilter";
import { SlickSlider } from "../carousel/slickSlider";
import { TooltipLoader } from "../common/tooltipLoader";
import { ExcellentBadge } from "../excellent";
import "url-search-params-polyfill";
import $ from "../common/jquery";
import { ViewportBreakpoint } from "../common/utils/viewportBreakpoints";
import { DynamicFilters } from "../dynamicFilters";
import { Filters } from "../filters";
import { SupplierFilterSearch } from "./supplierFilterSearch";
var ListingsFilterObserver = (function () {
    function ListingsFilterObserver(filters, pageData, existingFilterState, tourCount, clickStream, $productList, sorter, nextPageButton, shelfService) {
        var _this = this;
        this.ajax = new AjaxService();
        this.allFilters = filters;
        this.clickStream = clickStream;
        this.destinationId = pageData.destinationId;
        this.dynamicFilters = this.getValueFromUrl(ListingsFilterObserver.DYNAMIC_FILTERS_ARG);
        this.productCode = pageData.productCode;
        this.groupId = pageData.groupId;
        this.categoryId = pageData.categoryId;
        this.tagCategoryId = pageData.tagCategoryId;
        this.tagId = pageData.tagId;
        this.superFilterTagId = pageData.superFilterTagId;
        this.attractionId = pageData.attractionId;
        this.recommendationId = pageData.recommendationId;
        this.adGroupId = pageData.adGroupId;
        this.campaignId = pageData.campaignId;
        this.searchTerm = pageData.searchTerm;
        this.ajaxUrlPath = pageData.ajaxUrlPath;
        this.semRecommendationFlag = pageData.semRecommendationLander;
        this.taxonomyEligibleTagFilters = pageData.taxonomyEligibleTagFilters;
        this.tripDestinationType = pageData.tripDestinationType;
        this.tripDestinationId = pageData.tripDestinationId;
        this.$productListSection = $productList;
        this.$tourCount = tourCount;
        this.clearObs = new Subject();
        this.sorter = sorter;
        this.nextPageButton = nextPageButton;
        this.filtersAppliedObservable = new Subject();
        this.filtersLoadingObservable = new Subject();
        this.shelfService = shelfService;
        this.filterUpdateSubscriptions = [];
        this.latitude = pageData.latitude;
        this.longitude = pageData.longitude;
        var sortObservable = this.sorter ? this.sorter.getChangeObservable() : observableEmpty;
        this.filterChangeObservable = observableMerge.apply(void 0, [this.clearObs, sortObservable].concat(this.allFilters.map(function (filter) { return filter.getChangeObservable(); })));
        this.addFilterChangeSubscriptions();
        this.watchForFilterClear($(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR));
        this.watchForSupplierFilterClear($(ListingsFilterObserver.CLEAR_SUPPLIER_FILTER_SELECTOR));
        this.watchForRatingsFilterClear($(ListingsFilterObserver.CLEAR_RATING_FILTERS_SELECTOR));
        this.showOrHideClearAll();
        this.updateFiltersWithState(existingFilterState);
        this.showOrHideClearSupplierFilter();
        this.showOrHideClearRatings();
        this.populateNearbyDistanceModals();
        if (this.nextPageButton) {
            var nextPageButtonObservable = this.nextPageButton.getChangeObservable().pipe(tap(function () { return _this.showLoadingSignal(false); }));
            this.getAndHandleAjaxResponse(nextPageButtonObservable, false);
        }
        this.allFilters.forEach(function (filter) {
            filter.onMount && filter.onMount();
        });
        this.updateUrlWithGETParam(ListingsSort.URL_PARAM_NAME, sorter.getSelectedSort());
        observableFromEvent($(".language-filter-button"), "click")
            .subscribe(function () {
            _this.languageFilter = true;
        });
    }
    ListingsFilterObserver.scrollToTopOfFilters = function (scrollSpeed) {
        var $showTopFilters = $(".show-top-filters");
        var scrollToTarget = $showTopFilters.length ? $showTopFilters : $(".listing-filters");
        if (scrollToTarget.length) {
            ScrollFrom.scrollTo($("html, body"), scrollToTarget, -20, null, scrollSpeed);
        }
    };
    ListingsFilterObserver.updateProductCounts = function (productCount) {
        if (productCount) {
            $(".product-count")
                .get()
                .forEach(function (counter) { counter.innerHTML = productCount.innerHTML; });
        }
    };
    ListingsFilterObserver.prototype.clearAllFilters = function () {
        this.clearedFiltersFlag = true;
        this.allFilters.forEach(function (filter) {
            filter.clear();
        });
        $(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR).addClass("hidden");
        this.clearObs.next();
    };
    ListingsFilterObserver.prototype.getFiltersAppliedObservable = function () {
        return this.filtersAppliedObservable.pipe(distinctUntilChanged());
    };
    ListingsFilterObserver.prototype.getFiltersLoadingObservable = function () {
        return this.filtersLoadingObservable.pipe(distinctUntilChanged());
    };
    ListingsFilterObserver.prototype.getAggregateDateRangeFilter = function () {
        var dateRangeFilter;
        this.allFilters.forEach(function (filter) {
            if (filter instanceof AggregateDateRangeFilter) {
                dateRangeFilter = filter;
            }
        });
        return dateRangeFilter;
    };
    ListingsFilterObserver.prototype.addFilterChangeSubscriptions = function () {
        this.filterUpdateSubscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.filterUpdateSubscriptions = [];
        this.filterUpdateSubscriptions.push(this.updateFiltersOnFilterChange(this.filterChangeObservable));
        this.filterUpdateSubscriptions.push(this.getAndHandleAjaxResponse(this.filterChangeObservable, true));
    };
    ListingsFilterObserver.prototype.updateFiltersOnFilterChange = function (filterChangeObservable) {
        var _this = this;
        return filterChangeObservable.pipe(tap(function () {
            if (_this.nextPageButton) {
                _this.nextPageButton.setToInitialState();
            }
            _this.showLoadingSignal(true);
        }))
            .subscribe(function (filterState) {
            if (filterState) {
                _this.allFilters.forEach(function (f) {
                    f.updateFromState(filterState);
                });
                $(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR).removeClass("hidden");
            }
        });
    };
    ListingsFilterObserver.prototype.updateFiltersWithState = function (existingFilterState) {
        if (existingFilterState) {
            var parsedState_1;
            try {
                parsedState_1 = JSON.parse(existingFilterState);
            }
            catch (e) {
            }
            if (parsedState_1 !== undefined) {
                this.allFilters.forEach(function (f) {
                    f.updateFromState(parsedState_1);
                });
            }
        }
    };
    ListingsFilterObserver.prototype.showLoadingSignal = function (replaceContent) {
        this.filtersLoadingObservable.next(true);
        var productList = this.$productListSection.find(".product-list");
        var ajaxedShelves = this.$productListSection.find(".ajax-grouped-shelves");
        if (replaceContent) {
            productList.html("");
            ajaxedShelves.html("");
        }
        var productListLoadingElem = $(".product-loading-wrapper");
        productList.append(productListLoadingElem.clone()).html();
    };
    ListingsFilterObserver.prototype.getAndHandleAjaxResponse = function (observable, replaceContent) {
        var _this = this;
        return observable.pipe(tap(function () {
            var filtersState = _this.allFilters.map(function (f) { return f.getState(); });
            var tagFilter = filtersState.map(function (filter) { return filter.tagFilter; });
            if (!tagFilter && _this.superFilterTagId) {
                filtersState.push({
                    tagFilter: _this.superFilterTagId,
                });
            }
        }), mergeMap(function () { return _this.ajax.postJSONSafe(_this.getAjaxUrl(), _this.getAjaxBody(), "text"); }), map(function (data) { return data.response; }))
            .subscribe(function (resp) { return _this.handleChange(resp, replaceContent); });
    };
    ListingsFilterObserver.prototype.getValueFromUrl = function (param) {
        return new URLSearchParams(this.getGETParamsFromUrl()).get(param);
    };
    ListingsFilterObserver.prototype.updateUrlWithGETParam = function (key, value) {
        var updatedUrl = new URL(window.location.toString());
        updatedUrl.searchParams.set(key, value);
        if (value === ListingsSort.DEFAULT_SORT) {
            updatedUrl.searchParams.delete(key);
        }
        window.history.replaceState({
            url: updatedUrl.toString()
        }, window.document.title, updatedUrl.toString());
    };
    ListingsFilterObserver.prototype.getGETParamsFromUrl = function () {
        return window.location.search;
    };
    ListingsFilterObserver.prototype.getAjaxBody = function () {
        var filtersState = this.allFilters.map(function (f) { return f.getState(); });
        var destinationId = this.destinationId;
        var dynamicFilters = this.dynamicFilters;
        var groupId = this.groupId;
        var categoryId = this.categoryId;
        var tagCategoryId = this.tagCategoryId;
        var tagId = this.tagId;
        var attractionId = this.attractionId;
        var recommendationId = this.recommendationId;
        var adGroupId = this.adGroupId;
        var campaignId = this.campaignId;
        var tripDestinationType = this.tripDestinationType;
        var tripDestinationId = this.tripDestinationId;
        return $.extend.apply($, [{
                destinationId: destinationId,
                dynamicFilters: dynamicFilters,
                groupId: groupId,
                categoryId: categoryId,
                tagCategoryId: tagCategoryId,
                tagId: tagId,
                attractionId: attractionId,
                recommendationId: recommendationId,
                adGroupId: adGroupId,
                campaignId: campaignId,
                tripDestinationType: tripDestinationType,
                tripDestinationId: tripDestinationId
            }].concat(filtersState));
    };
    ListingsFilterObserver.prototype.getAjaxUrl = function () {
        var _a;
        var urlParams = (_a = {},
            _a[ListingsFilterObserver.DESTINATION_ARG] = this.destinationId,
            _a[ListingsFilterObserver.DYNAMIC_FILTERS_ARG] = this.dynamicFilters,
            _a[ListingsFilterObserver.PRODUCT_CODE_ARG] = this.productCode,
            _a[ListingsFilterObserver.GROUP_ARG] = this.groupId,
            _a[ListingsFilterObserver.CATEGORY_ARG] = this.categoryId,
            _a[ListingsFilterObserver.TAG_CATEGORY_ARG] = this.tagCategoryId,
            _a[ListingsFilterObserver.TAG_ARG] = this.tagId,
            _a[ListingsFilterObserver.ATTRACTION_ID_ARG] = this.attractionId,
            _a[ListingsFilterObserver.RECOMMENDATION_ID_ARG] = this.recommendationId,
            _a[ListingsFilterObserver.AD_GROUP_ID_ARG] = this.adGroupId,
            _a[ListingsFilterObserver.CAMPAIGN_ID_ARG] = this.campaignId,
            _a[ListingsFilterObserver.CALLING_SERVLET_ARG] = ClickStream.getServlet(),
            _a[ListingsSort.URL_PARAM_NAME] = this.sorter && this.sorter.getSelectedSort(),
            _a[NextPageButton.URL_PARAM_NAME] = this.nextPageButton && this.nextPageButton.getPageNum(),
            _a[ListingsFilterObserver.SEARCH_TERM_ARG] = this.searchTerm,
            _a[ListingsFilterObserver.SEM_RECOMMENDATION_FLAG_ARG] = this.semRecommendationFlag,
            _a[ListingsFilterObserver.CLEARED_FILTERS_FLAG_ARG] = this.clearedFiltersFlag,
            _a[ListingsFilterObserver.TRIP_DESTINATION_TYPE_ARG] = this.tripDestinationType,
            _a[ListingsFilterObserver.TRIP_DESTINATION_ID_ARG] = this.tripDestinationId,
            _a[ListingsFilterObserver.LATITUDE_ARG] = this.latitude,
            _a[ListingsFilterObserver.LONGITUDE_ARG] = this.longitude,
            _a);
        this.updateUrlWithGETParam(ListingsSort.URL_PARAM_NAME, urlParams[ListingsSort.URL_PARAM_NAME]);
        var sortGETParam = ListingsSort.URL_PARAM_NAME + "=" + urlParams[ListingsSort.URL_PARAM_NAME];
        var dynamicFiltersElements = $(".dynamic-filter .product-encoded-url");
        dynamicFiltersElements.each(function (index) {
            dynamicFiltersElements[index].setAttribute("additional-params", sortGETParam);
        });
        if ($("#popular-categories").prop("autoscroll")) {
            dynamicFiltersElements.each(function (index) {
                dynamicFiltersElements[index].setAttribute("fragment", "#popular-categories");
            });
        }
        Object.keys(urlParams).forEach(function (key) { return (!urlParams[key] && delete urlParams[key]); });
        return this.ajaxUrlPath + "?" + $.param(urlParams);
    };
    ListingsFilterObserver.prototype.handleChange = function (ajaxResponse, replaceContent) {
        var _this = this;
        var $ajaxResponse = $(ajaxResponse);
        var $sortElement = this.$productListSection.find(".listings-sorter").detach();
        $ajaxResponse.find(".listings-sorter").replaceWith($sortElement);
        this.clickStream.addElementsToClickStream($ajaxResponse.find("[data-action-capture]"));
        if (replaceContent) {
            this.$productListSection.empty();
        }
        this.$productListSection.find(".loading-cell").remove();
        this.$productListSection.append($ajaxResponse);
        var $tagFiltersSection = $(".tag-filter-section");
        if ($tagFiltersSection.length > 0) {
            $tagFiltersSection.html(this.$productListSection.find(".tag-filter-source").first().html());
        }
        if ($(".product-list-rows").length > 0) {
            new ListLayout("", $ajaxResponse, this.clickStream, null);
        }
        this.watchForFilterClear($ajaxResponse.find($(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR)));
        var updatedCount = $ajaxResponse.find("#productsList").attr("data-product-count");
        if (this.$tourCount && updatedCount) {
            this.$tourCount.html(updatedCount);
        }
        this.showOrHideClearAll();
        this.showOrHideClearSupplierFilter();
        this.showOrHideClearRatings();
        this.filtersAppliedObservable.next(this.areFiltersApplied());
        var productCount = $ajaxResponse.find("#productListCount").get(0);
        ListingsFilterObserver.updateProductCounts(productCount);
        var updatedUrl = this.$productListSection.find("#productsList").attr("data-base-url");
        if (updatedUrl) {
            updatedUrl = updatedUrl + this.getGETParamsFromUrl();
            window.history.replaceState({ url: updatedUrl }, window.document.title, updatedUrl);
            var $h1 = $('#listingPagesTitle');
            if ($h1.data("h1Page1") !== '') {
                $h1.text($h1.data("h1Page1"));
            }
        }
        if (this.nextPageButton) {
            if ($ajaxResponse.find(".product-list").data("has-more-products")) {
                this.nextPageButton.showPaginationButton();
            }
            else {
                this.nextPageButton.hidePaginationButton();
            }
        }
        this.updateAllFilterCounts($ajaxResponse);
        this.updateSupplierFilterOptions($ajaxResponse);
        this.$productListSection.find(".shelf-group").each(function (shelfGroupIndex, shelfGroupElem) {
            var $container = $(shelfGroupElem);
            var numOfSlides = $container.data("slides-to-show") || 4;
            var numToScroll = $container.data("slides-to-scroll") || numOfSlides;
            var lazyLoad = $container.data("lazyload") ? "lazyLoadImgs" : "none";
            new SlickSlider($container, {
                disableOnScreenSmallerThanOrEqualTo: ViewportBreakpoint.SM,
                infinite: false,
                lazyLoad: lazyLoad,
                slidesToScroll: numToScroll,
                slidesToShow: numOfSlides,
                swipeToSlide: false,
            }, {
                destinationId: $container.attr("data-destination-id"),
                servletName: "shelf",
                swipePageAction: "shelf_scroll",
                swipePageProperties: {
                    shelfId: $container.attr("data-tracking-id")
                }
            });
            $(shelfGroupElem).removeClass("hide-shelf-on-loading");
        });
        this.$productListSection.children(".ajax-grouped-shelves").each(function (index, elem) {
            _this.shelfService.ajaxGroupedShelves(elem);
        });
        this.filtersLoadingObservable.next(false);
        new TooltipLoader();
        new ExcellentBadge();
        var $dynamicFiltersSection = $(".dynamic-filters-display");
        if ($dynamicFiltersSection.length > 0) {
            var $dynamicFiltersSource = this.$productListSection.find(".dynamic-filters-source").first();
            if ($dynamicFiltersSource.length > 0) {
                $dynamicFiltersSection.first().html($dynamicFiltersSource.html());
                $dynamicFiltersSource.remove();
                DynamicFilters.setUpDynamicFilters();
                var dynamicFilterDatePicker = $("#dynamicFilterDatePicker");
                if (dynamicFilterDatePicker.length > 0 || $(".trigger-date-modal").length > 0) {
                    var oldAggregateDateRangeFilter_1 = this.getAggregateDateRangeFilter();
                    this.allFilters.filter(function (filter) { return filter !== oldAggregateDateRangeFilter_1; });
                    var aggregateDateRangeFilter = Filters.createAggregateDateRangeFilter($(document).find(".listing-filters"));
                    this.allFilters.push(aggregateDateRangeFilter);
                    this.filterChangeObservable =
                        observableMerge(this.filterChangeObservable, aggregateDateRangeFilter.getChangeObservable());
                    this.addFilterChangeSubscriptions();
                    this.updateFiltersWithState($ajaxResponse.filter("#productsList").attr("data-applied-filters"));
                }
                if (dynamicFilterDatePicker.length > 0) {
                    observableFromEvent(dynamicFilterDatePicker, "click")
                        .subscribe(function (event) {
                        event.stopPropagation();
                        $("#dynamicFilterDateRangePicker").find(".datepicker").trigger("click");
                    });
                }
                DateRangeFilter.addDateModalObservableEvent();
            }
        }
        this.populateNearbyDistanceModals();
        if (this.languageFilter) {
            window.location.reload();
        }
    };
    ListingsFilterObserver.prototype.watchForFilterClear = function (elements) {
        var _this = this;
        observableFromEvent(elements, "click")
            .subscribe(function () {
            _this.clearAllFilters();
            ListingsFilterObserver.scrollToTopOfFilters();
        });
    };
    ListingsFilterObserver.prototype.watchForRatingsFilterClear = function (element) {
        var _this = this;
        observableFromEvent(element, "click")
            .subscribe(function (event) {
            event.preventDefault();
            _this.allFilters.forEach(function (filter) {
                filter.updateFromState({ minimumRating: "" });
            });
            _this.clearObs.next();
        });
    };
    ListingsFilterObserver.prototype.watchForSupplierFilterClear = function (element) {
        var _this = this;
        observableFromEvent(element, "click")
            .subscribe(function (event) {
            event.preventDefault();
            _this.allFilters.forEach(function (filter) {
                var _a;
                filter.updateFromState((_a = {}, _a[Filters.SUPPLIER_FILTER_PARAM_NAME] = "", _a));
            });
            _this.clearObs.next();
        });
    };
    ListingsFilterObserver.prototype.showOrHideClearAll = function () {
        var $clearAllFiltersButton = $(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR).filter("#topbarClearAll");
        var $mobileClearAllFiltersButton = $(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR).filter("#mobileClearAll");
        $clearAllFiltersButton.toggleClass("hidden", this.isCompressionMsgShown() || !this.areFiltersApplied());
        if (this.isCompressionMsgShown() || !this.areFiltersApplied()) {
            $mobileClearAllFiltersButton.addClass("d-none").removeClass("d-inline-block");
        }
        else {
            $mobileClearAllFiltersButton.addClass("d-lg-none d-inline-block").removeClass("d-none");
        }
    };
    ListingsFilterObserver.prototype.showOrHideClearSupplierFilter = function () {
        var supplierFilter = Filters.findSupplierFilter(this.allFilters);
        if (supplierFilter) {
            var state = supplierFilter.getState();
            var show = !!state[Filters.SUPPLIER_FILTER_PARAM_NAME];
            var $clearSupplierFilterLinks = $(ListingsFilterObserver.CLEAR_SUPPLIER_FILTER_SELECTOR);
            $clearSupplierFilterLinks.toggle(show);
        }
    };
    ListingsFilterObserver.prototype.showOrHideClearRatings = function () {
        var $clearRatingsFiltersLinks = $(ListingsFilterObserver.CLEAR_RATING_FILTERS_SELECTOR);
        var ratingFilter = this.allFilters.find(function (filter) { return filter.paramName === "minimumRating"; });
        if (ratingFilter) {
            var state = ratingFilter.getState();
            var show = !isNaN(parseInt(state.minimumRating, 10));
            $clearRatingsFiltersLinks.toggle(show);
        }
    };
    ListingsFilterObserver.prototype.updateAllFilterCounts = function ($countsParent) {
        var elems = $("<div/>").append($countsParent.clone()).find("#productsList");
        var filterCounts = elems.data("filter-counts");
        this.allFilters.forEach(function (filter) {
            filter.updateCounts && filter.updateCounts(filterCounts);
        });
    };
    ListingsFilterObserver.prototype.updateSupplierFilterOptions = function ($ajaxResponse) {
        var $supplierFilterOptionsContainer = $("#supplierFilterOptionsContainer");
        var supplierFilterOptionsSourceId = "#supplierFilterOptionsContainerSource";
        if ($supplierFilterOptionsContainer.length > 0) {
            $supplierFilterOptionsContainer.html(this.$productListSection.find(supplierFilterOptionsSourceId).first().html());
            $supplierFilterOptionsContainer.scrollTop(0);
            $(supplierFilterOptionsSourceId).remove();
            SupplierFilterSearch.filterSuppliers();
            Filters.removeSupplierFilter(this.allFilters);
            var newSupplierFilter = Filters.createSupplierFilter($(document).find(".left-rail"));
            this.allFilters.push(newSupplierFilter);
            this.filterChangeObservable = observableMerge(this.filterChangeObservable, newSupplierFilter.getChangeObservable());
            this.addFilterChangeSubscriptions();
            this.updateFiltersWithState($ajaxResponse.filter("#productsList").attr("data-applied-filters"));
            this.showOrHideClearSupplierFilter();
            this.showOrHideClearAll();
        }
    };
    ListingsFilterObserver.prototype.areFiltersApplied = function () {
        return this.$productListSection.find("#productsList").data("appliedFilters") !== undefined;
    };
    ListingsFilterObserver.prototype.isCompressionMsgShown = function () {
        var compressionMsgClearFiltersLink = this.$productListSection.find(ListingsFilterObserver.CLEAR_FILTERS_SELECTOR);
        return compressionMsgClearFiltersLink.length > 0;
    };
    ListingsFilterObserver.prototype.populateNearbyDistanceModals = function () {
        observableFromEvent($(".nearby-distance"), "click")
            .subscribe(function (event) {
            var modalId = "#nearbyDistanceModal-" + $(event.currentTarget).data("product-code");
            $(modalId).modal("show");
            event.stopPropagation();
        });
    };
    ListingsFilterObserver.DYNAMIC_FILTERS_ARG = "dynamicFilters";
    ListingsFilterObserver.CLEAR_FILTERS_SELECTOR = ".clear-all-filters";
    ListingsFilterObserver.CLEAR_SUPPLIER_FILTER_SELECTOR = ".js-clear-supplier-filter";
    ListingsFilterObserver.CLEAR_RATING_FILTERS_SELECTOR = ".js-clear-rating-filter";
    ListingsFilterObserver.DESTINATION_ARG = "destination";
    ListingsFilterObserver.PRODUCT_CODE_ARG = "productCode";
    ListingsFilterObserver.GROUP_ARG = "group";
    ListingsFilterObserver.CATEGORY_ARG = "category";
    ListingsFilterObserver.TAG_CATEGORY_ARG = "tagCategoryId";
    ListingsFilterObserver.TAG_ARG = "tagId";
    ListingsFilterObserver.ATTRACTION_ID_ARG = "attractionId";
    ListingsFilterObserver.RECOMMENDATION_ID_ARG = "recommendationId";
    ListingsFilterObserver.AD_GROUP_ID_ARG = "adGroupId";
    ListingsFilterObserver.CAMPAIGN_ID_ARG = "campaignId";
    ListingsFilterObserver.CALLING_SERVLET_ARG = "callingServlet";
    ListingsFilterObserver.SEARCH_TERM_ARG = "searchTerm";
    ListingsFilterObserver.SEM_RECOMMENDATION_FLAG_ARG = "semLander";
    ListingsFilterObserver.CLEARED_FILTERS_FLAG_ARG = "clearedFilters";
    ListingsFilterObserver.TRIP_DESTINATION_ID_ARG = "tripDestinationId";
    ListingsFilterObserver.TRIP_DESTINATION_TYPE_ARG = "tripDestinationType";
    ListingsFilterObserver.LATITUDE_ARG = "lat";
    ListingsFilterObserver.LONGITUDE_ARG = "lon";
    return ListingsFilterObserver;
}());
export { ListingsFilterObserver };
