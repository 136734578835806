import { fromEvent as observableFromEvent } from "rxjs";
var CopyToClipboard = (function () {
    function CopyToClipboard(document, $copyTextTrigger, $copyTextSelector) {
        var _this = this;
        this.$copyText = $copyTextSelector;
        this.$copyTextTrigger = $copyTextTrigger;
        this.document = document;
        observableFromEvent(this.$copyTextTrigger, "click")
            .subscribe(function () {
            CopyToClipboard.copy(_this.$copyText.html(), _this.document);
        });
    }
    CopyToClipboard.copy = function (copyText, document, inputClass) {
        var aux = document.createElement("input");
        if (inputClass) {
            aux.classList.add(inputClass);
        }
        aux.setAttribute("value", copyText);
        document.body.appendChild(aux);
        if (this.isIos()) {
            this.selectIos(aux, document);
        }
        else {
            aux.select();
        }
        document.execCommand("copy");
        document.body.removeChild(aux);
    };
    CopyToClipboard.copyInputText = function (source, $document) {
        if (this.isIosOrSafari()) {
            var prevState = source.contentEditable;
            this.selectIos(source, $document);
            source.contentEditable = prevState;
        }
        else {
            source.select();
        }
        return $document.execCommand("copy");
    };
    CopyToClipboard.isIos = function () {
        var iosPlatforms = ["iPhone", "iPad", "iPod"];
        return iosPlatforms.indexOf(window.navigator.platform) !== -1;
    };
    CopyToClipboard.isIosOrSafari = function () {
        var contentWindow = window;
        var isSafari = contentWindow.safari !== undefined &&
            (contentWindow.safari.pushNotification).toString() === "[object SafariRemoteNotification]";
        var iosPlatforms = ["iPhone", "iPad", "iPod"];
        return iosPlatforms.indexOf(window.navigator.platform) !== -1 ||
            (isSafari && /Apple Computer/.test(window.navigator.vendor));
    };
    CopyToClipboard.selectIos = function (aux, $document) {
        var range = $document.createRange();
        aux.contentEditable = "true";
        aux.readOnly = true;
        range.selectNodeContents(aux);
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        aux.setSelectionRange(0, aux.value.length);
    };
    return CopyToClipboard;
}());
export { CopyToClipboard };
