import { fromEvent as observableFromEvent, merge as observableMerge } from "rxjs";
import { throttleTime, map, filter } from "rxjs/operators";
var DEFAULT_SETTINGS = {
    closeOnClick: true
};
var ClickDropdown = (function () {
    function ClickDropdown(button, content, settings) {
        this.$button = button;
        this.$content = content;
        this.settings = settings || DEFAULT_SETTINGS;
        this.initialize();
    }
    ClickDropdown.prototype.getMouseClickObservable = function () {
        return this.mouseClickObservable;
    };
    ClickDropdown.prototype.initialize = function () {
        var _this = this;
        this.mouseClickObservable = observableMerge(observableFromEvent(this.$button, "click"));
        this.mouseClickObservable.pipe(throttleTime(100))
            .subscribe(function () {
            if (!_this.$button.hasClass("clicked")) {
                _this.$button.addClass("clicked");
                _this.$content.show();
            }
            else {
                _this.$button.removeClass("clicked");
                _this.$content.hide();
            }
        });
        observableMerge(observableFromEvent(document, "click")).pipe(map(function (e) { return e.target; }), filter(function (target) { return !$(target).parent().is(_this.$button) &&
            !$(target).is(_this.$button) &&
            !_this.$content.find(target).length; })).subscribe(function () {
            _this.$button.removeClass("clicked");
            _this.$content.hide();
        });
        if (this.settings.closeOnClick) {
            observableFromEvent(this.$content, "click").subscribe(function () {
                _this.$button.removeClass("clicked");
                _this.$content.hide();
            });
        }
    };
    return ClickDropdown;
}());
export { ClickDropdown };
