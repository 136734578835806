var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SlickSlider } from "./carousel/slickSlider";
import { ClickStream } from "./clickstream";
import $ from "./common/jquery";
import { ViewportBreakpoint } from "./common/utils/viewportBreakpoints";
import { Decode } from "./decode";
import { fromEvent as observableFromEvent } from "rxjs";
var DynamicFilters = (function () {
    function DynamicFilters() {
    }
    DynamicFilters.setUpDynamicFilters = function (clickstreamOptions, pageProperties) {
        if (clickstreamOptions === void 0) { clickstreamOptions = {}; }
        if (pageProperties === void 0) { pageProperties = { ShelfTitle: "DYN_FILTERS" }; }
        var $dynamicFilterContainer = $(".dynamic-filter-container");
        Decode.addDecoderHandler($dynamicFilterContainer);
        var $activeSlide = $dynamicFilterContainer.find(".selected-link");
        var activeSlideNum = $activeSlide.index();
        var slider = new SlickSlider($dynamicFilterContainer, {
            disableOnScreenSmallerThanOrEqualTo: ViewportBreakpoint.BOOTSTRAP_LG,
            infinite: false,
            onInit: function (mySlider) {
                if (activeSlideNum > 0) {
                    setTimeout(function () { return mySlider.$slider.slick("slickGoTo", activeSlideNum, true); }, 10);
                }
            },
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToScroll: 4,
                    },
                },
            ],
            swipeToSlide: false,
            variableWidth: true,
        }, __assign({ servletName: ClickStream.getServlet(), swipePageAction: "scroll_dyn_filters" }, clickstreamOptions));
        observableFromEvent($("#dynamicFilterMoreButton"), "click").subscribe(function (e) {
            slider.$slider.scrollLeft(0);
            $("#stickyFilterButtonContent").trigger("click");
        });
    };
    return DynamicFilters;
}());
export { DynamicFilters };
