import { fromEvent as observableFromEvent } from "rxjs";
import { filter } from "rxjs/operators";
var Modal = (function () {
    function Modal(window, body, modals) {
        var _this = this;
        this.$window = window;
        this.$body = body;
        this.$modals = modals;
        if (this.$modals.attr(Modal.BACKGROUND_ATTR)) {
            observableFromEvent(this.$window, "click").pipe(filter(function (event) { return _this.$modals.hasClass("show")
                && $(event.target).hasClass("modal-backdrop"); }))
                .subscribe(function () {
                _this.$modals.modal("hide");
            });
        }
        if (this.$modals.attr(Modal.NO_SCROLL_ATTR)) {
            observableFromEvent(this.$modals, "show.bs.modal")
                .subscribe(function () {
                _this.$body.addClass(Modal.BODY_NO_SCROLL_CLASS);
            });
            observableFromEvent(this.$modals, "hidden.bs.modal")
                .subscribe(function () {
                _this.$body.removeClass(Modal.BODY_NO_SCROLL_CLASS);
            });
        }
    }
    Modal.BODY_NO_SCROLL_CLASS = "body-no-scroll";
    Modal.BACKGROUND_ATTR = "data-background-click";
    Modal.NO_SCROLL_ATTR = "data-no-scroll";
    return Modal;
}());
export { Modal };
