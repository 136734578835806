import "../../scss/dateRangeFilter.scss";
import { EMPTY as observableEmpty, fromEvent as observableFromEvent, Subject } from "rxjs";
import { DateRangePicker } from "../common/dateRangePicker";
import { DatepickerUtils } from "../datepickerUtils";
import $ from "../../ts/common/jquery";
import { ScreenSize } from "../common/utils/ScreenSize";
var DateRangeFilter = (function () {
    function DateRangeFilter(parentElement, container, clearDateButton) {
        var _this = this;
        this.$body = $("body");
        this.changeObs = new Subject();
        var isDynamicFilterDatePicker = $("#dynamicFilterDateRangePicker").length > 0;
        this.picker = new DateRangePicker(parentElement, container, clearDateButton, {
            allowApplyWithoutDates: false,
            screenSizeToShowModal: isDynamicFilterDatePicker ? ScreenSize.MD : ScreenSize.XS,
            isDynamicFilterDatePicker: isDynamicFilterDatePicker
        });
        this.picker.getOpenObservable().subscribe(this.handleOnOpen.bind(this));
        this.picker.getApplyObservable().subscribe(function () { return _this.handleOnApply(); });
        this.picker.getCloseObservable().subscribe(this.handleOnClose.bind(this));
    }
    DateRangeFilter.addDateModalObservableEvent = function () {
        var triggerDateModalObjects = $(".trigger-date-modal");
        if (triggerDateModalObjects.length === 0) {
            return;
        }
        triggerDateModalObjects
            .get()
            .forEach(function (btn) {
            observableFromEvent(btn, "click")
                .subscribe(function (event) {
                event.stopPropagation();
                $("#dynamicFilterDateRangePicker").find(".datepicker").trigger("click");
            });
        });
    };
    DateRangeFilter.prototype.clear = function () {
        this.picker.clear();
    };
    DateRangeFilter.prototype.getAppliedOptionsCount = function () {
        return 0;
    };
    DateRangeFilter.prototype.getChangeObservable = function () {
        if (this.picker.isInitialized()) {
            return this.changeObs;
        }
        return observableEmpty;
    };
    DateRangeFilter.prototype.getState = function () {
        var dateRange = this.picker.getSelectedDate();
        return {
            endDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.endDate)) || "",
            startDate: (dateRange && DatepickerUtils.formatDateToYMDString(dateRange.startDate)) || ""
        };
    };
    DateRangeFilter.prototype.revertState = function () {
    };
    DateRangeFilter.prototype.updateFromState = function (state) {
        if (state.startDate === "" && state.endDate === "") {
            this.clear();
        }
        else if (state.startDate && state.endDate) {
            var startDate = DatepickerUtils.parseYMDstring(state.startDate);
            var endDate = DatepickerUtils.parseYMDstring(state.endDate);
            if (!DatepickerUtils.dateGreaterThanOrEqualToToday(startDate)) {
                this.picker.clear();
                this.changeObs.next(this.getState());
                return;
            }
            this.updateDatePicker(startDate, endDate);
        }
    };
    DateRangeFilter.prototype.updateDatePicker = function (startDate, endDate) {
        this.picker.update({ startDate: startDate, endDate: endDate });
    };
    DateRangeFilter.prototype.handleOnApply = function () {
        this.changeObs.next(this.getState());
    };
    DateRangeFilter.prototype.handleOnOpen = function () {
        DatepickerUtils.closeOtherFilterDropdowns();
        this.$body.addClass("no-scroll");
    };
    DateRangeFilter.prototype.handleOnClose = function () {
        this.$body.removeClass("no-scroll");
    };
    return DateRangeFilter;
}());
export { DateRangeFilter };
