import { fromEvent, noop } from "rxjs";
import { AjaxService } from "../common/ajax";
import "../../scss/_travelDirective.scss";
var TravelDirective = (function () {
    function TravelDirective($container) {
        var _this = this;
        this.$container = $container;
        this.$moreInfoLink = this.$container.find(".travel-directive-more-info-link");
        this.ajaxService = new AjaxService();
        fromEvent(this.$container.find(".travel-directive-close"), "click")
            .subscribe(function () { return _this.closeTravelDirective(); });
        fromEvent(this.$moreInfoLink, "click").subscribe(function () { return _this.toggleShowMoreInfo(); });
    }
    TravelDirective.prototype.closeTravelDirective = function () {
        this.$container.addClass("invisible");
        this.ajaxService
            .doPostSafe(TravelDirective.TRAVEL_DIRECTIVE_SEEN_URL)
            .subscribe(noop, function (error) { return console.error("Travel Directive: " + error); });
    };
    TravelDirective.prototype.toggleShowMoreInfo = function () {
        var expandedAttrName = "data-expanded";
        var expanded = this.$moreInfoLink.attr(expandedAttrName);
        var $moreInfoTextContainer = this.$container.find("div span");
        if (expanded === "true") {
            this.$moreInfoLink.attr(expandedAttrName, "false");
            $moreInfoTextContainer.hide();
        }
        else {
            this.$moreInfoLink.attr(expandedAttrName, "true");
            $moreInfoTextContainer.show();
        }
    };
    TravelDirective.TRAVEL_DIRECTIVE_SEEN_URL = "/orion/ajax/cookieDisclaimer/seenTravelDirective";
    return TravelDirective;
}());
export { TravelDirective };
