import { fromEvent as observableFromEvent, Subject } from "rxjs";
var BannerCancellationFilter = (function () {
    function BannerCancellationFilter(container) {
        var _this = this;
        this.currentState = { cancellationFilter: "" };
        this.$container = $(container);
        this.$buttonClickSubject = new Subject();
        observableFromEvent(this.$container, "click").subscribe(function (event) {
            if ($(event.target).hasClass(BannerCancellationFilter.FILTER_CLASS)) {
                var $modalContainer = _this.$container.find(BannerCancellationFilter.MODAL_SELECTOR);
                if ($modalContainer.length) {
                    $modalContainer.modal("hide");
                }
                _this.currentState = { cancellationFilter: BannerCancellationFilter.DEFAULT_CANCELLATION_STATE_VAL + "," };
                _this.$buttonClickSubject.next(_this.getState());
            }
        });
    }
    BannerCancellationFilter.prototype.getState = function () {
        if (this.$container.find("." + BannerCancellationFilter.FILTER_CLASS).length === 0) {
            return {};
        }
        return this.currentState;
    };
    BannerCancellationFilter.prototype.getAppliedOptionsCount = function () {
        return this.getState().cancellationFilter === "" ? 0 : 1;
    };
    BannerCancellationFilter.prototype.getChangeObservable = function () {
        return this.$buttonClickSubject;
    };
    BannerCancellationFilter.prototype.updateFromState = function (state) {
        if (state != null && state.cancellationFilter) {
            this.currentState.cancellationFilter = state.cancellationFilter;
        }
    };
    BannerCancellationFilter.prototype.revertState = function () {
        this.updateFromState(this.currentState);
    };
    BannerCancellationFilter.prototype.clear = function () {
        this.currentState = { cancellationFilter: "" };
    };
    BannerCancellationFilter.FILTER_CLASS = "banner-cancellation-filter";
    BannerCancellationFilter.MODAL_SELECTOR = "#cancellationmodal";
    BannerCancellationFilter.DEFAULT_CANCELLATION_STATE_VAL = "default";
    return BannerCancellationFilter;
}());
export { BannerCancellationFilter };
