var OT_CONSENT_DENIED_COUNTRIES = [
    'AT',
    'AX',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GF',
    'GP',
    'GR',
    'HR',
    'HU',
    'IE',
    'IS',
    'IT',
    'LI',
    'LT',
    'LU',
    'LV',
    'MC',
    'MF',
    'MQ',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RE',
    'RO',
    'SE',
    'SI',
    'SK',
    'SM',
    'YT',
];
var OT_DEFAULT_CONSENT_DENIED_BY_REGION = {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    region: OT_CONSENT_DENIED_COUNTRIES,
};
var OT_DEFAULT_CONSENT_GRANTED = {
    ad_storage: 'granted',
    analytics_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
};
var addDefaultConsent = function (gtag) {
    gtag('consent', 'default', OT_DEFAULT_CONSENT_DENIED_BY_REGION);
    gtag('consent', 'default', OT_DEFAULT_CONSENT_GRANTED);
};
export { addDefaultConsent, OT_DEFAULT_CONSENT_GRANTED, OT_DEFAULT_CONSENT_DENIED_BY_REGION, };
