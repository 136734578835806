import { fromEvent as observableFromEvent } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import $ from "../common/jquery";
var Popover = (function () {
    function Popover($parentElement, containerName, stayOpenOnHover) {
        if (stayOpenOnHover === void 0) { stayOpenOnHover = false; }
        var $popoverElements = $parentElement.find('[data-toggle="popover"]');
        if ($popoverElements.length) {
            $popoverElements.each(function (index, element) {
                var $popoverElement = $(element);
                var $containerElement = (stayOpenOnHover) ? $popoverElement : (containerName || "body");
                $popoverElement.popover({
                    container: $containerElement,
                    html: true
                });
            });
            if ($popoverElements.attr("data-trigger") !== "focus" &&
                $popoverElements.attr("data-trigger") !== "hover") {
                this.watchPopoverClose();
                this.watchCloseClick($popoverElements);
            }
        }
    }
    Popover.prototype.watchPopoverClose = function () {
        observableFromEvent($("body"), "click")
            .subscribe(function (e) {
            if ($(e.target).data("toggle") !== "popover" && !$(e.target).parents(".popover").length) {
                $('[data-toggle="popover"]').popover("hide");
            }
        });
    };
    Popover.prototype.watchCloseClick = function ($popoverElement) {
        observableFromEvent($popoverElement, "shown.bs.popover")
            .pipe(map(function (event) { return $("#" + $(event.target).attr("aria-describedby")); }), switchMap(function ($popover) {
            var $popoverClose = $popover.find(".close");
            return observableFromEvent($popoverClose, "click").pipe(first(), map(function () { return $popover; }));
        })).subscribe(function ($popover) {
            $popover.popover("hide");
        });
    };
    return Popover;
}());
export { Popover };
