import { fromEvent as observableFromEvent } from "rxjs";
import "bootstrap";
import "parsleyjs";
import { AjaxService } from "../common/ajax";
import $ from "../common/jquery";
import { doLogout } from "../account/authUtil";
import { exhaustMap } from "rxjs/operators";
var Logout = (function () {
    function Logout($container) {
        this.ajax = AjaxService.getInstance();
        this.$container = $container;
    }
    Logout.prototype.bootstrap = function () {
        this.loginForm = $(Logout.LOGIN_FORM_JQID);
        this.buildStream$(Logout.LOGOUT_DROP_DOWN).subscribe(function () { return window.location.reload(); });
    };
    Logout.prototype.buildStream$ = function (linkId) {
        var _this = this;
        return observableFromEvent($(linkId), "click").pipe(exhaustMap(function () { return doLogout(_this.ajax); }));
    };
    Logout.LOGIN_FORM_JQID = "#loginForm";
    Logout.LOGOUT_DROP_DOWN = ".logout";
    return Logout;
}());
export { Logout };
