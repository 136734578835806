import { fromEvent as observableFromEvent } from "rxjs";
import { HelpCenterLoader } from "./helpCenterLoader";
import "../scss/chatLink.scss";
var HelpLink = (function () {
    function HelpLink($helpLinkBody) {
        this.$helpLinkBody = $helpLinkBody;
    }
    HelpLink.prototype.bootstrap = function () {
        observableFromEvent(this.$helpLinkBody, "click").subscribe(function (event) {
            HelpCenterLoader.load(window, event)
                .then(function () { return HelpCenterLoader.activate(window, HelpCenterLoader.HC_HELP_SHELL); });
        });
    };
    return HelpLink;
}());
export { HelpLink };
