var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import $ from "./common/jquery";
import { CookieCategory } from "./onetrust/cookieCategory";
import { OneTrustApi } from "./onetrust/OneTrustApi";
import { addDefaultConsent } from "./onetrust/AddDefaultConsent";
var GoogleGlobalSiteTag = (function () {
    function GoogleGlobalSiteTag($container, consentClient) {
        var _this = this;
        this.appId = 'AW-1067478494';
        this.floodLightTagId = 'DC-12197993';
        this.viatorCm360AppId = 'DC-13692933';
        this.hasConsent = new Promise(function (resolve, reject) {
            _this.deferredConsent = { resolve: resolve, reject: reject };
        });
        this.giveConsent = function (consentedCategories) {
            if (consentedCategories &&
                (consentedCategories.includes(CookieCategory.ADVERTISING) ||
                    consentedCategories.includes(CookieCategory.ALL))) {
                _this.deferredConsent.resolve('Consent Given');
            }
            _this.deferredConsent.reject('No Consent Given');
        };
        this.nonce = $container.data('nonce');
        this.email = $container.data('member-email');
        this.visitorRef = $container.data('visitor-ref');
        this.sessionId = $container.data('session-id');
        this.hasOneTrustConsentModel = $container.data('has-onetrust-consent-model');
        this.params = {
            u1: $container.data('visitor-id'),
            u2: $container.data('member-ref'),
        };
        this.viatorCm360Params = __assign({ u1: $container.data('visitor-id'), u2: $container.data('visitor-ref'), u3: $container.data('url'), u5: $container.data('session-id'), u6: $container.data('member-id'), u7: $container.data('hashed-email'), u8: $container.data('page-type') }, ($container.data('destination-name') && { u9: $container.data('destination-name') }), ($container.data('destination-id') && { u10: $container.data('destination-id') }), ($container.data('region') && { u11: $container.data('region') }), ($container.data('country') && { u12: $container.data('country') }), ($container.data('product-codes') && { u13: $container.data('product-codes').split(',') }), ($container.data('product-names') && { u14: $container.data('product-names').split(',') }), ($container.data('category-name') && { u15: $container.data('category-name') }), ($container.data('group-name') && { u16: $container.data('group-name') }), ($container.data('checkin-dates') && { u17: $container.data('checkin-dates').split(',') }), ($container.data('currency-code') && { u18: $container.data('currency-code') }), ($container.data('product-prices') && { u19: $container.data('product-prices').split(',') }), ($container.data('attraction-id') && { u20: $container.data('attraction-id') }), ($container.data('attraction-name') && { u21: $container.data('attraction-name') }), ($container.data('search-term') && { u22: $container.data('search-term') }), ($container.data('tag-id') && { u23: $container.data('tag-id') }), ($container.data('tag-name') && { u24: $container.data('tag-name') }));
        var servletName = $container.data('servlet-name');
        this.pageConfig = GoogleGlobalSiteTag.GoogleGlobalSiteTagPageTypes[servletName]
            || GoogleGlobalSiteTag.GoogleGlobalSiteTagPageTypes.genericVisitFallback;
        this.listenForConsentUpdates(consentClient);
    }
    GoogleGlobalSiteTag.prototype.listenForConsentUpdates = function (consentClient) {
        var _this = this;
        if (consentClient === null) {
            this.giveConsent([CookieCategory.ALL]);
            return;
        }
        consentClient.getNoticeApi().then(function (api) {
            if (!api.isGDPR() && !(consentClient instanceof OneTrustApi)) {
                _this.giveConsent([CookieCategory.ALL]);
            }
        });
        consentClient.getPriorConsentSubject().subscribe(function (categories) {
            var consentedCategories = Object.keys(categories).filter(function (category) { return categories[category]; });
            _this.giveConsent(consentedCategories);
        });
    };
    GoogleGlobalSiteTag.prototype.trackPage = function () {
        var _this = this;
        this.hasConsent.then(function () {
            return new Promise(function (resolve) {
                var inlineScript = document.createElement('script');
                inlineScript.innerText =
                    'window.dataLayer = window.dataLayer || [];function gtag(){window.dataLayer.push(arguments);}';
                inlineScript.nonce = _this.nonce;
                document.body.appendChild(inlineScript);
                if (_this.hasOneTrustConsentModel) {
                    addDefaultConsent(gtag);
                }
                var script = document.createElement('script');
                script.src = "https://www.googletagmanager.com/gtag/js?id=" + _this.appId;
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
                script.addEventListener('load', function () {
                    resolve('Remote script loaded');
                });
            });
        }).then(function () {
            gtag('js', new Date());
            gtag('config', _this.appId, { allow_enhanced_conversions: true });
            gtag('config', _this.floodLightTagId);
            if (_this.email) {
                gtag('set', 'user_data', { email: _this.email });
            }
            if (_this.pageConfig.globalSiteTagPageId) {
                gtag('event', 'conversion', {
                    send_to: _this.appId + "/" + _this.pageConfig.globalSiteTagPageId,
                    user_id: [_this.visitorRef],
                });
            }
            if (_this.pageConfig.floodLightTagId) {
                gtag('event', 'conversion', __assign({ allow_custom_scripts: true, send_to: _this.floodLightTagId + "/via_om/" + _this.pageConfig.floodLightTagId, user_id: [_this.visitorRef] }, _this.params));
            }
            if (_this.pageConfig.viatorCm360StandardTagId) {
                gtag('event', 'conversion', __assign({ session_id: _this.sessionId, send_to: _this.viatorCm360AppId + "/" + _this.pageConfig.viatorCm360StandardTagId, user_id: [_this.visitorRef], dc_custom_params: {
                        match_id: [_this.visitorRef],
                    } }, _this.viatorCm360Params));
            }
            if (_this.pageConfig.viatorCm360VIROS) {
                gtag('event', 'conversion', __assign({ send_to: _this.viatorCm360AppId + "/vi_eng/vi_en0+standard", user_id: [_this.visitorRef], dc_custom_params: {
                        match_id: [_this.visitorRef],
                    } }, _this.viatorCm360Params));
            }
            if (_this.pageConfig.viatorCm360VIROSUnique) {
                gtag('event', 'conversion', __assign({ allow_custom_scripts: true, send_to: _this.viatorCm360AppId + "/vi_eng/vi_ro0+unique", user_id: [_this.visitorRef], dc_custom_params: {
                        match_id: [_this.visitorRef],
                    } }, _this.viatorCm360Params));
            }
        });
    };
    GoogleGlobalSiteTag.genericVisitPageConfig = {
        globalSiteTagPageId: 'YdwsCN_usdADEN7bgf0D',
        floodLightTagId: 'viato0+unique',
    };
    GoogleGlobalSiteTag.GoogleGlobalSiteTagPageTypes = {
        "searchResults": {
            globalSiteTagPageId: '3EDqCMmZtroDEN7bgf0D',
            floodLightTagId: 'viato000+unique',
        },
        "checkout_flow": {
            globalSiteTagPageId: '4k_8CIuP6boDEN7bgf0D',
            floodLightTagId: 'viato003+unique',
        },
        attraction_detail_overview: __assign({ viatorCm360VIROS: true, viatorCm360VIROSUnique: true }, GoogleGlobalSiteTag.genericVisitPageConfig),
        geo_hub: __assign({ viatorCm360StandardTagId: 'vi_eng/vi_la0+standard', viatorCm360VIROS: true, viatorCm360VIROSUnique: true }, GoogleGlobalSiteTag.genericVisitPageConfig),
        genericVisitFallback: __assign({}, GoogleGlobalSiteTag.genericVisitPageConfig)
    };
    GoogleGlobalSiteTag.globalSiteTagContainerId = '#googleGlobalSiteTagData';
    GoogleGlobalSiteTag.initGoogleSiteTag = function (consentClient) {
        var $container = $(GoogleGlobalSiteTag.globalSiteTagContainerId);
        if ($container.length) {
            new GoogleGlobalSiteTag($container, consentClient).trackPage();
        }
    };
    return GoogleGlobalSiteTag;
}());
export { GoogleGlobalSiteTag };
