import $ from "../ts/common/jquery";
import { ClickStream } from "../ts/clickstream";
export function setUpGetToKnowYouModal() {
    var getToKnowYouModal = $("#getToKnowYouModal");
    if (getToKnowYouModal.length > 0) {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "get_to_know_you_modal_view",
            pageProperties: {
                elementId: getToKnowYouModal.data("modal-element-id")
            },
            servletName: ClickStream.getServlet()
        });
        getToKnowYouModal.modal("show");
    }
}
