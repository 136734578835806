import { fromEvent as observableFromEvent } from "rxjs";
var ListingsSort = (function () {
    function ListingsSort($parentElement) {
        var _this = this;
        var $displayedSelectedSort = $parentElement.find(".displayed-sort");
        this.selectedSort = this.getSelectedOptionElement($parentElement).data("value");
        this.changeObservable$ = observableFromEvent($parentElement.find(ListingsSort.OPTION_SELECTOR), "click");
        var $sortModalButton = $(".sort-modal-button-box");
        this.$sortModalElem = $(ListingsSort.SORT_MODAL_SELECTOR);
        var $sortOptionMenuElem = $(".sort-option-menu");
        if (this.$sortModalElem.length) {
            this.sortModal = this.$sortModalElem.modal({
                show: false
            });
        }
        this.changeObservable$.subscribe(function (event) {
            var $selectedElem = $(event.target).closest(ListingsSort.OPTION_SELECTOR);
            var $sortTooltip = $("#sortExplanationTooltip");
            var newExplanation = $selectedElem.data("js-explanation");
            if (!newExplanation) {
                $sortTooltip.attr("data-original-title", "");
                $sortTooltip.removeClass("sort-info-enabled");
                $sortTooltip.addClass("sort-info-disabled");
            }
            else {
                $sortTooltip.attr("data-original-title", newExplanation);
                $sortTooltip.removeClass("sort-info-disabled");
                $sortTooltip.addClass("sort-info-enabled");
            }
            _this.selectedSort = $selectedElem.data("value");
            var sortDisplayElement = $selectedElem.find(".sort-name");
            var sortDisplayName = sortDisplayElement.length > 0
                ? sortDisplayElement.html()
                : $("label[for='" + $selectedElem.attr("id") + "']").html();
            $displayedSelectedSort.html(sortDisplayName);
            _this.getSelectedOptionElement($parentElement).removeClass(ListingsSort.SELECTED_CLASS);
            var $selectedOptions = _this.getSortOptionByUrl($parentElement, _this.selectedSort);
            $selectedOptions.addClass(ListingsSort.SELECTED_CLASS);
            if (_this.$sortModalElem.length) {
                $sortModalButton.find(".modal-button-display-sort").html(sortDisplayName);
                if (_this.$sortModalElem.hasClass("show")) {
                    _this.sortModal.modal("hide");
                }
            }
            $sortOptionMenuElem.find(ListingsSort.OPTION_SELECTOR).each(function (index, elem) {
                $(elem).prop("checked", $(elem).data("value") === $selectedElem.data("value"));
            });
        });
    }
    ListingsSort.prototype.getSelectedSort = function () {
        return this.selectedSort;
    };
    ListingsSort.prototype.getChangeObservable = function () {
        return this.changeObservable$;
    };
    ListingsSort.prototype.getSelectedOptionElement = function ($parentElement) {
        return $parentElement.find(ListingsSort.OPTION_SELECTOR + "." + ListingsSort.SELECTED_CLASS);
    };
    ListingsSort.prototype.getSortOptionByUrl = function ($parentElement, url) {
        return $parentElement.find(ListingsSort.OPTION_SELECTOR + "[data-value='" + url + "']");
    };
    ListingsSort.URL_PARAM_NAME = "sortType";
    ListingsSort.OPTION_SELECTOR = ".sort-option";
    ListingsSort.SELECTED_CLASS = "selected-sort";
    ListingsSort.SORT_MODAL_SELECTOR = ".sort-modal";
    ListingsSort.DEFAULT_SORT = "featured";
    return ListingsSort;
}());
export { ListingsSort };
