var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DateRangeFilter } from "./dateRangeFilter";
import $ from "../common/jquery";
var AggregateDateRangeFilter = (function (_super) {
    __extends(AggregateDateRangeFilter, _super);
    function AggregateDateRangeFilter(parentElement, container, clearDateButton) {
        return _super.call(this, parentElement, container, clearDateButton) || this;
    }
    AggregateDateRangeFilter.prototype.clear = function () {
        this.unselectExtraDateRangeSetters();
        this.updateDatesInHeader(null, null);
        _super.prototype.clear.call(this);
    };
    AggregateDateRangeFilter.prototype.updateAndApplyDatePicker = function (startDate, endDate) {
        this.updateDatePicker(startDate, endDate);
        this.picker.applyDatesFilter();
    };
    AggregateDateRangeFilter.prototype.updateDatePicker = function (startDate, endDate) {
        this.picker.update({ startDate: startDate, endDate: endDate }, this.datesSetFromExtraSetters);
        this.updateDatesInHeader(startDate, endDate);
    };
    AggregateDateRangeFilter.prototype.handleOnApply = function () {
        this.setExtraSettersFlag(false);
        this.unselectExtraDateRangeSetters();
        _super.prototype.handleOnApply.call(this);
    };
    AggregateDateRangeFilter.prototype.setExtraSettersFlag = function (datesFromAddtionalSetters) {
        this.datesSetFromExtraSetters = datesFromAddtionalSetters;
    };
    AggregateDateRangeFilter.prototype.unselectExtraDateRangeSetters = function () {
        $(AggregateDateRangeFilter.EXTRA_DATE_SETTER_SELECTOR)
            .removeClass(AggregateDateRangeFilter.SELECTED_CLASS);
    };
    AggregateDateRangeFilter.prototype.updateDatesInHeader = function (startDate, endDate) {
        var searchMobileDateRange = $("#searchMobileDateRange");
        if (searchMobileDateRange.length > 0) {
            var startDateString = '';
            var endDateString = '';
            if (startDate !== null) {
                startDateString = startDate.toISOString();
                endDateString = endDate.toISOString();
            }
            searchMobileDateRange.attr('data-startDate', startDateString.split('T')[0]);
            searchMobileDateRange.attr('data-endDate', endDateString.split('T')[0]);
        }
    };
    AggregateDateRangeFilter.EXTRA_DATE_SETTER_SELECTOR = ".extra-date-range-setter";
    AggregateDateRangeFilter.SELECTED_CLASS = "selected";
    return AggregateDateRangeFilter;
}(DateRangeFilter));
export { AggregateDateRangeFilter };
