import { fromEvent as observableFromEvent, merge as observableMerge } from "rxjs";
import { debounceTime, throttleTime, map, filter } from "rxjs/operators";
var DEFAULT_SETTINGS = {
    closeOnClick: true
};
var HoverDropdown = (function () {
    function HoverDropdown(button, content, settings) {
        this.$button = button;
        this.$content = content;
        this.settings = settings || DEFAULT_SETTINGS;
        this.initialize();
    }
    HoverDropdown.prototype.getMouseEnterObservable = function () {
        return this.mouseEnterObservable;
    };
    HoverDropdown.prototype.initialize = function () {
        var _this = this;
        this.mouseEnterObservable = observableMerge(observableFromEvent(this.$button, "mouseenter"), observableFromEvent(this.$button, "click"));
        this.mouseEnterObservable.pipe(throttleTime(100))
            .subscribe(function () {
            _this.$button.addClass("hover");
            _this.$content.show();
        });
        observableFromEvent(this.$content, "mouseenter").pipe(throttleTime(100))
            .subscribe(function () {
            _this.$content.addClass("mouse-entered");
        });
        observableFromEvent(this.$content, "mouseleave")
            .subscribe(function () {
            _this.$button.removeClass("hover");
            _this.$content.removeClass("mouse-entered");
            _this.$content.hide();
        });
        observableFromEvent(this.$button, "mouseleave").pipe(debounceTime(50))
            .subscribe(function () {
            if (!_this.$content.hasClass("mouse-entered")) {
                _this.$button.removeClass("hover");
                _this.$content.hide();
            }
        });
        observableMerge(observableFromEvent(document, "click"), observableFromEvent(document, "touchstart")).pipe(map(function (e) { return e.target; }), filter(function (target) { return !$(target).parent().is(_this.$button) &&
            !$(target).is(_this.$button) &&
            !_this.$content.find(target).length; })).subscribe(function () {
            _this.$button.removeClass("hover");
            _this.$content.hide();
        });
        if (this.settings.closeOnClick) {
            observableFromEvent(this.$content, "click").subscribe(function () {
                _this.$button.removeClass("hover");
                _this.$content.hide();
            });
        }
    };
    return HoverDropdown;
}());
export { HoverDropdown };
