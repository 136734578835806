import "../../scss/priceFilter.scss";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { merge as observableMerge, fromEvent as observableFromEvent } from "rxjs";
import { PriceSlider } from "../priceslider/priceSlider";
import { KeyCodes } from "../common/keyCodes";
import $ from "../common/jquery";
var PriceFilter = (function () {
    function PriceFilter(container, immediateUpdate) {
        this.currentState = { priceFilter: "" };
        this.$priceFilterDiv = $(container).find(PriceFilter.PRICE_FILTER_OPTIONS);
        this.priceSlider = new PriceSlider(this.$priceFilterDiv);
        this.immediateUpdate = immediateUpdate;
        if (this.$priceFilterDiv.hasClass("keep-open-on-click-inside")) {
            observableFromEvent(this.$priceFilterDiv, "click")
                .subscribe(function (e) {
                e.stopPropagation();
            });
        }
    }
    PriceFilter.prototype.clear = function () {
        this.priceSlider.clear();
        this.currentState = { priceFilter: "" };
    };
    PriceFilter.prototype.getState = function () {
        return { priceFilter: this.priceSlider.getState() };
    };
    PriceFilter.prototype.getAppliedOptionsCount = function () {
        return this.priceSlider.getAppliedOptionsCount();
    };
    PriceFilter.prototype.getChangeObservable = function () {
        var _this = this;
        var enterButtonObs = observableFromEvent(this.$priceFilterDiv, "keyup").pipe(filter(function (e) { return e.keyCode === KeyCodes.ENTER; }), map(function () { return _this.getState(); }));
        enterButtonObs.subscribe(function () { return _this.currentState = _this.getState(); });
        if (this.immediateUpdate) {
            var typingInputObs = observableFromEvent(this.$priceFilterDiv.find("input.price"), "change").pipe(distinctUntilChanged(), map(function () { return _this.getState(); }));
            typingInputObs.subscribe(function () { return _this.currentState = _this.getState(); });
            var sliderObs = this.priceSlider.getChangeObservable().pipe(map(function () { return _this.getState(); }));
            return observableMerge(enterButtonObs, sliderObs, typingInputObs);
        }
        else {
            return enterButtonObs;
        }
    };
    PriceFilter.prototype.updateFromState = function (state) {
        if (state != null && state.priceFilter != null) {
            this.priceSlider.updateFromState(state.priceFilter);
            this.$priceFilterDiv.find(PriceFilter.APPLIED_OPTIONS).html(this.getAppliedOptionsCount());
            this.currentState = state;
        }
    };
    PriceFilter.prototype.revertState = function () {
        this.updateFromState(this.currentState);
    };
    PriceFilter.prototype.getStateMessage = function () {
        return this.priceSlider.getStateMessage();
    };
    PriceFilter.PRICE_FILTER_OPTIONS = ".price-filter-options";
    PriceFilter.APPLIED_OPTIONS = ".filter-count";
    return PriceFilter;
}());
export { PriceFilter };
