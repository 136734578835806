import { fromEvent as observableFromEvent } from "rxjs";
import { HelpCenterLoader } from "../helpCenterLoader";
var HelpButtonWithUnreadCount = (function () {
    function HelpButtonWithUnreadCount($headerContainer, window$, unreadMessageCount) {
        if (window$ === void 0) { window$ = window; }
        var _this = this;
        this.window = window;
        this.$helpButton = $headerContainer.find("#helpMenuButton");
        this.$helpSideButton = $headerContainer.find("#helpSideButton");
        this.$faqTopNavHelpIcon = $headerContainer.find("#faq-nav-help");
        this.$unreadMessageCount = this.$helpButton.find("#numUnreadMessage");
        this.$unreadMessageCountSideNav = this.$helpSideButton.find("#numUnreadMessageSide");
        this.$numUnreadMsgOnBurger = $headerContainer.find("#numUnreadMsgOnBurger");
        this.$numUnreadMessageFaq = this.$faqTopNavHelpIcon.find("#numUnreadMessageFaq");
        this.isShellActivated = false;
        if (this.window.addEventListener) {
            var updateUnreadNotificationCount_1 = function (unreadNotificationsCountToShow) {
                if (unreadNotificationsCountToShow > 0) {
                    _this.$unreadMessageCount.html(unreadNotificationsCountToShow);
                    _this.$unreadMessageCountSideNav.html(unreadNotificationsCountToShow);
                    _this.$numUnreadMsgOnBurger.html(unreadNotificationsCountToShow);
                    _this.$numUnreadMessageFaq.html(unreadNotificationsCountToShow);
                    _this.$unreadMessageCount.removeClass("d-none");
                    _this.$unreadMessageCountSideNav.removeClass("d-none");
                    _this.$numUnreadMsgOnBurger.removeClass("d-none");
                    _this.$numUnreadMessageFaq.removeClass("d-none");
                }
                else {
                    _this.$unreadMessageCount.addClass("d-none");
                    _this.$unreadMessageCountSideNav.addClass("d-none");
                    _this.$numUnreadMsgOnBurger.addClass("d-none");
                    _this.$numUnreadMessageFaq.addClass("d-none");
                }
            };
            this.window.addEventListener('HC_WIDGET_UPDATE_UNREAD_NOTIFICATIONS_COUNT', function (e) {
                var _a = e.detail, unreadNotificationsCount = _a.unreadNotificationsCount, from = _a.from;
                var helpShellState = HelpCenterLoader.getHelpShellState(_this.window);
                var stateUnreadNotificationsCountTotal = helpShellState.unreadNotificationsTotal;
                if (typeof unreadNotificationsCount === 'undefined' ||
                    typeof stateUnreadNotificationsCountTotal === 'undefined') {
                    return;
                }
                if (!_this.isShellActivated) {
                    helpShellState.unreadNotificationsTotal = helpShellState.unreadNotificationsTotal + unreadNotificationsCount;
                    helpShellState.unreadNotificationsCounts[from] = (helpShellState.unreadNotificationsCounts[from] || 0) + unreadNotificationsCount;
                    HelpCenterLoader.setHelpShellState(_this.window, helpShellState);
                }
                var unreadNotificationsCountToShow = stateUnreadNotificationsCountTotal + unreadNotificationsCount;
                updateUnreadNotificationCount_1(unreadNotificationsCountToShow);
            });
            this.window.addEventListener(HelpCenterLoader.HC_WIDGET_INITIAL_NOTIFICATIONS_COUNT, function (e) {
                var unreadNotificationsCount = e.detail.unreadNotificationsCount;
                if (unreadNotificationsCount) {
                    updateUnreadNotificationCount_1(unreadNotificationsCount);
                }
            });
        }
    }
    HelpButtonWithUnreadCount.prototype.bootstrap = function () {
        var _this = this;
        observableFromEvent(this.$helpButton, "click")
            .subscribe(function (event) { return _this.loadAndActivateHelpShell(event); });
        observableFromEvent(this.$helpSideButton, "click")
            .subscribe(function (event) { return _this.loadAndActivateHelpShell(event); });
        observableFromEvent(this.$faqTopNavHelpIcon, "click")
            .subscribe(function (event) { return _this.loadAndActivateHelpShell(event); });
    };
    HelpButtonWithUnreadCount.prototype.loadAndActivateHelpShell = function (event) {
        var _this = this;
        this.isShellActivated = true;
        HelpCenterLoader.load(this.window, event)
            .then(function () { return HelpCenterLoader.activate(_this.window, HelpCenterLoader.HC_HELP_SHELL); });
    };
    return HelpButtonWithUnreadCount;
}());
export { HelpButtonWithUnreadCount };
