import { FormStatus } from "../../account/IFormResponse";
import { ClickStream } from "../../clickstream";
import { AjaxService, HTML_RESPONSE_TYPE } from "../../common/ajax";
import $ from "../../common/jquery";
import { ForgotPasswordFormValidator } from "./forgotPasswordFormValidator";
import { ForgotPasswordSuccess } from "./forgotPasswordSuccess";
import "bootstrap";
import "parsleyjs";
import { EMPTY, fromEvent as observableFromEvent, Subject, } from "rxjs";
import { AjaxTimeoutError } from "rxjs/ajax";
import { catchError, filter, switchMap } from "rxjs/operators";
var ForgotPasswordForm = (function () {
    function ForgotPasswordForm($container, $modalContainer, ajaxService) {
        if (ajaxService === void 0) { ajaxService = new AjaxService(); }
        this.forgotPasswordSubject = new Subject();
        this.$modalContainer = $modalContainer;
        this.$container = $container;
        this.ajaxService = ajaxService;
        this.initialize();
    }
    ForgotPasswordForm.prototype.getFormObservable = function () {
        return this.forgotPasswordSubject;
    };
    ForgotPasswordForm.prototype.buildAjaxPost$ = function () {
        var email = this.forgotPasswordForm.find("input[name='email']")[0].value;
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "click_modal_forgot_password",
            servletName: ForgotPasswordForm.FORGOT_PASSWORD_MODAL_TRACKING_SERVLET,
        });
        this.forgotPasswordSubject.next({ status: FormStatus.SUBMITTED });
        return this.ajaxService.doPostWithTimeout(this.getForgotPasswordURL(), ForgotPasswordForm.SIGNUP_TIMEOUT, {
            email: email,
        }, HTML_RESPONSE_TYPE, { "Content-Type": "application/json" });
    };
    ForgotPasswordForm.prototype.initialize = function () {
        var _this = this;
        this.forgotPasswordForm = this.$modalContainer.find(ForgotPasswordForm.FORGOT_PASSWORD_FORM_CLASS);
        this.setupEnterKeyToForgotPassword($(this.forgotPasswordForm));
        this.validator = new ForgotPasswordFormValidator(this.forgotPasswordForm);
        this.forgotPasswordButton = $(this.forgotPasswordForm).find(ForgotPasswordForm.SEND_LINK_BUTTON_ID);
        this.buildStream$().subscribe(function (response) {
            ClickStream.postClickStreamWithCallback({
                pageAction: "forgot_password_success",
                servletName: ForgotPasswordForm.FORGOT_PASSWORD_MODAL_TRACKING_SERVLET,
            }, function () {
                _this.forgotPasswordSubject.next({
                    status: FormStatus.SUCCESS,
                    message: response.responseText,
                });
            });
            var $tapForgotPasswordModalSuccess = $("#tapForgotPasswordModalSuccess");
            new ForgotPasswordSuccess($("body"), $tapForgotPasswordModalSuccess, _this.forgotPasswordForm.find("input[name='email']")[0].value);
            _this.$container.find("#tapForgotPasswordModal").modal("hide");
            _this.$container.find("#tapForgotPasswordModalSuccess").modal("show");
        });
        this.backToLoginButton = this.$modalContainer.find("#backToLoginButton");
        this.backToLoginButton.click(function (e) { return _this.handleBackToLogin(e); });
        return this;
    };
    ForgotPasswordForm.prototype.buildStream$ = function () {
        var _this = this;
        var buttonHtml = this.forgotPasswordButton.html();
        return observableFromEvent(this.forgotPasswordButton, "click").pipe(filter(function () { return _this.validateForm(); }), switchMap(function () {
            return _this.buildAjaxPost$().pipe(catchError(function (error) {
                error instanceof AjaxTimeoutError
                    ? $(ForgotPasswordForm.FORGOT_PASSWORD_TIMEOUT_ERROR_CLASS).removeClass(ForgotPasswordForm.DISPLAY_NONE_CLASS)
                    : _this.forgotPasswordButton.html(buttonHtml);
                _this.forgotPasswordSubject.next({ status: FormStatus.FAILED });
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "forgot_password_failure",
                    servletName: ForgotPasswordForm.FORGOT_PASSWORD_MODAL_TRACKING_SERVLET,
                });
                return EMPTY;
            }));
        }));
    };
    ForgotPasswordForm.prototype.handleBackToLogin = function (e) {
        e.preventDefault();
        this.$container.find("#tapLoginModal").modal("show");
        this.$container.find("#tapForgotPasswordModal").modal("hide");
    };
    ForgotPasswordForm.prototype.setupEnterKeyToForgotPassword = function ($forgotPasswordForm) {
        observableFromEvent($forgotPasswordForm, "keydown")
            .pipe(filter(function (e) { return e.key === ForgotPasswordForm.KEY_ENTER; }))
            .subscribe(function () {
            $forgotPasswordForm
                .find(ForgotPasswordForm.SEND_LINK_BUTTON_ID)
                .trigger("click");
        });
    };
    ForgotPasswordForm.prototype.validateForm = function () {
        return this.forgotPasswordForm.parsley().validate();
    };
    ForgotPasswordForm.prototype.getForgotPasswordURL = function () {
        return ForgotPasswordForm.GENERIC_FORGOT_PASSWORD_URL;
    };
    ForgotPasswordForm.FORGOT_PASSWORD_FORM_CLASS = ".forgot-password-form";
    ForgotPasswordForm.SEND_LINK_BUTTON_ID = "#sendLinkButton";
    ForgotPasswordForm.FORGOT_PASSWORD_TIMEOUT_ERROR_CLASS = ".forgot-password-timeout-error";
    ForgotPasswordForm.DISPLAY_NONE_CLASS = "d-none";
    ForgotPasswordForm.GENERIC_FORGOT_PASSWORD_URL = "/orion/ajax/account/send-password-reset-email";
    ForgotPasswordForm.KEY_ENTER = "Enter";
    ForgotPasswordForm.FORGOT_PASSWORD_MODAL_TRACKING_SERVLET = "tap_modal_forgot_password";
    ForgotPasswordForm.SIGNUP_TIMEOUT = 10000;
    return ForgotPasswordForm;
}());
export { ForgotPasswordForm };
