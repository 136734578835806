import { fromEvent as observableFromEvent } from "rxjs";
import { ClickStream } from "../clickstream";
var UspBannerClicksListener = (function () {
    function UspBannerClicksListener(bannerContainer) {
        this.listen(bannerContainer);
    }
    UspBannerClicksListener.prototype.listen = function (bannerContainer) {
        var closeButtons = bannerContainer.find(".close-inline-banners");
        closeButtons.each(function (i) {
            observableFromEvent(closeButtons[i], "click")
                .subscribe(function (event) {
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "click_usp_banner_close",
                    servletName: ClickStream.getServlet()
                });
                bannerContainer.hide();
            });
        });
        var bannerCtas = bannerContainer.find(".inline-banner-cta");
        bannerCtas.each(function (i) {
            observableFromEvent(bannerCtas[i], "click")
                .subscribe(function (event) {
                ClickStream.postClickStreamWithTrackingArguments({
                    pageAction: "click_usp_banner",
                    servletName: ClickStream.getServlet()
                });
            });
        });
    };
    return UspBannerClicksListener;
}());
export { UspBannerClicksListener };
