import { fromEvent as observableFromEvent } from "rxjs";
import { LanguagePreferenceBanner } from "../languagePreferenceBanner";
var LanguagePreferenceBannerCloseListener = (function () {
    function LanguagePreferenceBannerCloseListener(bannerContainer) {
        this.listen(bannerContainer);
    }
    LanguagePreferenceBannerCloseListener.prototype.listen = function (bannerContainer) {
        var closeButton = bannerContainer.find(".close-language-preference-banner");
        var languagePreferenceBanner = new LanguagePreferenceBanner();
        observableFromEvent(closeButton, "click")
            .subscribe(function (event) {
            languagePreferenceBanner.setLanguagePreferenceCookie(true, null).subscribe();
            bannerContainer.hide();
        });
    };
    return LanguagePreferenceBannerCloseListener;
}());
export { LanguagePreferenceBannerCloseListener };
