import { fromEvent as observableFromEvent } from "rxjs";
var NextPageButton = (function () {
    function NextPageButton(parentElement) {
        var _this = this;
        this.$parentElement = $(parentElement);
        this.pageNum = 1;
        this.changeObservable$ = observableFromEvent(this.$parentElement, "click");
        this.changeObservable$.subscribe(function () {
            _this.pageNum++;
            _this.$parentElement.addClass("d-none");
        });
    }
    NextPageButton.prototype.getPageNum = function () {
        return this.pageNum;
    };
    NextPageButton.prototype.getChangeObservable = function () {
        return this.changeObservable$;
    };
    NextPageButton.prototype.hidePaginationButton = function () {
        this.$parentElement.addClass("d-none");
    };
    NextPageButton.prototype.showPaginationButton = function () {
        this.$parentElement.removeClass("d-none");
    };
    NextPageButton.prototype.setToInitialState = function () {
        this.pageNum = 1;
        this.showPaginationButton();
    };
    NextPageButton.URL_PARAM_NAME = "pageNum";
    return NextPageButton;
}());
export { NextPageButton };
