import { isDefinedAndNotNull } from "./utils/objectUtil";
export var TTD_URL_PATTERN = new RegExp("/.+/d(\\d+)-ttd");
export var PDP_URL_PATTERN = new RegExp("/tours/.+/.+/d\\d+-(.+)");
export var CATEGORY_URL_PATTERN = new RegExp("/.+-tours/.+/d\\d+-g\\d+-(c\\d+)");
export var GROUP_URL_PATTERN = new RegExp("/.+-tours/.+/d\\d+-(g\\d+)");
export var RECOMMENDATION_URL_PATTERN = new RegExp("/.+-tourism/.+/d\\d+-t(\\d+)");
export var ATTRACTION_URL_PATTERN = new RegExp("/.+-attractions/.+/d\\d+-a(\\d+)");
export var ALL_ATTRACTIONS_URL_PATTERN = new RegExp("/.+/d(\\d+)");
export var ARTICLE_URL_PATTERN = new RegExp("/blog/[^/]*/l(\\d+)");
export var SHELF_COLLECTION_URL_PATTERN = new RegExp("/collections/[^/]*/c(\\d+)");
var Url = (function () {
    function Url(url) {
        this.url = url;
        this.path = this.url.split(/[?#]/)[0];
        this.anchor = Url.ANCHOR_REG_EX.test(url)
            ? url.match(Url.ANCHOR_REG_EX)[0]
            : undefined;
    }
    Url.prototype.getQueryParameter = function (key) {
        return this.getQueryParameters()[key] || "";
    };
    Url.prototype.getQueryParameters = function () {
        var url = this.url;
        var qs = url.substring(url.indexOf("?") + 1);
        var result = {};
        if (qs === url) {
            return result;
        }
        var qsList = qs.split("&");
        for (var i = 0; i < qsList.length; i++) {
            qsList[i] = qsList[i].split("=");
            result[qsList[i][0]] = decodeURIComponent(qsList[i][1]);
        }
        return result;
    };
    Url.prototype.updateQueryString = function (key, value) {
        var re = new RegExp("([?|&])" + key + "=.*?(&|#|$)", "i");
        var url = this.url;
        if (isDefinedAndNotNull(value)) {
            if (url.match(re)) {
                return new Url(url.replace(re, "$1" + key + "=" + value + "$2"));
            }
            else {
                var hash = "";
                var separator = url.indexOf("?") !== -1 ? "&" : "?";
                if (url.indexOf("#") !== -1) {
                    hash = url.replace(/.*#/, "#");
                    url = url.replace(/#.*/, "");
                }
                return new Url(url + separator + key + "=" + value + hash);
            }
        }
        return this.removeQueryParameter(key);
    };
    Url.prototype.removeQueryParameter = function (key) {
        var queryParameters = this.getQueryParameters();
        var queryString = Object.keys(queryParameters)
            .filter(function (param) { return param !== key; })
            .map(function (param) { return param + "=" + queryParameters[param]; })
            .join("&");
        var result = queryString.length > 0 ? this.path + "?" + queryString : this.path;
        if (this.anchor) {
            result = result + this.anchor;
        }
        return new Url(result);
    };
    Url.prototype.getPath = function () {
        return this.path;
    };
    Url.prototype.getUrl = function () {
        return this.url;
    };
    Url.ANCHOR_REG_EX = /#.+/;
    return Url;
}());
export { Url };
