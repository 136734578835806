import { fromEvent as observableFromEvent } from "rxjs";
import { throttleTime } from "rxjs/operators";
var InlineElements = (function () {
    function InlineElements(languageSwitcherButton, currencySwitcherButton, window) {
        var _this = this;
        this.$leftElement = languageSwitcherButton;
        this.$rightElement = currencySwitcherButton;
        this.$window = window;
        this.resizeButtons();
        observableFromEvent($(this.$window), "resize")
            .pipe(throttleTime(100))
            .subscribe(function () {
            _this.resizeButtons();
        });
    }
    InlineElements.prototype.resizeButtons = function () {
        if (this.getElementHeight(this.$leftElement) !==
            this.getElementHeight(this.$rightElement)) {
            this.$leftElement.parent()
                .removeClass("inline-element-left")
                .addClass("full-width-element");
            this.$rightElement.parent()
                .removeClass("inline-element-right")
                .addClass("full-width-element");
        }
    };
    InlineElements.prototype.getElementHeight = function (element) {
        return element.innerHeight();
    };
    return InlineElements;
}());
export { InlineElements };
