import { EMPTY as observableEmpty, fromEvent as observableFromEvent } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { ClickStream } from "../clickstream";
import { AjaxService, HTML_RESPONSE_TYPE } from "../common/ajax";
import "../../global/main";
import "../../scss/tapBookableLink.scss";
import $ from "../common/jquery";
import { catchError } from "rxjs/operators";
import { HttpCodes } from "../common/HttpCodes";
import { CopyToClipboard } from "../common/copyToClipboard";
import "url-search-params-polyfill";
var TapBookableShareLink = (function () {
    function TapBookableShareLink(container$, ajaxService$, window$) {
        if (ajaxService$ === void 0) { ajaxService$ = new AjaxService(); }
        if (window$ === void 0) { window$ = window; }
        this.container = container$;
        this.window = window$;
        this.ajaxService = ajaxService$;
    }
    TapBookableShareLink.prototype.bootstrap = function () {
        var _this = this;
        var requestedPageName = this.container
            .find("#tapShareLink, #tapShareLinkHomeHeader, #tapShareLinkHomeSideNav")
            .attr(TapBookableShareLink.DATA_PAGE_NAME);
        var bookableLinkCopied = this.container.find("#bookableLinkCopied");
        var bookableLinkText = this.container.find("#bookableLinkText");
        var bookableLinkCopyButtonText = this.container.find("#bookableLinkCopyButtonText");
        var bookableLinkCopyButtonChecked = this.container.find("#bookableLinkCopyButtonChecked");
        var bookableLinkLanguageSwitcherDropdown = this.container.find("#bookableLinkLanguageSwitcherDropdown");
        var bookableLinkLanguageSwitcherDropdownButton = this.container.find("#bookableLinkLanguageSwitcherDropdownButton");
        var bookableLinkCurrencySwitcherDropdown = this.container.find("#bookableLinkCurrencySwitcherDropdown");
        var bookableLinkCurrencySwitcherDropdownButton = this.container.find("#bookableLinkCurrencySwitcherDropdownButton");
        this.container.find("#bookableLinkCopyButton").click(function (event) {
            var oldPosition = window.pageYOffset;
            var copied = CopyToClipboard.copyInputText(bookableLinkText[0], _this.container[0]);
            window.scrollTo(0, oldPosition);
            var pageAction = "click_bookable_link_copy";
            pageAction = _this.getAgentType(event, pageAction);
            pageAction = _this.getPageType(event, pageAction);
            _this.copyLinkClickStream(pageAction);
            if (copied) {
                _this.copyComplete(bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked);
            }
            else {
                _this.copyStatusReset(bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked);
            }
        });
        this.container.find("#tapShareLink, #tapShareLinkHomeHeader, #tapShareLinkHomeSideNav").click(function () {
            bookableLinkText.keydown(function (event) {
                if (!event.altKey &&
                    (event.ctrlKey || event.metaKey) && event.which === 67) {
                    var pageAction = "keydown_bookable_link_copy";
                    pageAction = _this.getAgentType(event, pageAction);
                    pageAction = _this.getPageType(event, pageAction);
                    _this.copyLinkClickStream(pageAction);
                    _this.copyComplete(bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked);
                }
            });
            _this.copyStatusReset(bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked);
            var subFolder = bookableLinkLanguageSwitcherDropdownButton.length > 0 ?
                bookableLinkLanguageSwitcherDropdownButton.attr(TapBookableShareLink.DATA_SUBFOLDER) : "";
            var currency = bookableLinkCurrencySwitcherDropdownButton.length > 0 ?
                bookableLinkCurrencySwitcherDropdownButton.attr(TapBookableShareLink.DATA_CURRENCY) : "";
            _this.getBookableLinkGeneratorObservable(requestedPageName, subFolder, currency, false).pipe(catchError(function (err) { return _this.handleBookableLinkAjaxError(err); }))
                .subscribe(function (ajaxResponse) {
                bookableLinkText.val(ajaxResponse.response);
                bookableLinkText.select();
            });
        });
        this.checkUrlParameterForBookableLinkModal();
        this.listenForBookableLinkLanguageMenuItems(bookableLinkLanguageSwitcherDropdownButton, bookableLinkLanguageSwitcherDropdown, bookableLinkCurrencySwitcherDropdownButton, bookableLinkText, requestedPageName);
        this.listenForBookableLinkCurrencyMenuItems(bookableLinkCurrencySwitcherDropdownButton, bookableLinkCurrencySwitcherDropdown, bookableLinkLanguageSwitcherDropdownButton, bookableLinkText, requestedPageName);
    };
    TapBookableShareLink.prototype.getBookableLinkGeneratorObservable = function (pageName, locale, currency, fromDropDown) {
        var body = {
            tapUrl: location.href.split("?")[0].split("#")[0],
            dynamicFilters: new URLSearchParams(location.search).get("dynamicFilters"),
            useSelectedLocale: fromDropDown,
            selectedLocale: locale,
            selectedCurrency: currency,
            requestedPageName: pageName
        };
        return this.ajaxService.doPost(TapBookableShareLink.GENERATE_BOOKABLE_LINK_URL, body, HTML_RESPONSE_TYPE);
    };
    TapBookableShareLink.prototype.seenBookableLinkModalObservable = function () {
        return this.ajaxService.doGetSafe(TapBookableShareLink.SEEN_BOOKABLE_LINK_MODAL_URL);
    };
    TapBookableShareLink.prototype.getUrlParameter = function () {
        return (location.search.split("bookableLinkModal" + "=")[1] || "");
    };
    TapBookableShareLink.prototype.checkUrlParameterForBookableLinkModal = function () {
        var parameter = this.getUrlParameter();
        var isTapSeenBookableLinkModal = $("#tapSeenBookableLinkModal").val();
        if (isTapSeenBookableLinkModal === "false" && parameter === "1") {
            this.seenBookableLinkModalObservable().subscribe(function () {
                if ($("#tapShareLinkHomeHeader").length > 0) {
                    $("#tapShareLinkHomeHeader").click();
                }
                else if ($("#tapShareLinkHomeSideNav").length > 0) {
                    $("#tapShareLinkHomeSideNav").click();
                }
                else if ($("#tapShareLink").length > 0) {
                    $("#tapShareLink").click();
                }
            });
        }
    };
    TapBookableShareLink.prototype.copyComplete = function (bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked) {
        bookableLinkCopied.removeClass("d-none");
        bookableLinkCopied.addClass("d-block");
        bookableLinkCopyButtonText.removeClass("d-block");
        bookableLinkCopyButtonChecked.addClass("d-block");
    };
    TapBookableShareLink.prototype.copyStatusReset = function (bookableLinkCopied, bookableLinkCopyButtonText, bookableLinkCopyButtonChecked) {
        bookableLinkCopied.addClass("d-none");
        bookableLinkCopied.removeClass("d-block");
        bookableLinkCopyButtonChecked.removeClass("d-block");
        bookableLinkCopyButtonText.addClass("d-block");
    };
    TapBookableShareLink.prototype.copyLinkClickStream = function (pageAction) {
        ClickStream.postClickStream("bookable-link", pageAction, null, null, null, null, null);
    };
    TapBookableShareLink.prototype.listenForBookableLinkLanguageMenuItems = function (bookableLinkLanguageSwitcherDropdownButton, bookableLinkLanguageSwitcherDropdown, bookableLinkCurrencySwitcherDropdownButton, bookableLinkText, requestedPageName) {
        var _this = this;
        var bookableLinkLanguageMenuItems = bookableLinkLanguageSwitcherDropdown
            .find(TapBookableShareLink.BOOKABLE_LINK_LANGUAGE_MENU_ITEM_CLASS);
        observableFromEvent(bookableLinkLanguageMenuItems, TapBookableShareLink.CLICK_EVENT_NAME)
            .subscribe(function (event) {
            var currency = bookableLinkCurrencySwitcherDropdownButton.length > 0 ?
                bookableLinkCurrencySwitcherDropdownButton.attr(TapBookableShareLink.DATA_CURRENCY) : "";
            var element = $(event.target);
            var selectedItem = element.closest(TapBookableShareLink.BOOKABLE_LINK_LANGUAGE_MENU_ITEM_CLASS);
            var link = selectedItem.find(TapBookableShareLink.LINK_ITEM_CLASS);
            var subFolder = link.attr(TapBookableShareLink.DATA_VALUE_ATTRIBUTE);
            var countryFlag = selectedItem.find(TapBookableShareLink.COUNTRY_FLAG_CLASS);
            var newImgUrl = countryFlag.parent().attr(TapBookableShareLink.DATA_IMG_URL);
            _this.getBookableLinkGeneratorObservable(requestedPageName, subFolder, currency, true).pipe(catchError(function (err) { return _this.handleBookableLinkAjaxError(err); }))
                .subscribe(function (ajaxResponse) {
                bookableLinkText.val(ajaxResponse.response);
                bookableLinkText.select();
                bookableLinkLanguageSwitcherDropdownButton
                    .find(TapBookableShareLink.BOOKABLE_LINK_LANGUAGE_BUTTON_CLASS)
                    .attr(TapBookableShareLink.SRC_ATTRIBUTE, newImgUrl);
                bookableLinkLanguageSwitcherDropdownButton
                    .attr(TapBookableShareLink.DATA_SUBFOLDER, subFolder);
            });
        });
    };
    TapBookableShareLink.prototype.listenForBookableLinkCurrencyMenuItems = function (bookableLinkCurrencySwitcherDropdownButton, bookableLinkCurrencySwitcherDropdown, bookableLinkLanguageSwitcherDropdownButton, bookableLinkText, requestedPageName) {
        var _this = this;
        var bookableLinkCurrencyMenuItems = bookableLinkCurrencySwitcherDropdown
            .find(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_MENU_ITEM_CLASS);
        observableFromEvent(bookableLinkCurrencyMenuItems, TapBookableShareLink.CLICK_EVENT_NAME)
            .subscribe(function (event) {
            var subFolder = bookableLinkLanguageSwitcherDropdownButton.length > 0 ?
                bookableLinkLanguageSwitcherDropdownButton.attr(TapBookableShareLink.DATA_SUBFOLDER) : "";
            var element = $(event.target);
            var selectedItem = element.closest(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_MENU_ITEM_CLASS);
            var currencySymbol = selectedItem
                .find(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_SYMBOL_CLASS).text();
            var currency = selectedItem
                .find(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_CLASS).text();
            _this.getBookableLinkGeneratorObservable(requestedPageName, subFolder, currency, true).pipe(catchError(function (err) { return _this.handleBookableLinkAjaxError(err); }))
                .subscribe(function (ajaxResponse) {
                bookableLinkText.val(ajaxResponse.response);
                bookableLinkText.select();
                bookableLinkCurrencySwitcherDropdownButton
                    .find(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_SYMBOL_CLASS)
                    .html(currencySymbol);
                bookableLinkCurrencySwitcherDropdownButton
                    .find(TapBookableShareLink.BOOKABLE_LINK_CURRENCY_CLASS)
                    .html(currency);
                bookableLinkCurrencySwitcherDropdownButton
                    .attr(TapBookableShareLink.DATA_CURRENCY, currency);
            });
        });
    };
    TapBookableShareLink.prototype.handleBookableLinkAjaxError = function (err) {
        if (err instanceof AjaxError && err.status === HttpCodes.UNAUTHORIZED) {
            this.window.location.replace(TapBookableShareLink.LOGOUT_URL);
        }
        return observableEmpty;
    };
    TapBookableShareLink.prototype.getAgentType = function (event, pageAction) {
        var agentType = $(event.currentTarget).attr("agent-type");
        if (agentType !== undefined && agentType !== null) {
            pageAction += "_" + agentType;
        }
        return pageAction;
    };
    TapBookableShareLink.prototype.getPageType = function (event, pageAction) {
        var pageType = $(event.currentTarget).attr("page-type");
        if (pageType !== undefined && pageType !== null) {
            pageAction += "_" + pageType;
        }
        return pageAction;
    };
    TapBookableShareLink.GENERATE_BOOKABLE_LINK_URL = "/orion/travel-agent/generateBookableLink";
    TapBookableShareLink.SEEN_BOOKABLE_LINK_MODAL_URL = "/orion/travel-agent/ajax/seenBookableLinkModal";
    TapBookableShareLink.CLICK_EVENT_NAME = "click";
    TapBookableShareLink.BOOKABLE_LINK_LANGUAGE_BUTTON_CLASS = ".bookable-link-language-button-image";
    TapBookableShareLink.BOOKABLE_LINK_LANGUAGE_MENU_ITEM_CLASS = ".bookable_link-language-menu-item";
    TapBookableShareLink.COUNTRY_FLAG_CLASS = ".country-flag";
    TapBookableShareLink.BOOKABLE_LINK_CURRENCY_SYMBOL_CLASS = ".bookable-link-currency-symbol";
    TapBookableShareLink.BOOKABLE_LINK_CURRENCY_CLASS = ".bookable-link-currency";
    TapBookableShareLink.BOOKABLE_LINK_CURRENCY_MENU_ITEM_CLASS = ".bookable_link-currency-menu-item";
    TapBookableShareLink.LOGOUT_URL = "/logout";
    TapBookableShareLink.LINK_ITEM_CLASS = ".link-item";
    TapBookableShareLink.DATA_PAGE_NAME = "data-page-name";
    TapBookableShareLink.DATA_VALUE_ATTRIBUTE = "data-value";
    TapBookableShareLink.DATA_IMG_URL = "data-img-url";
    TapBookableShareLink.DATA_SUBFOLDER = "data-subfolder";
    TapBookableShareLink.DATA_CURRENCY = "data-currency";
    TapBookableShareLink.SRC_ATTRIBUTE = "src";
    return TapBookableShareLink;
}());
export { TapBookableShareLink };
