import "../../scss/_heart.scss";
import { fromEvent as observableFromEvent } from "rxjs";
import { AjaxService, JSON_RESPONSE_TYPE } from "../common/ajax";
import { ClickStream } from "../clickstream";
import $ from "../common/jquery";
import { isDefinedAndNotNull } from "../common/utils/objectUtil";
import { WishlistErrorModal } from "../../module-entries/wishlistErrorModal";
var WishlistButton = (function () {
    function WishlistButton($buttonsContainer) {
        var $buttons = $buttonsContainer.find(".wishlist-button");
        WishlistButton.initializeWishlistProductCodes();
        WishlistButton.initializeButtons($buttons);
        $buttons.each(function (index, button) {
            if (!$(button).hasClass(WishlistButton.INITIALIZED_CLASS)) {
                observableFromEvent($(button), "click").subscribe(function (event) { return WishlistButton.handleButtonClick(event); });
            }
        });
        $buttons.addClass(WishlistButton.INITIALIZED_CLASS);
    }
    WishlistButton.handleButtonClick = function (event) {
        event.stopPropagation();
        var currentTarget = event.currentTarget;
        WishlistButton.toggleWishlist($(currentTarget));
    };
    WishlistButton.trackWishlistAction = function (pageAction, productID) {
        var servletName = ClickStream.getServlet();
        var pageProperties = { product: "saves" };
        ClickStream.postClickStreamWithTrackingArguments({
            attractionProductId: productID,
            pageAction: pageAction,
            pageProperties: pageProperties,
            servletName: servletName,
        });
    };
    WishlistButton.toggleWishlist = function ($button) {
        var productCode = $button.attr("data-attraction-product-id");
        var inWishlist = this.wishlistProductCodes.has(productCode);
        var trackingString = inWishlist ? WishlistButton.CLICK_REMOVE_TRACKING : WishlistButton.CLICK_SAVE_TRACKING;
        WishlistButton.trackWishlistAction(trackingString, productCode);
        if (inWishlist) {
            this.removeProductFromWishlist(productCode);
        }
        else {
            this.addProductToWishlist(productCode);
        }
    };
    WishlistButton.updateWishlistItemCount = function () {
        WishlistButton.wishlistItemCount = this.wishlistProductCodes.size;
        var $wishListCountIcon = $(".num-wishlist-items");
        var itemCount = this.wishlistItemCount;
        $wishListCountIcon.toggle(itemCount > 0);
        $wishListCountIcon.text(itemCount.toString());
    };
    WishlistButton.updateWishlistHeartsForProduct = function (productCode) {
        var inWishlist = this.wishlistProductCodes.has(productCode);
        this.productCodeToButtonMap.get(productCode).each(function (index, button) {
            var $productButton = $(button);
            var $heart = $productButton.find(".heart");
            if (inWishlist) {
                $productButton.addClass("wishlisted");
                $heart.addClass("pulse");
            }
            else {
                $productButton.removeClass("wishlisted");
                $heart.removeClass("pulse");
            }
        });
    };
    WishlistButton.handleWishlistError = function (wishlistError) {
        WishlistButton.wishlistErrorModal.open(wishlistError);
    };
    WishlistButton.updateWishlistProductCodes = function (productCodes) {
        this.wishlistProductCodes = productCodes;
    };
    WishlistButton.handleWishlistUpdate = function (response) {
        var updatedProductCodes = response.response.productCodes;
        if (isDefinedAndNotNull(updatedProductCodes)) {
            var updatedProductCodesSet = new Set(response.response.productCodes);
            this.updateWishlistProductCodes(updatedProductCodesSet);
            this.updateWishlistItemCount();
        }
    };
    WishlistButton.addProductToWishlist = function (productCode) {
        var _this = this;
        var addItemUrl = WishlistButton.WISHLIST_AJAX_ROOT_URL + "/addProduct";
        return WishlistButton.ajax.postJSON(addItemUrl, { productCode: productCode }, JSON_RESPONSE_TYPE)
            .toPromise()
            .then(function (response) { return _this.handleWishlistUpdate(response); })
            .then(function () { return _this.updateWishlistHeartsForProduct(productCode); })
            .catch(function (error) { return _this.handleWishlistError(error.response); });
    };
    WishlistButton.removeProductFromWishlist = function (productCode) {
        var _this = this;
        var deleteWishlistUrl = WishlistButton.WISHLIST_AJAX_ROOT_URL + "/removeProduct";
        return WishlistButton.ajax.postJSON(deleteWishlistUrl, { productCode: productCode }, JSON_RESPONSE_TYPE)
            .toPromise()
            .then(function (response) { return _this.handleWishlistUpdate(response); })
            .then(function () { return _this.updateWishlistHeartsForProduct(productCode); });
    };
    WishlistButton.initializeButtons = function ($buttons) {
        $(".num-wishlist-items").toggle(this.wishlistItemCount > 0);
        for (var _i = 0, _a = $buttons.toArray(); _i < _a.length; _i++) {
            var button = _a[_i];
            var $button = $(button);
            var productId = $button.attr("data-attraction-product-id");
            var inWishlist = this.wishlistProductCodes.has(productId);
            WishlistButton.productCodeToButtonMap.set(productId, $button.add(this.productCodeToButtonMap.get(productId)));
            if (inWishlist) {
                $button.addClass("wishlisted");
            }
        }
    };
    WishlistButton.WISHLIST_AJAX_ROOT_URL = "/orion/ajax/react/wishlist";
    WishlistButton.INITIALIZED_CLASS = "initialized";
    WishlistButton.CLICK_SAVE_TRACKING = "click_save_product";
    WishlistButton.CLICK_REMOVE_TRACKING = "click_remove_product";
    WishlistButton.ajax = new AjaxService();
    WishlistButton.wishlistProductCodes = new Set();
    WishlistButton.productCodeToButtonMap = new Map();
    WishlistButton.wishlistErrorModal = WishlistErrorModal.getInstance();
    WishlistButton.initializeWishlistProductCodes = function () {
        var productCodesStr = $("#userData").attr("data-wishlist-product-codes");
        var wishlistProductCodes;
        try {
            wishlistProductCodes = new Set(JSON.parse(productCodesStr));
        }
        catch (e) {
            wishlistProductCodes = new Set();
        }
        WishlistButton.wishlistProductCodes = wishlistProductCodes;
    };
    return WishlistButton;
}());
export { WishlistButton };
