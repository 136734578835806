import { AjaxService } from "./common/ajax";
import "../scss/languagePreferenceBanner.scss";
var LanguagePreferenceBanner = (function () {
    function LanguagePreferenceBanner() {
        this.ajaxService = new AjaxService();
    }
    LanguagePreferenceBanner.prototype.setLanguagePreferenceCookie = function (dismissedBanner, selectedLocale) {
        var body = {
            dismissedBanner: dismissedBanner,
            selectedLocale: selectedLocale
        };
        return this.ajaxService
            .postJSONSafe(LanguagePreferenceBanner.LANGUAGE_PREFERENCE_BANNER_URL, body);
    };
    LanguagePreferenceBanner.LANGUAGE_PREFERENCE_BANNER_URL = "/orion/ajax/languagePreferenceCookie";
    return LanguagePreferenceBanner;
}());
export { LanguagePreferenceBanner };
