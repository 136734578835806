import { fromEvent as observableFromEvent, merge as observableMerge } from "rxjs";
import { take, filter, debounceTime } from "rxjs/operators";
import CustomEvent from "../polyfill/CustomEvent";
import $ from "../common/jquery";
var loadAttribute = function (element, attr) {
    var dataAttr = "data-" + attr;
    var value = element.getAttribute(dataAttr);
    if (value) {
        element.setAttribute(attr, value);
        element.removeAttribute(dataAttr);
    }
};
var LOAD_EVENT = "LazyLoadContentLoad";
export var DEFAULT_LAZY_IMG_CLASS = "lazyImg";
export var DEFAULT_LAZY_SOURCED_CONTENT_CLASS = "lazySrc";
export var DEFAULT_LAZY_IMG_SELECTOR = "img." + DEFAULT_LAZY_IMG_CLASS;
export var DEFAULT_ALL_LAZY_SOURCED_CONTENT_SELECTOR = DEFAULT_LAZY_IMG_SELECTOR + "," +
    ("." + DEFAULT_LAZY_SOURCED_CONTENT_CLASS);
var LazyLoadContent = (function () {
    function LazyLoadContent(document, options) {
        if (options === void 0) { options = LazyLoadContent.defaultConfig; }
        this.document = document;
        this.contentElements = this.getAllLazyContentElements();
        this.placeHolderClass = options.placeholderClass || LazyLoadContent.defaultConfig.placeholderClass;
        this.heightThreshold =
            isNaN(options.heightThreshold) ? LazyLoadContent.defaultConfig.heightThreshold : options.heightThreshold;
        this.debounceTime =
            isNaN(options.debounceTime) ? LazyLoadContent.defaultConfig.debounceTime : options.debounceTime;
        this.listenToDomChange();
        this.attachListeners();
        this.handleDomElementScroll(document);
        this.loadViewableContent();
    }
    LazyLoadContent.loadContent = function () {
        LazyLoadContent.document.dispatchEvent(new CustomEvent(LOAD_EVENT));
    };
    LazyLoadContent.prototype.loadViewableContent = function () {
        var _this = this;
        this.contentElements
            .each(function (index, img) {
            _this.isInViewport(img) && _this.loadContent(img);
        });
        this.contentElements = this.contentElements.filter(function (index, img) {
            return $(img).hasClass(DEFAULT_LAZY_IMG_CLASS) || $(img).hasClass(DEFAULT_LAZY_SOURCED_CONTENT_CLASS);
        });
    };
    LazyLoadContent.prototype.getAllLazyContentElements = function () {
        return $(this.document).find(DEFAULT_ALL_LAZY_SOURCED_CONTENT_SELECTOR);
    };
    LazyLoadContent.prototype.listenToDomChange = function () {
        var _this = this;
        new MutationObserver(function (mutationRecords) {
            mutationRecords.forEach(function (record) {
                Array.prototype.slice.call(record.addedNodes).forEach(function (el) {
                    _this.handleDomElementScroll(el);
                });
            });
            _this.contentElements = _this.getAllLazyContentElements();
            _this.loadViewableContent();
        }).observe(LazyLoadContent.document, { childList: true, subtree: true });
    };
    LazyLoadContent.prototype.handleDomElementScroll = function (container) {
        $(container).find(".scrollable-list").on("scroll", function () {
            LazyLoadContent.loadContent();
        });
    };
    LazyLoadContent.prototype.attachListeners = function () {
        var _this = this;
        var observable$ = observableMerge(observableFromEvent(this.document, "scroll", { passive: true }), observableFromEvent(this.document, LOAD_EVENT)).pipe(filter(function () { return _this.contentElements.length > 0; }));
        if (this.debounceTime > 0) {
            observable$ = observable$.pipe(debounceTime(this.debounceTime));
        }
        observable$.subscribe(function () {
            _this.loadViewableContent();
        });
    };
    LazyLoadContent.prototype.canLoadContent = function (content) {
        var $content = $(content);
        return !$content.attr("loaded") && (!!$content.attr("data-src") || !!$content.attr("data-srcset"));
    };
    LazyLoadContent.prototype.loadContent = function (content) {
        var el = $(content);
        if (!this.canLoadContent(el)) {
            return;
        }
        $(content.parentNode).is("picture") && this.loadPicture(content.parentNode);
        loadAttribute(content, "sizes");
        loadAttribute(content, "src");
        loadAttribute(content, "srcset");
        el.removeClass(DEFAULT_LAZY_IMG_CLASS)
            .removeClass(DEFAULT_LAZY_SOURCED_CONTENT_CLASS);
        var loader = this;
        var loadObservable = observableFromEvent(el, "load");
        var errorObservable = observableFromEvent(el, "error");
        observableMerge(loadObservable, errorObservable).pipe(take(1))
            .subscribe(function (event) {
            loader.markContentLoadState(el.get(0), event.type === "load");
        });
    };
    LazyLoadContent.prototype.loadPicture = function (picture) {
        var sources = $(picture).find("source");
        sources.each(function (index, source) { return loadAttribute(source, "srcset"); });
    };
    LazyLoadContent.prototype.markContentLoadState = function (content, loadedSuccessfully) {
        $(content).attr("loaded", "" + loadedSuccessfully)
            .toggleClass(this.placeHolderClass, !loadedSuccessfully)
            .parent().removeClass("skeleton");
    };
    LazyLoadContent.prototype.isInViewport = function (content) {
        var rect = content.getBoundingClientRect();
        return ((rect.height > 0 || rect.width > 0) &&
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= LazyLoadContent.$window.innerHeight + this.heightThreshold &&
            rect.left <= LazyLoadContent.$window.innerWidth);
    };
    LazyLoadContent.defaultConfig = {
        debounceTime: 20,
        heightThreshold: 400,
        placeholderClass: "lazyImgPlaceholder"
    };
    LazyLoadContent.$window = window;
    LazyLoadContent.document = document;
    return LazyLoadContent;
}());
export { LazyLoadContent };
