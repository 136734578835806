import { SubfolderUrlPrefix } from "../ts/subfolderUrlPrefix";
import $ from "../ts/common/jquery";
import { isDefinedAndNotNull } from "../ts/common/utils/objectUtil";
import { ClickStream } from "../ts/clickstream";
export var WishlistErrorReasonWebEnum;
(function (WishlistErrorReasonWebEnum) {
    WishlistErrorReasonWebEnum["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    WishlistErrorReasonWebEnum["TOO_MANY_WISHLIST_ITEMS"] = "TOO_MANY_WISHLIST_ITEMS";
})(WishlistErrorReasonWebEnum || (WishlistErrorReasonWebEnum = {}));
var WishlistErrorModal = (function () {
    function WishlistErrorModal(window) {
        window.document.querySelectorAll(".wishlist-error-modal .cta.reload-page").forEach(function (button) {
            button.addEventListener("click", function () { return window.location.reload(); });
        });
    }
    WishlistErrorModal.getInstance = function () {
        if (isDefinedAndNotNull(this.instance)) {
            return this.instance;
        }
        if (this.isModalPresent) {
            this.instance = new WishlistErrorModal(window);
            return this.instance;
        }
        return null;
    };
    WishlistErrorModal.prototype.open = function (error) {
        var errorReason = isDefinedAndNotNull(error) ? error.reason : undefined;
        var modalId = WishlistErrorModal.getModalIdForErrorType(errorReason);
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "wishlist_error_modal_open",
            servletName: ClickStream.getServlet(),
            pageProperties: {
                errorReason: errorReason
            }
        });
        if (errorReason === "TOO_MANY_WISHLIST_ITEMS") {
            var subfolderUrlPrefix = new SubfolderUrlPrefix(document);
            var wishlistUrl = subfolderUrlPrefix.apply("/wishlist/" + error.wishlistRef);
            var wishlistFullCta = document.querySelector("#" + modalId + " .cta");
            wishlistFullCta.setAttribute("href", wishlistUrl);
        }
        WishlistErrorModal.openErrorModal(modalId);
    };
    WishlistErrorModal.openErrorModal = function (modalId) {
        $("#" + modalId).modal('show');
    };
    WishlistErrorModal.getModalIdForErrorType = function (errorReason) {
        switch (errorReason) {
            case "TOO_MANY_WISHLIST_ITEMS":
                return "wishlistFullModal";
            default:
                return WishlistErrorModal.GENERIC_MODAL_ID;
        }
    };
    WishlistErrorModal.GENERIC_MODAL_ID = "wishlistErrorModal";
    WishlistErrorModal.isModalPresent = isDefinedAndNotNull(document.getElementById(WishlistErrorModal.GENERIC_MODAL_ID));
    return WishlistErrorModal;
}());
export { WishlistErrorModal };
